import React, { useState, useEffect } from "react";
import StudentCourseCard from "./StudentCourseCard";
import { MdArrowDropDown, MdSearch } from "react-icons/md";
import { getCourseByUser } from "../../Api";
import Loader from "react-loader-spinner";
import {userState} from '../../Atoms'
import {useRecoilState} from 'recoil'

export default function StudentCoursePage() {

const [Loading, setLoading] = useState(false)
const [searchTerm, setsearchTerm] = useState('')
const [Courses, setCourses] = useState(null);
const [userData,setuserData] = useRecoilState(userState)
const token = userData.userToken


 useEffect(() => {
 getMyCourses()
   return () => {
   }
 }, [])


const getMyCourses = async()=>{
  setLoading(true)
  console.log('Token is',token)
  try{
  const res = await getCourseByUser(token) ;
  console.log('My Res is',res.data)
  setCourses(res.data)
  setLoading(false)}catch(e){
    console.log('Error is catched',e.response.data.error)
  }
}


  return (
    <div>
      <br />
      <br />

      {/* <div className="container">
        <div className="row">
          <div
            className="col-lg-6 col-sm-12"
            style={{ display: "flex", alignItems: "center", padding: "30px" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "100%",
              }}
            >
              <MdSearch size={40} style={{ marginRight: "5px" }} />
              <input
                type="text"
                style={{
                  border: "0.8px solid #e0e0e0",
                  height: "40px",
                  backgroundColor: "#fff",
                }}
                className="form-control search-input"
                id="formGroupExampleInput"
                placeholder="Seach Course"
                onChange={(event)=>setsearchTerm(event.target.value)}
              />
            </div>
          </div>

          <div
            className="col-lg-6 col-sm-12"
            style={{ display: "flex", alignItems: "center", padding: "30px" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "center",
                flexWrap: "wrap",
                cursor: "pointer",
                transition: "all 0.3s ease-in",
              }}
            >
              <h5>Showing Consultancy for : &nbsp;</h5>
              <div className="dropdown" onClick={() => setopenMenu(true)}>
                <button
                  className="btn btn-primary dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    padding: 0,
                    marginTop: "-5px",
                    color: "gray",
                  }}
                >
                  {selectedCategory} <MdArrowDropDown size={24} />
                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  <li onClick={() => setcurrentIndex(-1)}>
                    <a className="dropdown-item">All Courses</a>
                  </li>

                  {categoryArray &&
                    categoryArray.map((item, index) => {
                      return (
                        <li
                          style={{ cursor: "pointer" }}
                          key={index}
                          onClick={() => setcurrentIndex(index)}
                        >
                          <a className="dropdown-item">{item}</a>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>
        </div>

      </div> */}

<center>
      { Loading && (
        <Loader type="Puff" color="#00BFFF" height={50} width={50} />
      )}
      </center>

      {/* This is courses cards section */}

      <div className="container-fluid" style={{ minHeight: "100vh" }}>
        <div className="row">
          <div className="course-container">
          {Courses &&
              Courses.filter((val)=>{
                if(searchTerm==''){
                  return val ;
                  // return all elements
                }else{
                 if(val.courseName.toLowerCase().includes(searchTerm.toLowerCase())){
                   return val ;
                 }
                }
              }).map((item, index) => {
                if(item.courseId){
                  return <StudentCourseCard key={index} item={item.courseId} />;
                }
                  
              })}
          </div>
        </div>
      </div>
    </div>
  );
}
