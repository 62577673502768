import React from 'react'
import './Auth.css'
import LoginForm from './forms/LoginForm'
import login from '../../assets/login.jpg'



export default function LoginScreen() {




    return (
        <div>
          <div className="container" style={{marginTop : '5vh'}}>
              <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12" style={{flexWrap : 'wrap-reverse'}}>
                      <img style={{width : '90%'}} src={login} />
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12">
                      <LoginForm />
                  </div>
              </div>
          </div>
        </div>
    )
}