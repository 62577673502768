import React from 'react'
import Saly from '../../assets/Saly-26.png'



export default function TwoSectionContent() {
    return (
        <div>
             {/* About Section Start */}
             <br/>
             <br/>
      <section className="about-section">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-xl-6 col-lg-5 col-md-12 col-sm-12">
              <div className="row ">
          
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                 
                  <div >
                   
                   <img src={Saly} style={{maxWidth : '100%'}} />
                   {/* <img src={Saly2} style={{maxWidth : '100%'}} /> */}
                    {/* <img src="https://image.freepik.com/free-photo/portrait-young-university-student-woman-with-graduation-cap-violet-background_33799-8058.jpg" alt="" className="img-fluid" /> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-7">
              <div className="section-heading pl-lg-5 ">
                <span className="subheading">Our Instructors</span>
                <h3>Get Instant Access To <span> Expert Instructors.</span></h3>
                <p className="mb-4">
                  Our Instructors are highly qualified and they have knowledge of several years 
                  working in Finance.
                  We are keen to make sure your learning journey is fun.
                </p>
                <a href="/courses" className="btn  btn-main">Join Now</a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* About Section End */}
      <br/>
      <br/>
        </div>
    )
}
