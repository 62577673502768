import React, { useState } from "react";
import { contactFormDataApi } from "../../../Api";

function LeadForm() {
  const [loading, setLoading] = useState(false);
  const [dataSent, setDataSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [contactData, setContactData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
    phone: " ",
  });

  //make a function that checks contact data is not empty string and show that message
  const checkContactData = () => {
    if (
      contactData.name === "" ||
      contactData.email === "" ||
      contactData.subject === "" ||
      contactData.message === "" ||
      contactData.phone.length < 10
    ) {
      setErrorMessage("Please Fill in all fields !");
      return reset();
    } else {
      setErrorMessage("");
      sendData();
    }
  };

  const sendData = async () => {
    setLoading(true);

    try {
      const res = await contactFormDataApi(contactData);
      setDataSent(true);
      setLoading(false);
    } catch (e) {
      setErrorMessage(e.response.data.error);
      setLoading(false);
    }

    reset();
  };

  const reset = () => {
    setTimeout(() => {
      setDataSent(false);
      setErrorMessage("");
    }, 6000);
  };
  return (
    <>
      <div className="">
        <input
          type="text"
          name="name"
          id="name"
          placeholder="Your Name"
          onChange={(e) =>
            setContactData({ ...contactData, name: e.target.value })
          }
        />
      </div>
      <div className="">
        <input
          type="email"
          name="email"
          id="email"
          placeholder="Email Address"
          onChange={(e) =>
            setContactData({ ...contactData, email: e.target.value })
          }
        />
      </div>
      <div className="">
        <input
          type="number"
          name="phone"
          id="phone"
          placeholder="Phone no"
          onChange={(e) =>
            setContactData({ ...contactData, phone: e.target.value })
          }
        />
      </div>
      <div className="">
        <input
          type="text"
          placeholder="Subject"
          id="subject"
          name="subject"
          onChange={(e) =>
            setContactData({
              ...contactData,
              subject: e.target.value,
            })
          }
        />
      </div>
      <div className="input-wrapper">
        <div className="form-group">
          <textarea
            id="message"
            onChange={(e) =>
              setContactData({
                ...contactData,
                message: e.target.value,
              })
            }
            name="message"
            cols={30}
            rows={6}
            className="form-control"
            placeholder="Your Message"
            defaultValue={""}
          />
        </div>
      </div>
      <div className="Cbtn">
        <p className="mt-4 mb-4 text-danger text-capitalize">
          {errorMessage}
          {/* erro mssage */}
        </p>

        {loading ? (
          <button className="btn btn-main">Sending...</button>
        ) : (
          <button onClick={() => checkContactData()}>Enquiry Now</button>
        )}
      </div>

      {dataSent && (
        <div>
          <div class="alert alert-success mt-2" role="alert">
            Your Message has been sent !
          </div>
        </div>
      )}
    </>
  );
}

export default LeadForm;
