import axios from 'axios'


const base_url = 'https://admin.efinladder.in/api/send-mail'
// const base_url = 'https://work-school.herokuapp.com/send-mail'



export const courseOfferForm = async (userData) => {
    const res = await axios.patch(`${base_url}`, userData);
    return res;
}
