import React,{useState,useEffect} from 'react' ;
import {cartState,userState} from '../../Atoms'
import { useRecoilState } from "recoil";
import { useHistory } from 'react-router-dom'
import { MdClose,MdMenu } from 'react-icons/md';
import StudentCoursePage from '../StudentCourse/StudentCoursePage';

export default function StudentDashboard() {

    const [userDetail,setuserDetail] = useRecoilState(userState)
    const history  = useHistory()
    const [showSideMenu, setshowSideMenu] = useState(false)



useEffect(() => {
  
      
    if( !userDetail.email){
        console.log('Not logged in')
        history.push('/login')
      }

    return () => { }
}, [])



    return (
    
          
<div className="container-fluid" >
    <div className="row" >
 
<div className="col-12">

<center>
    <br/>
    <h3>My Courses</h3>
</center>

<StudentCoursePage/>




    
</div>
  
</div>
</div>
        
    )
}
