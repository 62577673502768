import React from 'react'
import CtaIllustration from '../../assets/cta-illustration.png'

export default function TwoSectionLeft() {
    return (
        <div>
            <br/>

  {/* About Section Start */}
  <section className="about section-padding">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div data-aos="fade-right" data-aos-duration="1000"   className="col-xl-6 col-lg-6">
              <div className="section-heading ">
                <span className="subheading">HOW TO JOIN US</span>
                <h3> How to Sign up with FinLadder ?</h3>
                {/* <p>The ultimate planning solution for busy women who want to reach their personal goals.Effortless comfortable eye-catching unique detail </p> */}
              </div>
              <div data-aos="fade-right" data-aos-duration="1000"  data-aos-delay="400" className="about-text-block">
                <div className="icon-box">
                  <i className="flaticon-video-camera" />
                </div>
                <div className="about-desc">
                  <h4>Sign up on website</h4>
                  <p>If you are new click on register and fill out your details.</p>
                </div>
              </div>
              <div data-aos="fade-right" data-aos-duration="1000"  data-aos-delay="600" className="about-text-block">
                <div className="icon-box">
                  <i className="flaticon-flag" />
                </div>
                <div className="about-desc">
                  <h4>Enroll in course</h4>
                  <p>Enroll in the course you like by adding them to cart.</p>
                </div>
              </div>
              <div data-aos="fade-right" data-aos-duration="1000"  data-aos-delay="900" className="about-text-block">
                <div className="icon-box border-none">
                  <i className="flaticon-video-camera" />
                </div>
                <div className="about-desc">
                  <h4>Start Learning.</h4>
                  <p>Great! Now you'll find your course and you can start learning right away!</p>
                </div>
              </div>
            </div>
            <div data-aos="fade-right" data-aos-once="false" data-aos-duration="1000"  data-aos-delay="1200"  className="col-xl-6 col-lg-6">
              <div className="about-img">
                <img src={CtaIllustration} alt="" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* About Section End */}
<br/>

        </div>
    )
}
