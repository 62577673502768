import React from 'react';
import { AiOutlineCheckSquare } from 'react-icons/ai';
import "./Mainpage.css"

const Timeline = () => {
  return <div className="container mt-4" >
   <div className="">
          <h2 className="text-center" >Modules of NCFM</h2>
          <p>Our NCFM course offers 50 modules, each module provides a different set of knowledge. Each module duration is 1 month and rarely 2 months. After the completion of the course, the candidates are provided with the training and preparation of this examination by ICFM. ICFM provides intense theoretical and practical training to the candidates so that they can be well versed with the market one and write exams with confidence and get certified. </p>
         
          <p>The candidate will find NCFM Course Online Modules PDF at the end of the page. 
</p>
<p>
NCFM Course Details are provided in the PDF.
</p>
<h5>NCFM Module Full Syllabus:</h5>
<p>Below given is the list of NCFM Course Online Modules. </p>
<div className="row">
  <div className="col-md-6">
 
  <ul>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Financial Markets: A Beginner’s Module</li>
        <li> <AiOutlineCheckSquare/>  &nbsp; Mutual Funds: A Beginner’s Module</li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Currency Derivatives: A Beginner’s Module</li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Equity Derivatives: A Beginner’s Modules</li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Interest Rate Derivatives: A Beginner’s Module</li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Commercial Banking in India: A Beginner’s Module</li>
        <li> <AiOutlineCheckSquare/>  &nbsp; FIMMDA-NSE Debt Market (Basic) </li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Securities Market (Basic) Module</li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Clearing Settlement and Risk Management Module</li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Banking Fundamentals – International</li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Capital Markets Fundamentals – International</li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Capital Market (Dealers) Module (CMDM)</li>
        <li> <AiOutlineCheckSquare/>  &nbsp; Derivatives Market (Dealers) Module (DMDM) </li>
        <li> <AiOutlineCheckSquare/>  &nbsp; Investment Analysis and Portfolio Management </li>
        <li> <AiOutlineCheckSquare/>  &nbsp; Fundamental Analysis Module </li>
        <li> <AiOutlineCheckSquare/>  &nbsp; Options Trading Strategies Module </li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Operations Risk Management Module</li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Banking Sector Module</li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Treasury Management Module</li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Insurance Module</li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Macroeconomics for Financial Markets Module</li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  NSDL-Depository Operations Module</li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Commodities Market Module</li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Surveillance in Stock Exchanges Module</li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Corporate Governance Module</li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Compliance Officers (Brokers) Module</li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Compliance Officers (Corporate) Module</li>
        <li> <AiOutlineCheckSquare/>  &nbsp; Information Security Auditors Module (Part-1) & Information Security Auditors Module (Part-2) </li>
      </ul>
  </div>
  <div className="col-md-6">
 
  <ul>
  
        <li> <AiOutlineCheckSquare/>  &nbsp; Technical Analysis Module </li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Mergers and Acquisitions Module</li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Back Office Operations Module</li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Wealth Management Module</li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Project Finance Module</li>
        <li> <AiOutlineCheckSquare/>  &nbsp; Venture Capital and Private Equity Module </li>
        <li> <AiOutlineCheckSquare/>  &nbsp; Financial Services Foundation </li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  NSE Certified Quality Analyst Module</li>
        <li> <AiOutlineCheckSquare/>  &nbsp; Information Security Services Professionals </li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Algorithmic Trading Module</li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Financial Markets (Advanced) Module</li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Securities Market (Advanced) Module</li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Derivatives (Advanced) Module</li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Mutual Funds (Advanced) Module</li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Options Trading (Advanced) Module</li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Modules of Financial Planning Standards Board India (Certified Financial Planner Certification)</li>
        <li> <AiOutlineCheckSquare/>  &nbsp; Equity Research Module </li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Market Risk Module</li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Financial Modeling Course</li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Business Analytics Module</li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Investment Banking Operations – International</li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  International Financial Reporting Standards (IFRS)</li>
     
   
      </ul>
  </div>
</div>

<p> <b>Note</b>  : To download NCFM Course Online Modules Free Download, directly click on the course's name. </p>
<p>All of these topics will be taught by industry experts with more than 15 years of expertise. Our services don't stop there; we also offer free mock tests in both online and offline formats, depending on the needs of the candidate.</p>

      </div>
      
  </div>;
};

export default Timeline;
