import React from 'react'
import FreeContentComponent from './FreeContentComponent'
import pdf0 from '../../assets/pdf/frm0.pdf'
import pdf1 from '../../assets/pdf/frm1.pdf'
import pdf2 from '../../assets/pdf/frm2.pdf'
import pdf3 from '../../assets/pdf/frm3.pdf'
import img1 from '../../assets/pdf/Artboard 11.png'
import img2 from '../../assets/pdf/Artboard 12.png'
import img3 from '../../assets/pdf/Artboard 13.png'
import img4 from '../../assets/pdf/Artboard 14.png'

import cfa1 from '../../assets/pdf/cfa1.pdf'
import img5 from '../../assets/pdf/Artboard 15.png'

import cfa2 from '../../assets/pdf/cfa2.pdf'
import img6 from '../../assets/pdf/Artboard 16.png'

import cfa3 from '../../assets/pdf/cfa3.pdf'
import img7 from '../../assets/pdf/Artboard 17.png'

import cfa4 from '../../assets/pdf/cfa4.pdf'
import img8 from '../../assets/pdf/Artboard 18.png'

import cfa5 from '../../assets/pdf/cfa5.pdf'
import img9 from '../../assets/pdf/Artboard 19.png'


import bank1 from '../../assets/pdf/bank1.pdf'
import img10 from '../../assets/pdf/Artboard 110.png'


import bank2 from '../../assets/pdf/bank2.pdf'
import img11 from '../../assets/pdf/Artboard 111.png'


import bank3 from '../../assets/pdf/bank3.docx'
import img12 from '../../assets/pdf/Artboard 112.png'

export default function FreeContentScreen() {

const FinanceData =[
    {
        title : 'Foundation of risk management',
        description : 'Learn about fundamentals of Finance',
        pdf : pdf0,
        thumbnail : img1,
    },
    {
        title : 'Quantative Analysis',
        description : 'Learn about fundamentals of Finance',
        pdf : pdf1,
        thumbnail : img2,
    },
    {
        title : 'Finance',
        description : 'Learn about fundamentals of Finance',
        pdf : pdf2,
        thumbnail : img3,
    },
    {
        title : 'Finance',
        description : 'Learn about fundamentals of Finance',
        pdf : pdf3,
        thumbnail : img4,
    },
]




const CfaData =[
    {
        title : 'Corporate Finance, Equity and Fixed Income',
        description : 'Learn about fundamentals of Finance',
        pdf : cfa1,
        thumbnail : img5,
    },
    {
        title : 'Derivatives, Alternative Investment and Portfolio Management',
        description : 'Learn about fundamentals of Finance',
        pdf : cfa2,
        thumbnail : img6,
    },
    {
        title : 'Economics',
        description : 'Learn about fundamentals of Finance',
        pdf : cfa3,
        thumbnail : img7,
    },
      {
        title : 'Ethical & Professional & Quantative  Methods',
        description : 'Learn about fundamentals of Finance',
        pdf : cfa4,
        thumbnail : img8,
    },
      {
        title : 'Financial Reporting Analysis',
        description : 'Learn about fundamentals of Finance',
        pdf : cfa5,
        thumbnail : img9,
    },
   
   
  
]


const BankingData =[
    {
        title : 'Financial Modelling Beginner Notes',
        description : 'Learn about fundamentals of Finance',
        pdf : bank1,
        thumbnail : img10,
    },
    {
        title : '100+ Finance Interview Questions',
        description : 'Learn about fundamentals of Finance',
        pdf : bank2,
        thumbnail : img11,
    },
    {
        title : '100+ Finance Interview Questions',
        description : 'Learn about fundamentals of Finance',
        pdf : bank3,
        thumbnail : img12,
    },
    
  
]

    return (
        <div className="container-fluid">
         <br/>
    
       

<div className="row">

<div className="col-lg-12 free-content-container">
    <h3>&nbsp; FRM Level 1 Schweser</h3>
    <br/>
<FreeContentComponent contentData={FinanceData}/>
</div>



<div className="col-lg-12 free-content-container">
    <h3> &nbsp; CFA Level 1 Schweser</h3>
    <br/>
<FreeContentComponent contentData={CfaData}/>
</div>





</div>
<br/>

<div className="row">

<div className="col-lg-12 free-content-container">
    <h3>&nbsp; Investment Banking</h3>
    <br/>
<FreeContentComponent contentData={BankingData}/>
</div>








</div>
<br/>



         <br/>
        </div>
    )
}
