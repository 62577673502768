import React from 'react';
import { AiOutlineCheckSquare } from 'react-icons/ai';

const CfaDetailPage = () => {
  return <div>
       <div className="container my-5">
  <div className="row justify-content-center align-items-center">
  <div className="col-lg-7 leftContainerDfaDetail">
    <h4 className="leftContainerDfaDetail mt-5" >Why CFA?</h4>
    <p className="leftContainerDfaDetail pt-3" >There are considerable advantages to choosing CFA over other financial courses. In this section, we will explore the advantages of the course in more depth. Keep following the article for more. </p> <br/>
    <ul>
    <div className="d-flex justify-content-center align-items-start ">
    <AiOutlineCheckSquare  size={20}  />
                  <li className="pb-1 ml-2 DetailList " >
                    {" "}
                      Nothing on a resume can guarantee you a job, while our certified course can make great job offers for you. People in the finance industry know the time and discipline it takes to become a CFA charter holder. {" "}
                  </li>
    </div>
    <div className="d-flex justify-content-center align-items-start ">
    <AiOutlineCheckSquare  size={20}  />
                  <li className="pb-1 ml-2 DetailList " >
                  CFA Course Online Fee is quite affordable when compared to MBA programs. The MBA program can cost you Rs. 30-40 Lakh while our CFA Certification Course Fees In India will cost half of it. For such professional qualification. CFA is quite a bargain. 
                  </li>
    </div>
    <div className="d-flex justify-content-center align-items-start ">
    <AiOutlineCheckSquare  size={20}  />
                  <li className="pb-1 ml-2 DetailList " >
                  CFA Institutes has a global network of around 1,35,000 investment professionals. This degree speaks for itself. It is well recognized by almost 30 countries and territories. You will gain numerous career and job opportunities in the financial sector. 
                  </li>
    </div>
    <div className="d-flex justify-content-center align-items-start ">
    <AiOutlineCheckSquare  size={20}  />
                  <li className="pb-1 ml-2 DetailList " >
                  You can obtain analytical skills and competence in quantitative methods, economics, financial reporting, investment analysis, and portfolio management by earning a CFA charter. This course of ours is a set of skills and knowledge you will develop growing as a CFA Charter holder. 
                  </li>
    </div>
    <div className="d-flex justify-content-center align-items-start ">
    <AiOutlineCheckSquare  size={20}  />
                  <li className="pb-1 ml-2 DetailList " >
                  As a CFA charter holder, you get a higher salary and projects. The international market knows that there is no shortage of projects for CFA charter holders. You get hired by global financial institutions. The salary of CFA charter holders is premium, due to their knowledge and skills.
                  </li>
    </div>
                 
                </ul>
  </div>
  <div className="col-lg-4 rightContainer">
    <div className="  ">
        <img src="/assets/Icons/3.svg" className="img-fluid" alt="undraw_ask_me_anything_re_x7pm" />
    </div>
  </div>
  </div>
  {/* card  start*/}
  <div className="row justify-content-center align-items-center my-5">
  <div className="col-lg-4 rightContainer">
    <div className="">
        <img src="/assets/Icons/9.svg" className="img-fluid" alt="undraw_ask_me_anything_re_x7pm" />
    </div>
  </div>
  <div className="col-lg-7 leftContainerDfaDetail">
    <h4 className="leftContainerDfaDetail   " >CFA Courses Online Fees </h4>
    <ul>
    <div className="d-flex justify-content-center align-items-start ">
    <AiOutlineCheckSquare  size={20}  />
                  <li className="pb-1 ml-2 DetailList " >
                  The fee structure of our CFA Course Online varies as per the levels. Kindly contact us to know more about the fee structure of the course.
                  </li>
    </div>
    <div className="d-flex justify-content-center align-items-start ">
    <AiOutlineCheckSquare  size={20}  />
                  <li className="pb-1 ml-2 DetailList " >
                  Choose from one of our business financing partners to apply for an educational loan with a 9-18 months repayment period. 
                  </li>
    </div>
            
    <div className="d-flex justify-content-center align-items-start ">
    <AiOutlineCheckSquare  size={20}  />
                  <li className="pb-1 ml-2 DetailList " >
                  Pay only 10% of the registration fee and start the course with us now.
                  </li>
    </div>
            
    <div className="d-flex justify-content-center align-items-start ">
    <AiOutlineCheckSquare  size={20}  />
                  <li className="pb-1 ml-2 DetailList " >
                  Candidates who fail to submit their course fees on time will be charged an additional cost.
                  </li>
    </div>
    <div className="d-flex justify-content-center align-items-start ">
    
                
                 <p> Contact us, for more details regarding CFA Course Eligibility And Price.</p>
                  
    </div>
            
                  
                </ul>
  </div>
 
  </div>
  {/* card end  */}
  {/* card  start*/}
  <div className="row justify-content-center align-items-center my-5">
 
  <div className="col-lg-7 leftContainerDfaDetail">
    <h4 className="leftContainerDfaDetail   " >CFA Courses Outline</h4>
    <p>The outline of the course includes the following points:                                                                                                                 </p>
    <ul>
    <div className="d-flex justify-content-center align-items-start ">
    <AiOutlineCheckSquare  size={20}  />
                  <li className="pb-1 ml-2 DetailList " >
                  This course CFA could be defined as a self-study, distance learning course. Under our course, you will learn about investment analysis, valuation, portfolio management, and emphasize the highest ethical and professional standards.
                  </li>
    </div>
    <div className="d-flex justify-content-center align-items-start ">
    <AiOutlineCheckSquare  size={20}  />
                  <li className="pb-1 ml-2 DetailList " >
                  The program is divided into three levels. In order to get CFA certification, the candidate must clear all three levels and meet a certain work requirement set out by the CFA Institutes. 
                  </li>
    </div>
    <div className="d-flex justify-content-center align-items-start ">
    <AiOutlineCheckSquare  size={20}  />
                  <li className="pb-1 ml-2 DetailList " >
                  Level 1 of our CFA Course Online is the least difficult one among all. The concepts of Level 1 are easier to grab, but they require more focus and time for better understanding. This level is designed to inculcate a strategic and analytical thought process among students. 
                  </li>
    </div>
    <div className="d-flex justify-content-center align-items-start ">
    <AiOutlineCheckSquare  size={20}  />
                  <li className="pb-1 ml-2 DetailList " >
                  Over 300 hours of study is required for each level of our CFA Certification Course 2021.
                  </li>
    </div>
                  
                </ul>
  </div>
  <div className="col-lg-4 rightContainer">
    <div className="">
        <img src="/assets/Icons/5.svg" className="img-fluid" alt="undraw_ask_me_anything_re_x7pm" />
    </div>
  </div>
 
  </div>
  {/* card end  */}
  {/* card  start*/}
  <div className="row justify-content-center align-items-center my-5">
  <div className="col-lg-4 rightContainer">
    <div className="">
        <img src="/assets/Icons/11.svg" className="img-fluid" alt="undraw_ask_me_anything_re_x7pm" />
    </div>
  </div>
  <div className="col-lg-7 leftContainerDfaDetail">
    <h4 className="leftContainerDfaDetail   " >CFA Courses Eligibility</h4>
    <p>In this section, we will go over the CFA Course Eligibility Criteria. Candidates should be aware of the following points before enrolling in our CFA Course.                                                                                                                 </p>
    <ul>
    <div className="d-flex justify-content-center align-items-start ">
    <AiOutlineCheckSquare  size={20}  />
                  <li className="pb-1 ml-2 DetailList " >
                  Candidates must have a relevant bachelor's degree from a well-recognized institute to be eligible for the CFA Course Online.
                  </li>
    </div>
                 
    <div className="d-flex justify-content-center align-items-start ">
    <AiOutlineCheckSquare  size={20}  />
                  <li className="pb-1 ml-2 DetailList " >
                  The final year candidate pursuing a bachelor’s can also opt for our financial course.
                  </li>
    </div>
                 
    <div className="d-flex justify-content-center align-items-start ">
    <AiOutlineCheckSquare  size={20}  />
                  <li className="pb-1 ml-2 DetailList " >
                  The only requirement of the CFA institute is that the candidate must be a graduate or have at least four years of professional job experience, or a combination of both.
                  </li>
    </div>
                 
    <div className="d-flex justify-content-center align-items-start ">
    <AiOutlineCheckSquare  size={20}  />
                  <li className="pb-1 ml-2 DetailList " >
                  Undergraduate candidates pursuing our course must complete their bachelor's degree before the beginning of Level 2.
                  </li>
    </div>
                 
    <div className="d-flex justify-content-center align-items-start ">
    <AiOutlineCheckSquare  size={20}  />
                  <li className="pb-1 ml-2 DetailList " >
                  Aside from educational requirements, these are a few conditions needed to be fulfilled by the candidates. The candidate must meet professional conduct admission criteria and live in a participating country
                  </li>
    </div>
    <div className="d-flex justify-content-center align-items-start ">
    <AiOutlineCheckSquare  size={20}  />
                  <li className="pb-1 ml-2 DetailList " >
                  The candidate must have a valid international passport.
                  </li>
    </div>
                 
    <div className="d-flex justify-content-center align-items-start ">
    <AiOutlineCheckSquare  size={20}  />
                  <li className="pb-1 ml-2 DetailList " >
                  The candidates enrolling for CFA Online Course should complete the English assessment. 
                  </li>
    </div>
                 
                </ul>
  </div>
 <h5 className="my-3"  >Click here for CFA Course Online Modules Free Download. </h5>
 
  </div>
  {/* card end  */}
  </div>
  </div>;
};

export default CfaDetailPage;
