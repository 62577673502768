import React from 'react';
import { AiOutlineCheckSquare } from 'react-icons/ai';

const CfaDetailPage = () => {
  return <div>
    <div className="container my-5">
      <div className="row justify-content-center align-items-center">
        <div className="col-lg-7 leftContainerDfaDetail">
          <h4 className="leftContainerDfaDetail mt-5" >Why FRM?</h4>
          <p>Now you must decide why you should select our FRM Certification Course 2021 above any other financial knowledge training. You must understand exactly where you are investing your money, therefore here is the answer to your why</p>
          <ul>
            <div className="d-flex justify-content-center align-items-start ">
              <AiOutlineCheckSquare size={20} />
              <li className="pb-1 ml-2 DetailList " >
                If you are a student who wants to strengthen your resume for a career in the Risk or Financial Markets domain. Then you must choose our  FRM Online Course. FRM has a strong signaling ability. If you have cleared both FRM exams, you will be seen as quantitatively sound and proficient in Risk Concepts and Financial Markets compared to other courses
              </li>
            </div>
            <div className="d-flex justify-content-center align-items-start ">
              <AiOutlineCheckSquare size={20} />
              <li className="pb-1 ml-2 DetailList " >
                If you are someone who is watching their field or domain. Assuming you want to switch to the Risk Management domain, choosing FRM Course Online is the only right decision.
              </li>
            </div>
            <div className="d-flex justify-content-center align-items-start ">
              <AiOutlineCheckSquare size={20} />
              <li className="pb-1 ml-2 DetailList " >
                We offer FRM Courses Books to help you gain a better understanding of risk financial markets and products, as well as risk management techniques.
              </li>
            </div>
            <div className="d-flex justify-content-center align-items-start ">
              <AiOutlineCheckSquare size={20} />
              <li className="pb-1 ml-2 DetailList " >
                The best-designed online course in finance, our FRM Course Online gives you a thorough knowledge of risks concepts that can be applied in various roles across firms. Also, our courses will boost your confidence by adding credentials to your resume.
              </li>
            </div>

          </ul>
        </div>
        <div className="col-lg-4 rightContainer">
          <div className="  ">
            <img src="/assets/Icons/2.svg" className="img-fluid" alt="undraw_ask_me_anything_re_x7pm" />
          </div>
        </div>
      </div>
      {/* card  start*/}
      <div className="row justify-content-center align-items-center my-5">
        <div className="col-lg-4 rightContainer">
          <div className="">
            <img src="/assets/Icons/1.svg" className="img-fluid" alt="undraw_ask_me_anything_re_x7pm" />
          </div>
        </div>
        <div className="col-lg-7 leftContainerDfaDetail">
          <h4 className="leftContainerDfaDetail   " >FRM Courses Online Fees  </h4>
          <p>In a country like India, the government’s contribution to the financial sector is growing dynamically. The government has realised how important the financial sector is for a country's growth. In this section, we will explain to our potential candidates about the fees structure. </p>
          <ul>
            <div className="d-flex justify-content-center align-items-start ">
              <AiOutlineCheckSquare size={20} />
              <li className="pb-1 ml-2 DetailList " >
                You may save up to 10% on our special FRM Certification Course Fees with a one-time purchase.
              </li>
            </div>
            <div className="d-flex justify-content-center align-items-start ">
              <AiOutlineCheckSquare size={20} />
              <li className="pb-1 ml-2 DetailList " >
                To apply for an educational loan with a 16-24 month repayment duration, choose one of our business financing partners.
              </li>
            </div>

            <div className="d-flex justify-content-center align-items-start ">
              <AiOutlineCheckSquare size={20} />
              <li className="pb-1 ml-2 DetailList " >
                The fees for the FRM Course Online can be paid in installments. Depending on the candidate's preference, the number of installments could range from 7-9.
              </li>
            </div>
            <div className="d-flex justify-content-center align-items-start ">
              <AiOutlineCheckSquare size={20} />
              <li className="pb-1 ml-2 DetailList " >
                FRM Course Books’ cost varies with the vendor.
              </li>
            </div>
            <p className="py-2" > <b>Note :</b> If the candidate fails in any of the parts he/she must re-pay the FRM Course Fees Online for that part. Therefore, the candidates are advised to attempt the examination carefully. </p>



          </ul>
        </div>

      </div>
      {/* card end  */}
      {/* card  start*/}
      <div className="row justify-content-center align-items-center my-5">

        <div className="col-lg-7 leftContainerDfaDetail">
          <h4 className="leftContainerDfaDetail   " >FRM Courses Outline</h4>
          <p>In this section, we will be discussing FRM Courses Outline. Please find the bullet points informational below.                                                                                                               </p>
          <ul>
            <div className="d-flex justify-content-center align-items-start ">
              <AiOutlineCheckSquare size={20} />
              <li className="pb-1 ml-2 DetailList " >
                It is your responsibility to register with GARP for exam entry before you book the course. Details of registration can be found on the GARP website.
              </li>
            </div>
            <div className="d-flex justify-content-center align-items-start ">
              <AiOutlineCheckSquare size={20} />
              <li className="pb-1 ml-2 DetailList " >
                There are no penalties for incorrect answers in the examination.
              </li>
            </div>
            <div className="d-flex justify-content-center align-items-start ">
              <AiOutlineCheckSquare size={20} />
              <li className="pb-1 ml-2 DetailList " >
                Our course will train you to make informed risk decisions. The motive behind our course is to make your expertise in risk management, money handling, and investment
              </li>
            </div>
            <div className="d-flex justify-content-center align-items-start ">
              <AiOutlineCheckSquare size={20} />
              <li className="pb-1 ml-2 DetailList " >
                Once the candidate successfully passes the FRM test he/she will be able to make better decisions in the financial sector. Under our course, you will have the opportunity to develop your risk management skills and improve your own creativity.
              </li>
            </div>


          </ul>
        </div>
        <div className="col-lg-4 rightContainer">
          <div className="">
            <img src="/assets/Icons/3.svg" className="img-fluid" alt="undraw_ask_me_anything_re_x7pm" />
          </div>
        </div>

      </div>
      {/* card end  */}
      {/* card  start*/}
      <div className="row justify-content-center align-items-center my-5">
        <div className="col-lg-4 rightContainer">
          <div className="">
            <img src="/assets/Icons/11.svg" className="img-fluid" alt="undraw_ask_me_anything_re_x7pm" />
          </div>
        </div>
        <div className="col-lg-7 leftContainerDfaDetail">
          <h4 className="leftContainerDfaDetail   " >FRM Course Eligibility</h4>
          <p>The eligibility for our course is very simple. The candidates who know managing risk and investment can opt for the course. There is no minimum for FRM Course Eligibility to sit in the exam, but the candidates are advised to opt for our courses only after graduation, from any field.
            In short, the candidates need to fulfill these three FRM Courses Eligibility which is mentioned below:
            <ul>
              <div className="d-flex justify-content-center align-items-start ">
                <AiOutlineCheckSquare size={20} />
                <li className="pb-1 ml-2 DetailList " >
                  The candidates must have cleared both Part-1 and Part-2 exams of the FRM Course Online.
                </li>
              </div>
              <div className="d-flex justify-content-center align-items-start ">
                <AiOutlineCheckSquare size={20} />
                <li className="pb-1 ml-2 DetailList " >
                  Minimum 2 years of relevant work experience is required.
                </li>
              </div>
              <div className="d-flex justify-content-center align-items-start ">
                <AiOutlineCheckSquare size={20} />
                <li className="pb-1 ml-2 DetailList " >
                  Active fellow membership in GARP.
                </li>
              </div>


            </ul>                                                                                                        </p>


        </div>


      </div>
      {/* card end  */}
      {/* card  start*/}
      <div className="row justify-content-center align-items-center my-5">

        <div className="col-lg-7 leftContainerDfaDetail">
          <h4 className="leftContainerDfaDetail   " >FRM Test Details</h4>
          <p>In this section, know all about test details. This test is conducted twice a year by the council. The FRM exam has two parts, part 1 and part 2, that take place in May and November, respectively. In these exams, the students have to prove their two years of professional work experience to GARP in order to pass the test and perceive certification in it.                                                                                                                </p>
          <p>Furthermore, the students are granted five years to provide evidence of their relevant professional experience. In case the student fails to submit his/her work experience, their FRM status will no longer hold any value and the students will have to reappear for the exam again.</p>

        </div>
        <div className="col-lg-4 rightContainer">
          <div className="">
            <img src="/assets/Icons/1.svg" className="img-fluid" alt="undraw_ask_me_anything_re_x7pm" />
          </div>
        </div>

      </div>
      {/* card end  */}
      {/* card  start*/}
      <div className="row justify-content-center align-items-center my-5">
        {/* <div className="col-lg-4 rightContainer">
          <div className="">
            <img src="/assets/Icons/11.svg" className="img-fluid" alt="undraw_ask_me_anything_re_x7pm" />
          </div>
        </div> */}
        <div className="col-lg-12 leftContainerDfaDetail">
          <h4 className="leftContainerDfaDetail   " >Financial Risk Management - Overview by eFinladder</h4>
          <p className="">
            Signing up for the ‘Financial Risk Management’ online course by eFinladder to announce a grand career move in the world of Finance?
          </p>
          <p className="">
            But, before dwelling into a professional commitment to begin with your FRM course online, it is important to polish its foundations such as Financial Markets and Products,Quantitative Analysis and Value at Risk for better understanding of financial topics.
          </p>
          <p className="">
            Financial Risk Management is the method of identifying, analyzing, and making investment decisions based on accepting or mitigating risks. These may be quantitative or qualitative risks, and it is the responsibility of the Finance manager to use the available financial instruments to protect the company from them.
          </p>
          <p className="">
            The Basel Accords are a set of regulations adopted by international banks that help to track, report, and expose credit, marketing, and operational risks in the banking industry.
          </p>
          <p className="">
            You can enroll yourself into a Financial Risk Management Course exclusively provided by eFinladder experts. You can get in touch with us to enquire about FRM Course fees and FRM Course duration.
          </p>
          <p className="">
            <ul>
              <li> <AiOutlineCheckSquare />  &nbsp;  <b>Operational Risk</b> </li>

            </ul>
            Operational Risk is the risk of indirect or direct loss caused by internal people, systems, processes, or external events that fail or are inadequate. It includes security risks, legal risks, fraud, environmental risks, and physical risks, among others (major power failures, infrastructure shutdown etc.)
          </p>
          <p className="">
            <ul>
              <li> <AiOutlineCheckSquare />  &nbsp;  <b>Foreign Exchange Risk</b> </li>

            </ul>
            Foreign Exchange Risk in Financial Risk Management is likewise referred to as currency risk, FX risk, and exchange rate risk. It is incurred when a business conducts a financial transaction in a currency other than its operating currency, which is typically its domestic currency. The risk stems from unfavorable fluctuations in the exchange rate between the transactional currency and the operating currency.
          </p>
          <p className="">
            <ul>
              <li> <AiOutlineCheckSquare />  &nbsp;  <b>Credit Risk</b>  </li>

            </ul>
            Credit risk is the possibility that a borrower or customer will default on their debts or payments. When determining the extent of the Credit Risk associated with borrowed
            funds, additional factors such as interest loss, rising collection costs, etc. must be considered in addition to the loss of principal.
            <br />


            A specialized curated FRM courseonline offered by eFinladder unveils more practical standard concepts ranging from types associated with Financial Risk Management to expert tips on pros and cons on financial risk management.

          </p>




        </div>
        <h5 className="my-3 pl-2"  >Click here for FRM Course Online Modules Free Download. </h5>
      </div>
      {/* card end  */}

    </div>
  </div>;
};

export default CfaDetailPage;
