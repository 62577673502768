import React from 'react'
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { ImYoutube } from 'react-icons/im';
import FullWidthTestimonial from '../../ReusableComponents/FullWidthTestimonial';
import Testimonial from '../../ReusableComponents/Testimonial';
import CourseV2Form from '../CourseV2Form';
import WhyFinladder from '../WhyFinaldder';
import "./style.css"
import Plyr from 'plyr-react'
import 'plyr-react/dist/plyr.css'

const InvestMentBanking = () => {

    const [showImbModal, setShowImbModal] = useState(false)

    const startScroll = (id) => {
        const element = document.getElementById(id);
        element.scrollIntoView();
    }
    return (
        <>
            <div className="InvestMentBanking-container">
                <div className="header-section container my-20">
                    <div className="row justify-content-center header-section-wrapper ">
                        <div className="col-md-7  d-flex e flex-column justify-content-center "
                        >
                            <h1 className="title mb-2 text-light">
                                Investment Banking (Financial Modeling & Valuations)
                            </h1>
                            <h6 className="sub-title text-light">
                                Growth Stuck?  Remove Barriers - Jump into <br />Investment Banking Role with Finladder!

                            </h6>
                            <div className="InvestMentBanking-start-now-btn mb-5 mt-3 " onClick={() => startScroll('ContactV2')}>
                                START NOW
                            </div>
                        </div>
                        <div className="col-md-5 equity-video-container" onClick={() => setShowImbModal(true)}>
                            <button className="btn btn-transparent video-block" style={{ padding: 0 }} >
                                <img src="/assets/images/brand_logo/tablet-showing-growing.jpg" alt="equity-banner" className="img-fluid equity-video-banner" />
                                <div className="video-icon"

                                >
                                    <ImYoutube

                                        style={{
                                            marginTop: "-25px"
                                        }}
                                    />

                                </div>
                            </button>


                        </div>
                    </div>
                </div>
            </div>
            <Modal show={showImbModal} onHide={
                () => setShowImbModal(false)
            } size='xl'>
                <Modal.Header closeButton style={{ paddingTop: 0, paddingBottom: 0, background: '#00000030' }}>
                    {/* <Modal.Title>Modal heading</Modal.Title> */}
                </Modal.Header>
                <Modal.Body style={{ padding: 0 }}>
                    <Plyr autoplay="true" source={{
                        type: "video",
                        sources: [
                            {
                                src: `${"https://www.youtube.com/watch?v=D2wx-Z6P4HY".replace('watch?v=', 'embed/').replace('youtube', 'youtube-nocookie')}?showinfo=0&rel=0&modestbranding=1&controls=0`,
                                provider: "youtube"
                            }
                        ]
                    }}
                    />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default InvestMentBanking;