import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation,
  withRouter
} from 'react-router-dom'
import { useEffect } from 'react'
import HomepageScreen from './components/homepage/HomepageScreen'
import Navbar from './components/Nav/Navbar'
import RegisterScreen from './components/Auth/RegisterScreen'
import LoginScreen from './components/Auth/LoginScreen'
import ForgotPasswordScreen from './components/Auth/ForgotPasswordScreen'
import Footer from './components/Footer/Footer'
import ContactPage from "./components/ContactPage/ContactPage";
import AboutPage from "./components/AboutPage/AboutPage";
import CoursePage from './components/CoursePage/CoursePage'
import InnerCoursePage from "./components/CoursePage/InnerCoursePage";
import CartPage from "./components/CartPage/CartPage";
import StudentDashboard from "./components/StudentDashboard/StudentDashboard";
import NotFound from "./components/NotFound/NotFound";
import StudentInnerCoursePage from './components/StudentCourse/StudentInnerCoursePage'
import FreeContentScreen from "./components/FreeContent/FreeContentScreen";
import Terms from './components/ReusableComponents/Terms'
import Privacy from './components/ReusableComponents/Privacy'
import Refund from './components/ReusableComponents/Refund'
import AOS from 'aos';
import ThankYouPage from './components/ReusableComponents/ThankYou'
import Blog from './components/Blog/Blog'
import BlogByid from './components/Blog/BlogByid'
// new pages
import MainPage from './components/Equity/MainPage'
import FrmMainPage from './components/SeoContentsPages/Frm/FrmMainPage'
import ErtMainPage from './components/SeoContentsPages/Equity/ErtMainPage'
import CfaMainPage from './components/SeoContentsPages/CFA/CfaMainPage'
import NcfmMainPage from './components/SeoContentsPages/NCFM/NcfmMainPage'
import ThankYouPageForm from './components/ThankYouPage'
import { Helmet } from 'react-helmet'


function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return props.children
}
const ScrollToTop = withRouter(_ScrollToTop)





function App() {

  useEffect(() => {
    AOS.init({
      duration: 2000
    });
    AOS.refresh()
  }, []);


  const isAuth = false;

  return (

    <Router>

      <ScrollToTop>
        <Helmet>
          <title>
            eFinladder | Best Finance Courses 2022 | Become Certified Financial Expert
          </title>

          <meta
            name="description"
            content="eFinladder offering the best finance courses for those who are searching for the financial courses to join and to become a certified financial expert in the industry."
          />

          <meta name="keywords" content="FRM , Equity , CFA ,NCFM " />


        </Helmet>

        <Navbar />

        <Switch>
          <Route exact path="/" component={HomepageScreen} />
          <Route exact path="/register" component={RegisterScreen} />
          <Route exact path="/login" component={LoginScreen} />
          <Route exact path="/contact" component={ContactPage} />
          <Route exact path="/about" component={AboutPage} />
          <Route exact path="/courses" component={CoursePage} />
          <Route exact path="/cart" component={CartPage} />
          <Route exact path="/studentdashboard" component={StudentDashboard} />
          <Route exact path="/mycourses/:courseId" component={StudentInnerCoursePage} />
          <Route exact path="/coursedetail/Buy:courseId" component={InnerCoursePage} />
          <Route exact path="/resetpassword" component={ForgotPasswordScreen} />
          <Route exact path="/content" component={FreeContentScreen} />
          <Route exact path="/terms" component={Terms} />
          <Route exact path="/privacy" component={Privacy} />
          <Route exact path="/refund-policy" component={Refund} />
          <Route exact path="/blogs" component={Blog} />
          <Route exact path="/blog/:id" component={BlogByid} />
          <Route exact path="/thank-you-nse/:orderid" component={ThankYouPage} />
          {/* new page */}
          {/* <Route exact path="/mainpage" component={MainPage}/>  */}
          <Route exact path="/courses/frm" component={FrmMainPage} />
          <Route exact path="/courses/equity-research-training" component={ErtMainPage} />
          <Route exact path="/courses/cfa" component={CfaMainPage} />
          <Route exact path="/courses/ncfm" component={NcfmMainPage} />

          <Route exact path="/thank-you" component={ThankYouPageForm} />
          <Route component={NotFound} />
        </Switch>
        <Footer />


      </ScrollToTop>
    </Router>


  );
}

export default App;
