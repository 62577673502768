import React from 'react';
import { AiOutlineCheckSquare } from 'react-icons/ai';
import { IoIosArrowDropright } from 'react-icons/io';

const CoursesFeaturesCard = () => {
  return <div className=" bg-gray">
      

      {/* Career Benefits */}

  

    {/* new */}

    <div className="container">
    <div className="row justify-content-center align-items-center mb-5 py-5">
  <div className="col-lg-4 rightContainer">
    <div className="">
        <img src="/assets/Icons/4.svg" className="img-fluid" alt="undraw_ask_me_anything_re_x7pm" />
    </div>
  </div>
  <div className="col-lg-7 leftContainerDfaDetail">
    <h4 className="leftContainerDfaDetail mt-5  " >What is CFA Courses Online? What do they offer?</h4>
   <p>Our CFA Course Online is regarded as the world’s most respected and recognized designation worldwide, in the finance and investment market. 
This course offers skills such as investment tools, asset valuation, investment banking, equity research, portfolio management, and wealth planning. Our CFA Certification Course 2021 is mainly pursued by those with backgrounds in finance, accounting, economics, or business. The aim behind our course is to promote and develop a high level of education, professional and ethical standards in the finance and investment industry. 
</p>
  </div>
 
  </div>
    </div>


  </div>;
};

export default CoursesFeaturesCard;
