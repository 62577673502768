import React from 'react'
import Slider from 'react-slick';
import "./style.css"
const CourseReviewTestimonial = ({ courseReviewData, minHeightCard = "auto" }) => {
    var settings = {

        infinite: true,
        speed: 500,
        slidesToShow: 2,
        draggable: true,
        centerMode: true,
        arrows: true,
        dots: true,
        nav: true,
        autoplay: true,
        autoplaySpeed: 1500,
        pauseOnHover: true,
        responsive: [{
            breakpoint: 1024,
            settings: {
                slidesToShow: 1,
            }
        }]
    };


    return (
        <div>
            <section className="review-testimonial section-padding" >
                <div className="container">
                    <div className="row align-items-center justify-content-center mb-3">
                        <div className="col-lg-7">
                            <div className="section-heading center-heading">
                                <h3 className="text-primary-emphasis" >Review</h3>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid ">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="testimonials-slides ">
                                <Slider {...settings}>
                                    {
                                        courseReviewData && courseReviewData.map((item, index) => {
                                            return (
                                                <div className="review-testimonial-item-wrapper ">
                                                    <div className="review-testimonial-item " style={{ minHeight: minHeightCard }} >
                                                        <div className="review-testimonial-info-title">
                                                            <h4>{item.name}</h4>
                                                        </div>

                                                        <div className="review-testimonial-info-desc">
                                                            {item.desc}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                </Slider>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default CourseReviewTestimonial;