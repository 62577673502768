import React from 'react'
import Slider from "react-slick";
import {Link } from 'react-router-dom'
import Shivam from '../../assets/testing/shivam.jpeg'
import kaushal from '../../assets/testing/kaushal.jpeg'
import sakshi from '../../assets/testing/sakshi.jpeg'


export default function Testimonial() {

  var settings = {
      
    infinite: true,
    speed: 500,
    slidesToShow: 1,
   draggable : true,
    centerMode : true,
    autoplay : true,
    autoplaySpeed : 1500, 
    arrows : false,
    dots : true,
  };

    return (
        <div>
               {/* Testimonial section start */}
      <section className="testimonial-2 section-padding">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-6 col-xl-5 mr-auto">
              <div className="section-heading">
                <span className="subheading">Testimonials</span>
                <h3>Success Stories from students</h3>
                <p>The ultimate academic solution for students who want to reach 
                  their personal goals as well as Academic goals.
                  High Quality curated courses from Top instructors brings you the
                  knowledge at your fingertips.</p>
                <p> <Link to="/courses" className="text-underline d-block">Buy Courses<i className="fa fa-angle-right ml-2" /></Link></p>
              </div>
            </div>
            <div className="col-lg-6 col-xl-6">
              <div >






<Slider {...settings} >




<div className="testimonial-item">
                  <i className="fa fa-quote-right" />
                  <div className="client-info">
                    <img src={Shivam} alt="" style={{width : '70px' ,height : '70px' ,objectFit : 'cover'}} className="img-fluid" />
                    <div className="testionial-author">Shivam,Junior Analyst at Dallas Venture Capital</div>
                  </div>
                  <div className="testimonial-info-title">
                    <h4>  I have known Ishaan and Murrad for more than a year and they are rockstars in a
                       true sense.</h4>
                  </div>
                  <div className="testimonial-info-desc">
                 They have proven that no dream is small and two partners who believe in each other can make it big..
                  </div>
                </div>


    <div className="testimonial-item">
                  <i className="fa fa-quote-right" />
                  <div className="client-info">
                    <img src={kaushal} alt="" style={{width : '70px' ,height : '70px' ,objectFit : 'cover'}} className="img-fluid" />
                    <div className="testionial-author">Kaushal Subudhi, PGDM(FINANCE), CAT</div>
                  </div>
                  <div className="testimonial-info-title">
                    <h4> Best support staff & Instructors.</h4>
                  </div>
                  <div className="testimonial-info-desc">
                  I hold more than 500 hours of teaching and I take CFA classes with FinLadder. Trust me you'll not find any better support staff and bonding.
                  </div>
                </div>


                <div className="testimonial-item">
                  <i className="fa fa-quote-right" />
                  <div className="client-info">
                    <img src={sakshi} alt="" style={{width : '70px' ,height : '70px' ,objectFit : 'cover' , objectPosition : 'top'}} className="img-fluid" />
                    <div className="testionial-author">Sakshi Patni</div>
                  </div>
                  <div className="testimonial-info-title">
                    <h4> Amazing Course , Amazing Teachers !</h4>
                  </div>
                  <div className="testimonial-info-desc">
                  I had taken Investment Analysis and Portfolio Management course 
                  from FinLadder after talking to Ishaan. I liked the course so much that
                  I ended up taking other courses too. Definition of the courses in one word -
Amazing!
                  </div>
                </div>





</Slider>







          
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Testimonial section End */}
        </div>
    )
}
