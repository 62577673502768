import React from 'react'

export default function CourseInstructor({instructor}) {

console.log('instructr',instructor)

    return (



   <div className="row" style={{marginTop  : '20px' , padding : '20px'}}>
            <div className="col-lg-4 col-md-4">
              <div className="instructor-image">
                <img src={instructor.imageLink} alt={instructor.name} className="img-fluid" />
              </div>
            </div>
            <br/>
            <div className="col-lg-8 col-md-8">
              <div className="instructor-content">
                <h4><a >{instructor.name}</a></h4>
                <span className="sub-title"><p>{instructor.company}</p></span>
                {/* <p>Jone Smit is a celebrated photographer, author, and writer who brings passion to everything he does.</p> */}
                {/* <ul className="user-social-links">
                  <li><a href="#"> <i className="fab fa-facebook" /></a></li>
                  <li><a href="#"> <i className="fab fa-twitter" /></a></li>
                  <li><a href="#"> <i className="fab fa-linkedin" /></a></li>
                </ul> */}
              </div>
            </div>
            </div>



    )
}
