import React from 'react';
import { AiOutlineCheckSquare } from 'react-icons/ai';
import { IoIosArrowDropright } from 'react-icons/io';

const CoursesFeaturesCard = () => {
  return <div className=" bg-gray">
      

      {/* Career Benefits */}

  

    {/* new */}

    <div className="container">
    <div className="row justify-content-center align-items-center mb-5 py-5">
  <div className="col-lg-4 rightContainer">
    <div className="">
        <img src="/assets/Icons/4.svg" className="img-fluid" alt="undraw_ask_me_anything_re_x7pm" />
    </div>
  </div>
  <div className="col-lg-7 leftContainerDfaDetail">
    <h4 className="leftContainerDfaDetail mt-5  " >What are Equity Research Training Courses Online? What do they offer?</h4>
   <p>Equity Research Training Course helps you become a professional Stock Market  Analyst. This course will teach you the facts and figures of the capital market and provide an overview of Industry Analysis. 
</p>
<p>Students will learn about both qualitative and quantitative aspects of company analysis with live examples. Our course offers knowledge of the financial market from scratch. </p>
<p>Equity Research Training Certification Course 2022 Online was specifically designed for those who wish to gain knowledge of deep market concepts such as: </p>
<ul>
        <li> <AiOutlineCheckSquare/>  &nbsp;  improve his/ her understanding of the capital markets</li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  become an Equity Research Analyst</li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  focus on long term investments</li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  learn how to invest</li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Construct and manage individual portfolios</li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Become self-sufficient in managing money  </li>
      </ul>
  </div>
 
  </div>
    </div>


  </div>;
};

export default CoursesFeaturesCard;
