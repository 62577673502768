import React, { useEffect, useState } from "react";
import logo from "../../assets/logo-white.png";
import { Link, useParams } from "react-router-dom";
import { getBlogsBYID } from "../../Api";
import reacthtmlparse from "react-html-parser";

export default function Blog() {
  // get id from useHistorylet history =usehi
  let history = useParams();
  console.log(history);
  let [blogs, setblogs] = useState([]);
  useEffect(() => {
    Blogs();
  }, [0]);
  const Blogs = async () => {
    let data = await getBlogsBYID(history.id);
    console.log(data, history.id);
    setblogs(data.data);
  };
  return (
    <>
      <div className="container">
        <div class="row">
          <div class="leftcolumn">
            <div class="card">
              <h2>{blogs.heading}</h2>
              <div class="fakeimg">
                {" "}
                <img src={blogs.thumbImage} class="card-img-top" alt="..." />
              </div>
              {/* <h5>
                {blogs.shortContent} {blogs.month} {blogs.day}
              </h5> */}
              <br />
              <p>{reacthtmlparse(blogs.content)}</p>
            </div>
          </div>
          <div class="rightcolumn">
            <div class="card">
              <h2>{blogs.authorName}</h2>
              <img src={blogs.authorImage} style={{ height: "100px" }} />
              <p>{blogs.authorDesc}</p>
              <p>
                <div class="social">
                  {blogs?.socialAcc?.fb ? (
                    <a target="_blank" className='m-2' href={blogs?.socialAcc?.fb}>
                      <i class="fab fa-facebook-f"></i>
                    </a>
                  ) : null}
                  {blogs?.socialAcc?.twitter ? (
                    <a target="_blank" className='m-2' href={blogs?.socialAcc?.twitter}>
                      <i class="fab fa-twitter"></i>
                    </a>
                  ) : null}
                  {blogs?.socialAcc?.linkendin ? (
                    <a target="_blank" className='m-2' href={blogs?.socialAcc?.linkendin}>
                      <i class="fab fa-linkedin-in"></i>
                    </a>
                  ) : null}
                </div>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
