import React from 'react';
import { AiOutlineCheckSquare } from 'react-icons/ai';
import "./Mainpage.css"

const Timeline = () => {
  return <div className="container mt-4" >  
   <div className="">
          <h2 className="text-center" >Modules of CFA </h2>
          <p>Our CFA modules include the foundational knowledge that firms as expect from the candidates. The curriculum of our course includes skills that are needed in investment management today. We keep our curriculum up to the date, so it reflects modern financial practices. The syllabus is designed to provide required skills such as critical advanced investment analysis and portfolio management skills. The course has a total of seven units. Candidates must adhere to the syllabus that corresponds to their level. Here is the overview of the curriculum. </p>
          <h4>CFA Module Full Syllabus</h4>
          <p>The candidates can find CFA Course Online Modules Full Syllabus here. Each module is summarized along with the skills under them. Kindly find it helpful. 

Read on to learn more about our CFA Course Details and the syllabus.
</p>
      </div>
      <div class="timeline mt-5">
     
  <div class="Customecontainer bg left">
    <div class="content">
      <h5>Industry Overview
</h5>
      <p>The core purpose of Module1 is to familiarize the investment industry to the candidates. The candidates learn about the advantages of well-done work and the negative consequences of poorly-done work on the market.</p>
      <ul>
        <li> <AiOutlineCheckSquare/> &nbsp; The investment industry; A Top-Down View</li>
      </ul>
    </div>
  </div>
  <div class="Customecontainer right">
    <div class="content">
      <h5>
      Ethics and regulations</h5>
      <p>2nd Modules focus on the foundation of the investment industry. Skills like how to build trust, reputation, confidence, and value essential matters are taught to the candidates</p>
      <ul>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Ethics and investment professionalism</li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Regulation</li>
      </ul>
    </div>
  </div>
  <div class="Customecontainer left">
    <div class="content">
      <h5>Inputs and Tools</h5>
      <p>Here we familiarise the candidates with the economic world. They are taught about how their actions can affect the industry at the micro, macro, and international level. Candidates get a clearer understanding of the economic world. </p>
      <ul>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Microeconomics </li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Macroeconomics</li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Economics of international trade</li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Financial Statement</li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Quantitative Concepts</li>
      </ul>
    </div>
  </div>
  <div class="Customecontainer right">
    <div class="content">
      <h5>Investment Instruments</h5>
      <p>This module covers basic investment options. Each investment aspect is discussed in depth here. What are essential investment instruments and what is their purpose is all that candidates learn under this module</p>
      <ul>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Debt Securities </li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Equity Securities</li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Derivatives</li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Alternative investments</li>
      </ul>
    </div>
  </div>
  <div class="Customecontainer left">
    <div class="content">
      <h5>Industry Structure</h5>
      <p>This module teaches topics like who are investing in this industry, what are they investing in, a different markets place where investors can take place, etc</p>
      <ul>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Structure of the investment industry </li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Investment vehicles</li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  The functioning of financial markets</li>
      </ul>
    </div>  
  </div>
  <div class="Customecontainer right">
    <div class="content">
      <h5>Serving Clients Need</h5>
      <p>As the name suggests, this module is all about clients and their needs. CFA Course Online teaches all about clients. So that investments can be allocated and managed in the right way for them.</p>
      <ul>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Investors and their needs </li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Investment management</li>
        
      </ul>
    </div>  
  </div>
  <div class="Customecontainer left">
    <div class="content">
      <h5>Industry Control</h5>
      <p>Controls are essential for ensuring that everything functions smoothly. To guarantee that the customer is appropriately served in the fast-paced world of investments and risk, it is critical to understand how systems and controls are used in the sector. This module discusses it all in-depth.</p>
      <ul>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Risk Management </li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Performance Evaluation</li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Investment industry documentation</li>
      </ul>
    </div>  
  </div>
  
</div>
  </div>;
};

export default Timeline;
