import React from 'react'
import Gif1 from '../../assets/mapping-gif.gif'
import Gif2 from '../../assets/personalised-gif.gif'
import Gif3 from '../../assets/Engaging-gif.gif'
import {Link} from 'react-router-dom'

export default function ThreeFeatures() {
    return (
        <div style={{background : '#fcfcfc'}}>
            


     {/* Banner Section Start */}
     <section className=" section-padding">
          <div className="container">
            <div className="row align-items-center">
              <div data-aos="fade-right" data-aos-duration="2500" className="col-md-12 col-xl-6 col-lg-6">
                <div className="banner-content">
                  {/* <span className="subheading">Expert instruction</span> */}
                  <h1>Engaging Video Lessons</h1>
                  <p>Videos that help you visualize each concept, making it easier to understand. Clearer concepts lead to better Interview. </p>
                  <Link to="/courses" className="btn btn-main">View More Videos </Link>  
                </div>
              </div>
              <div data-aos="fade-left" data-aos-duration="2500" className="col-md-12 col-xl-6 col-lg-6">
              <img src={Gif1} alt="" className="img-fluid" />
                {/* <div className="banner-img-round mt-5 mt-lg-0">
                
                </div> */}
              </div>
            </div> {/* / .row */}
          </div> {/* / .container */}
        </section>
        {/* Banner Section End */}


     {/* Banner Section Start */}
     <section className=" section-padding">
          <div className="container">
            <div className="row align-items-center" style={{flexWrap:"wrap-reverse"}}>


            <div data-aos="fade-right" data-aos-duration="2500"  className="col-md-12 col-xl-6 col-lg-6">
              <img src={Gif2} alt="" className="img-fluid" />
                {/* <div className="banner-img-round mt-5 mt-lg-0">
                
                </div> */}
              </div>



              <div data-aos="fade-left" data-aos-duration="2500" className="col-md-12 col-xl-6 col-lg-6">
                <div className="banner-content">
                  {/* <span className="subheading">Expert instruction</span> */}
                  <h1>Personalized Learning Journeys</h1>
                  <p>Learning is easier than ever with FinLadder. Users can customize the learning process based on their own needs and level of expertise.</p>
                  <Link to="/courses" className="btn btn-main">View Courses </Link>  
                </div>
              </div>
           
            </div> {/* / .row */}
          </div> {/* / .container */}
        </section>
        {/* Banner Section End */}





   {/* Banner Section Start */}
   <section className=" section-padding">
          <div className="container">
            <div className="row align-items-center">


           

              <div  data-aos="fade-right" data-aos-duration="2500" className="col-md-12 col-xl-6 col-lg-6">
                <div className="banner-content">
                  {/* <span className="subheading">Expert instruction</span> */}
                  <h1>Mapped to the Syllabus</h1>
                  <p>At FinLadder, we’re confident in our ability to provide effective and educational videos in several subjects and for various levels.</p>
                  <Link to="/courses" className="btn btn-main">Start learning </Link>  
                </div>
              </div>


              <div data-aos="fade-left" data-aos-duration="2500" className="col-md-12 col-xl-6 col-lg-6">
              <img src={Gif3} alt="" className="img-fluid" />
                {/* <div className="banner-img-round mt-5 mt-lg-0">
                </div> */}
              </div>
           
            </div> {/* / .row */}
          </div> {/* / .container */}
        </section>
        {/* Banner Section End */}










        </div>
    )
}
