import React from 'react';
import { AiOutlineCheckSquare } from 'react-icons/ai';

const CfaDetailPage = () => {
  return <div>
    <div className="container my-5">
      <div className="row justify-content-center align-items-center">
        <div className="col-lg-7 leftContainerDfaDetail">
          <h4 className="leftContainerDfaDetail mt-5" >Why Equity Research Training?</h4>

          <ul>
            <div className="d-flex justify-content-center align-items-start ">
              <AiOutlineCheckSquare size={20} />
              <li className="pb-1 ml-2 DetailList " >
                Our course is certified by the National Stock exchange Academy after its completion.
              </li>
            </div>
            <div className="d-flex justify-content-center align-items-start ">
              <AiOutlineCheckSquare size={20} />
              <li className="pb-1 ml-2 DetailList " >
                This course provides a foundation for understanding Fundamental Analysis.
              </li>
            </div>
            <div className="d-flex justify-content-center align-items-start ">
              <AiOutlineCheckSquare size={20} />
              <li className="pb-1 ml-2 DetailList " >
                The course is scientifically structured to help the participants understand the basic concepts and terminologies relating to the capital market and their application for investing in stocks.
              </li>
            </div>
            <div className="d-flex justify-content-center align-items-start ">
              <AiOutlineCheckSquare size={20} />
              <li className="pb-1 ml-2 DetailList " >
                One can use it for conducting a stock valuation and predicting its price evolution, making a forecast on the company's performance, evaluating its management, making internal decisions, as well as calculating its credit risk.
              </li>
            </div>
            <div className="d-flex justify-content-center align-items-start ">
              <AiOutlineCheckSquare size={20} />
              <li className="pb-1 ml-2 DetailList " >
                We offer Equity Research Training Courses Books to help learners grasp the course better.
              </li>
            </div>

          </ul>
        </div>
        <div className="col-lg-4 rightContainer">
          <div className="  ">
            <img src="/assets/Icons/3.svg" className="img-fluid" alt="undraw_ask_me_anything_re_x7pm" />
          </div>
        </div>
      </div>
      {/* card  start*/}
      <div className="row justify-content-center align-items-center my-5">
        <div className="col-lg-4 rightContainer">
          <div className="">
            <img src="/assets/Icons/8.svg" className="img-fluid" alt="undraw_ask_me_anything_re_x7pm" />
          </div>
        </div>
        <div className="col-lg-7 leftContainerDfaDetail">
          <h4 className="leftContainerDfaDetail   " >Equity Research Training Courses Online Fees  </h4>
          <p>This section provides information about Stock Market Courses Online fees. </p>
          <ul>
            <div className="d-flex justify-content-center align-items-start ">
              <AiOutlineCheckSquare size={20} />
              <li className="pb-1 ml-2 DetailList " >
                You can get a 10% discount on the course with a one-time purchase.
              </li>
            </div>
            <div className="d-flex justify-content-center align-items-start ">
              <AiOutlineCheckSquare size={20} />
              <li className="pb-1 ml-2 DetailList " >
                Choose from one of our business financing partners to apply for an educational loan with a 9-18 months repayment period.
              </li>
            </div>

            <div className="d-flex justify-content-center align-items-start ">
              <AiOutlineCheckSquare size={20} />
              <li className="pb-1 ml-2 DetailList " >
                Stock Market Course Online Fees can be paid in installments. The number of installments might range from three to five, depending on the candidate's preference.
              </li>
            </div>




          </ul>
        </div>

      </div>
      {/* card end  */}
      {/* card  start*/}
      <div className="row justify-content-center align-items-center my-5">

        <div className="col-lg-7 leftContainerDfaDetail">
          <h4 className="leftContainerDfaDetail   " >Equity Research Training Courses Outline</h4>
          <p>If you are eager to do analysis and deal with figures, Equity Research Training is the right choice. In case you need an overview of our Stock Market Equity Courses like curriculum, duration, and other details. Here is a summary of what this course has to offer.                                                                                                                </p>
          <ul>
            <div className="d-flex justify-content-center align-items-start ">
              <AiOutlineCheckSquare size={20} />
              <li className="pb-1 ml-2 DetailList " >
                The primary task of equity research is to find out the listed companies and perform the valuations. You will look at different financial statements of the companies like balance sheet, cash flow, discount cash flow (DCF).
              </li>
            </div>
            <div className="d-flex justify-content-center align-items-start ">
              <AiOutlineCheckSquare size={20} />
              <li className="pb-1 ml-2 DetailList " >
                Furthermore, the course focuses on the economic and industry parameters of the companies.
              </li>
            </div>
            <div className="d-flex justify-content-center align-items-start ">
              <AiOutlineCheckSquare size={20} />
              <li className="pb-1 ml-2 DetailList " >
                This course teaches you the basic functions and the nitty-gritty of equity research. We will be covering a variety of technologies and tools in the course.
              </li>
            </div>
            <div className="d-flex justify-content-center align-items-start ">
              <AiOutlineCheckSquare size={20} />
              <li className="pb-1 ml-2 DetailList " >
                Once the duration of the course completes, you will be able to use all the techniques and tools all by yourself.
              </li>
            </div>
            <div className="d-flex justify-content-center align-items-start ">
              <AiOutlineCheckSquare size={20} />
              <li className="pb-1 ml-2 DetailList " >
                We help you become self-sufficient through our course.
              </li>
            </div>

            <div className="d-flex justify-content-center align-items-start ">
              <AiOutlineCheckSquare size={20} />
              <li className="pb-1 ml-2 DetailList " >
                As an equity analyst, you'll be well-equipped to perform financial modeling. Financial modeling includes projecting the firm's financials and estimating the fair value of the company using DCF Valuation trading multiple values and other valuation methods.
              </li>
            </div>

          </ul>
        </div>
        <div className="col-lg-4 rightContainer">
          <div className="">
            <img src="/assets/Icons/1.svg" className="img-fluid" alt="undraw_ask_me_anything_re_x7pm" />
          </div>
        </div>

      </div>
      {/* card end  */}
      {/* card  start*/}
      <div className="row justify-content-center align-items-center my-5">
        <div className="col-lg-4 rightContainer">
          <div className="">
            <img src="/assets/Icons/2.svg" className="img-fluid" alt="undraw_ask_me_anything_re_x7pm" />
          </div>
        </div>
        <div className="col-lg-7 leftContainerDfaDetail">
          <h4 className="leftContainerDfaDetail   " >Equity Research Training Course Eligibility</h4>
          <p>There are no specific criteria for this course. Anyone who is serious about learning Equity research and wants to make a career in this field can opt for this course. There is no specific qualification required to become a Stock Market analyst. Graduates in the field of commerce, finance, or investment are usually preferred, but it’s not a compulsion.                                                                                                                </p>
          <p> <b>Due to the high level of competition in this field, it is recommended to acquire a degree from a financial course, just to get a foundation in finance. Graduates with a non-finance background will benefit from this technique. </b>  </p>

        </div>


      </div>
      {/* card end  */}
      {/* card  start*/}
      <div className="row justify-content-center align-items-center my-5">

        <div className="col-lg-7 leftContainerDfaDetail">
          <h4 className="leftContainerDfaDetail   " >Equity Research Training Test Details</h4>
          <p>Find out more about the test details in the following section.                                                                                                                </p>
          <ul>
            <div className="d-flex justify-content-center align-items-start ">
              <AiOutlineCheckSquare size={20} />
              <li className="pb-1 ml-2 DetailList " >
                The duration of the examination is 1.5 hours.
              </li>
            </div>
            <div className="d-flex justify-content-center align-items-start ">
              <AiOutlineCheckSquare size={20} />
              <li className="pb-1 ml-2 DetailList " >
                The test will consist of 50 multiple choice questions worth 2 marks each.
              </li>
            </div>
            <div className="d-flex justify-content-center align-items-start ">
              <AiOutlineCheckSquare size={20} />
              <li className="pb-1 ml-2 DetailList " >
                You must score 50 marks to pass the exam.
              </li>
            </div>
            <div className="d-flex justify-content-center align-items-start ">
              <AiOutlineCheckSquare size={20} />
              <li className="pb-1 ml-2 DetailList " >
                There will be negative markings in the exam.
              </li>
            </div>
            <div className="d-flex justify-content-center align-items-start ">
              <AiOutlineCheckSquare size={20} />
              <li className="pb-1 ml-2 DetailList " >
                •The National Stock Exchange hosts the Equity Research Training examination. It is mandatory for the candidates to take the examination at an NSE-approved test center only.
              </li>
            </div>

            <div className="d-flex justify-content-center align-items-start ">
              <AiOutlineCheckSquare size={20} />
              <li className="pb-1 ml-2 DetailList " >
                The exam fees, inclusive of GST, must be paid by the student prior to the Equity Research Training Online Course assessment.
              </li>
            </div>
            <div className="d-flex justify-content-center align-items-start ">
              <AiOutlineCheckSquare size={20} />
              <li className="pb-1 ml-2 DetailList " >
                Prior to the examination, candidates will receive Equity Research Training Login.
              </li>
            </div>

          </ul>
        </div>
        <div className="col-lg-4 rightContainer">
          <div className="">
            <img src="/assets/Icons/10.svg" className="img-fluid" alt="undraw_ask_me_anything_re_x7pm" />
          </div>
        </div>

      </div>
      {/* card end  */}
      {/* card  start*/}
      <div className="row justify-content-center align-items-center my-5">
        {/* <div className="col-lg-4 rightContainer">
          <div className="">
            <img src="/assets/Icons/2.svg" className="img-fluid" alt="undraw_ask_me_anything_re_x7pm" />
          </div>
        </div> */}
        <div className="col-lg-12 leftContainerDfaDetail">
          <h4 className="leftContainerDfaDetail   " >Learn With eFinladder</h4>
          <p className="" >
            <b>Equity researchers</b> are responsible for identifying patterns in current market price fluctuations and utilizing this data to develop algorithms that identify profitable stock investment opportunities and require professional interventions such as equity research training or equity research training course handpicked byeFinladder professionals.
          </p>
          <p className="" >As we have settled down the curiosity behind ‘what is <b>equity research training</b>’ to strengthen the financial intermediaries, you can hook into the popular equity research training course channelized by eFinladder which makes you industry ready to take up employment roles such as equity research training analyst. </p>
          <p className="" >In order to cross-compare domestic and foreign stocks, the equity research training program helps you to comprehend the unique characteristics of various international markets. The analysis of stocks by equity researchers assists portfolio managers in making more informed investment decisions. Equity researchers utilize problem-solving skills, data interpretation, and various other tools to comprehend and forecast the future behavior of a given security. </p>
          <p className="" >Typically, this requires a quantitative analysis of a stock's statistical data in light of recent market activity. Lastly, <b>equity research</b> training sessions may help you with creating investment models and screening tools that identify trading strategies that aid in portfolio risk management.</p>
          <p className="" >A thorough understanding of finance, economics, and accounting is necessary for a position in equity research or opting for a full-fledged course such as equity research training.</p>

          <p className="" ><b>An equity research training</b> session by eFinladder covers various modules which prepares  a researcher in equity capable of analyzing a company's financial statement. Additionally, it also makes aspirants proficient in financial modelling, Excel, and valuation techniques. </p>
          <p className="" >Various modules are taken into consideration while curating eFinladder's Equity Research training program such as financial Intermediaries, Bringing of IPO in the market, Primary and secondary market, SMA and WMA theory and Relative Strength Index.</p>
          <p className="" >If you're interested in a career in equity research, you should know that the organizational structure is relatively flat (unlike investment banking), and the two primary positions are Associate and Analyst and Equity research training analyst course is right at your getaway provided by eFinladder.</p>
          <p className="" >In contrast to other areas of corporate finance, the Analyst position is more senior than the Associate position. Typically, an associate (or multiple associates) work for a single Analyst, who is responsible for the overall coverage of a group of companies. </p>
          <p className="" >Analysts are typically divided into industry sectors in order to cover comparable companies. Most industries require a great deal of specialized knowledge, so it makes sense for an analyst to specialize in a single industry.</p>

          <p className="" >Get Certified as a <b>Financial Analyst</b> with <b>eFinladder’s Equity Research Training</b> programs. </p>

        </div>

        <h5 className="my-3 pl-2"  >Click here for Equity Research Training Course Online Modules Free Download. </h5>

      </div>
      {/* card end  */}

    </div>
  </div>;
};

export default CfaDetailPage;
