import React from 'react';
import { AiOutlineCheckSquare } from 'react-icons/ai';

const CfaDetailPage = () => {
  return <div>
       <div className="container my-5">
  <div className="row justify-content-center align-items-center">
  <div className="col-lg-7 leftContainerDfaDetail">
    <h4 className="leftContainerDfaDetail mt-5" >Why NCFM?</h4>
    <p>Since these courses are all about investing and selling, you must choose your course carefully. Why should you pick NCFM over any other option? We've put together a list of points to help you find the answer.</p>
    <ul>
    <div className="d-flex justify-content-center align-items-start ">
    <AiOutlineCheckSquare  size={20}  />
                  <li className="pb-1 ml-2 DetailList " >
                  Our NCFM modules prepare the candidates to the core for the examination.
                  </li>
    </div>
    <div className="d-flex justify-content-center align-items-start ">
    <AiOutlineCheckSquare  size={20}  />
                  <li className="pb-1 ml-2 DetailList " >
                  Both theoretical and practical knowledge is provided..  
                  </li>
    </div>
    <div className="d-flex justify-content-center align-items-start ">
    <AiOutlineCheckSquare  size={20}  />
                  <li className="pb-1 ml-2 DetailList " >
                  Candidates who are willing to get a basic knowledge of financial markets, products, stock market, particularly about the capital markets can also enroll in our NCFM Course Online, we have introduced a separate beginner’s module.
                  </li>
    </div>
    <div className="d-flex justify-content-center align-items-start ">
    <AiOutlineCheckSquare  size={20}  />
                  <li className="pb-1 ml-2 DetailList " >
                  Financial Markets: A Beginner’s Module is for someone who wants basic knowledge of the financial market for maybe reading financial newspapers or to become an expert in this particular niche.  
                  </li>
    </div>
    <div className="d-flex justify-content-center align-items-start ">
    <AiOutlineCheckSquare  size={20}  />
                  <li className="pb-1 ml-2 DetailList " >
                  The candidates are asked to practice in the live markets, once the learning duration is over.
                  </li>
    </div>
    <div className="d-flex justify-content-center align-items-start ">
    <AiOutlineCheckSquare  size={20}  />
                  <li className="pb-1 ml-2 DetailList " >
                  Our course is one of the best-recognized courses in India when it comes to financing.
                  </li>
    </div>
    <div className="d-flex justify-content-center align-items-start ">
    <AiOutlineCheckSquare  size={20}  />
                  <li className="pb-1 ml-2 DetailList " >
                  If the course is in online mode, that does not mean we totally cut off the physical interaction, our NCFM faculty does arrange workshops and meetings of the candidates with industry experts. Mock tests are also held in both online and offline mode. 
                  </li>
    </div>
      
    <div className="d-flex justify-content-center align-items-start ">
    <AiOutlineCheckSquare  size={20}  />
                  <li className="pb-1 ml-2 DetailList " >
                  When the course was designed, each aspect of learning was taken into consideration, hence we built a 5 tier exposure Faculty and they are mainly called as 
                  <ul>
  
  <li> <AiOutlineCheckSquare/>  &nbsp;  Practical faculty </li>
  <li> <AiOutlineCheckSquare/>  &nbsp;  Older Investor and Trader</li>
  <li> <AiOutlineCheckSquare/>  &nbsp;  Research Team</li>
  <li> <AiOutlineCheckSquare/>  &nbsp;  Group Discussion</li>
  <li> <AiOutlineCheckSquare/>  &nbsp;  Reliance Securities</li>



</ul>
                  </li>
    </div>
    <div className="d-flex justify-content-center align-items-start ">
    <AiOutlineCheckSquare  size={20}  />
                  <li className="pb-1 ml-2 DetailList " >
                  Once the duration of the course is over the candidates will get
                  <ul>
  
  <li> <AiOutlineCheckSquare/>  &nbsp;  ICFM NISM Certification </li>
  <li> <AiOutlineCheckSquare/>  &nbsp;  Practical Knowledge</li>
  <li> <AiOutlineCheckSquare/>  &nbsp;  Job Assistance Programs
</li>



</ul>

                  </li>

    </div>
<p>We solely aim to make our youth 100% confident in trading and earning through it. We believe that your investment in our NCFM Course Online must profit you. And as a futures trader and investor, you must know that no investment must go without profit.</p>
 
      
                </ul>
  </div>
  <div className="col-lg-4 rightContainer">
    <div className="  ">
        <img src="/assets/Icons/1.svg" className="img-fluid" alt="undraw_ask_me_anything_re_x7pm" />
    </div>
  </div>
  </div>
  {/* card  start*/}
  <div className="row justify-content-center align-items-center my-5">
  <div className="col-lg-4 rightContainer">
    <div className="mt-2">
        <img src="/assets/Icons/5.svg" className="img-fluid" alt="undraw_ask_me_anything_re_x7pm" />
    </div>
  </div>
  <div className="col-lg-7 leftContainerDfaDetail">
    <h4 className="leftContainerDfaDetail   " >NCFM Course Online Fee </h4>
    <p>NCFM Certification Course Fees In India could be afforded by most individuals. This course being an online course saves transportation and other materialistic expenses.  </p>
    <ul>
    <div className="d-flex justify-content-center align-items-start ">
    <AiOutlineCheckSquare  size={20}  />
                  <li className="pb-1 ml-2 DetailList " >
                  You can save up to 20% on one-time payments on our NCFM Certification Course 2021.
                  </li>
    </div>
    <div className="d-flex justify-content-center align-items-start ">
    <AiOutlineCheckSquare  size={20}  />
                  <li className="pb-1 ml-2 DetailList " >
                  Choose from one of our business financing partners to apply for an educational loan with a 9-18 months repayment period.
                  </li>
    </div>
    
            <p className="py-2" > After completing NCFM Full Course Duration, the candidates will be taught NCFM Login for examination purposes.  </p>
   
            
                  
                </ul>
  </div>
 
  </div>
  {/* card end  */}
  {/* card  start*/}
  <div className="row justify-content-center align-items-center my-5">
 
  <div className="col-lg-7 leftContainerDfaDetail">
    <h4 className="leftContainerDfaDetail   " >NCFM Course Outline</h4>
    
    <ul>
    <div className="d-flex justify-content-center align-items-start ">
    <AiOutlineCheckSquare  size={20}  />
                  <li className="pb-1 ml-2 DetailList " >
                  The course is designed to introduce risk and methods of the financial market to the candidates
                  </li>
    </div>
    <div className="d-flex justify-content-center align-items-start ">
    <AiOutlineCheckSquare  size={20}  />
                  <li className="pb-1 ml-2 DetailList " >
                  We groom you to become high-powered financial professionals in a range of fields, including the stock exchange, banking, investing, and other financial services.
                  </li>
    </div>
    <div className="d-flex justify-content-center align-items-start ">
    <AiOutlineCheckSquare  size={20}  />
                  <li className="pb-1 ml-2 DetailList " >
                  We provide you with relevant training in all the modules. The modules covered under our course include Beginner’s Module, Intermediate Module, and Advanced modules.
                  </li>
    </div>
    <div className="d-flex justify-content-center align-items-start ">
    <AiOutlineCheckSquare  size={20}  />
                  <li className="pb-1 ml-2 DetailList " >
                  We will award you one certificate for each module that you complete successfully.
                  </li>
    </div>
   
    <div className="d-flex justify-content-center align-items-start ">
    <AiOutlineCheckSquare  size={20}  />
                  <li className="pb-1 ml-2 DetailList " >
                  Our courses are backed by extensive research, resulting in a learning method that is both relevant and engaging for our students.
                  </li>
    </div>
   
                  
                </ul>
  </div>
  <div className="col-lg-4 rightContainer">
    <div className="mt-2">
        <img src="/assets/Icons/7.svg" className="img-fluid" alt="undraw_ask_me_anything_re_x7pm" />
    </div>
  </div>
 
  </div>
  {/* card end  */}
  {/* card  start*/}
  <div className="row justify-content-center align-items-center my-5">
  <div className="col-lg-4 rightContainer">
    <div className="mt-2">
        <img src="/assets/Icons/8.svg" className="img-fluid" alt="undraw_ask_me_anything_re_x7pm" />
    </div>
  </div>
  <div className="col-lg-7 leftContainerDfaDetail">
    <h4 className="leftContainerDfaDetail   " >NCFM Course Eligibility</h4>
    <p>Under this heading, the candidates will find information regarding NCFM Course Eligibility And Price, so kindly read it carefully to get all your doubts cleared. </p>  
<ul>
   <div className="d-flex justify-content-center align-items-start ">
   <AiOutlineCheckSquare  size={20}  />
                 <li className="pb-1 ml-2 DetailList " >
                 Candidates who have completed their 12th class from a well-recognized board are eligible to join these courses.  But if the candidate has a bachelor’s degree it will be a bit easier for the candidate to get jobs in big companies, the rest of the undergraduates will be promoted to small companies only.
                 </li>
   </div>
   <div className="d-flex justify-content-center align-items-start ">
   <AiOutlineCheckSquare  size={20}  />
                 <li className="pb-1 ml-2 DetailList " >
                 The candidates are required to submit all the original documents - 10th, 12th graduation, other professional degrees they possess and the original documentation must be shown to the center In Charge. If the candidate could not fulfill the requirement it will lead to the cancellation of their candidature.
                 </li>
   </div>
   <div className="d-flex justify-content-center align-items-start ">
   <AiOutlineCheckSquare  size={20}  />
                 <li className="pb-1 ml-2 DetailList " >
                 The candidate must maintain 95% attendance in each module opted by them.
                 </li>
   </div>
   
   <div className="d-flex justify-content-center align-items-start ">
   <AiOutlineCheckSquare  size={20}  />
                 <li className="pb-1 ml-2 DetailList " >
                 Remark of faculty and center head will be taken seriously after each class.
                 </li>
   </div>
   
   <div className="d-flex justify-content-center align-items-start ">
   <AiOutlineCheckSquare  size={20}  />
                 <li className="pb-1 ml-2 DetailList " >
                 Anybody who has an interest in the Indian stock/securities market can opt for these courses, does not matter whether you are a student, teacher, investor, employee of BPOs/ IT Companies, Employees of Brokers/Sub-Brokers, Housewives and the list of interested candidates goes on. It does not matter who you are or what your profession is all we need is your time and interest to bring the best out of you. 
                 </li>
   </div>
   
                 
               </ul>                                                                                                        
   
    
  </div>

 
  </div>
  {/* card end  */}
   {/* card  start*/}
   <div className="row justify-content-center align-items-center my-5">
 
 <div className="col-lg-7 leftContainerDfaDetail">
   <h4 className="leftContainerDfaDetail   " >NCFM Test Details</h4>
   <p>The candidates need to attempt a test based on the knowledge they have gained during this course. To get the certification of your opted course it is very crucial to appear for the test. During the test, the candidates are required to attempt 60 questions. Within 120 minutes/2hrs duration.                                                                                                                </p>
   <p>Hers is the exam pattern of the NCFM Test:</p>
   <ul>
   <li> <AiOutlineCheckSquare/>  &nbsp;  No. of questions: 60</li>
   <li> <AiOutlineCheckSquare/>  &nbsp; Maximum marks: 100 </li>
   <li> <AiOutlineCheckSquare/>  &nbsp; Passing marks: 50 (50%) </li>
   <li> <AiOutlineCheckSquare/>  &nbsp;  No negative marking will be done.</li>
   </ul>
<p> <b>Note</b>  : For successful candidates, the certificate is valid for the next 5 years from the test date.</p>
 </div>
 <div className="col-lg-4 rightContainer">
   <div className="mt-2">
       <img src="/assets/Icons/11.svg" className="img-fluid" alt="undraw_ask_me_anything_re_x7pm" />
   </div>
 </div>

 <h5 className="my-3"  >Click here for NCFM Course Online Modules Free Download. </h5>

 </div>
 {/* card end  */}

  </div>
  </div>;
};

export default CfaDetailPage;
