import React,{useState,useEffect} from "react";
import {Link} from 'react-router-dom'
import { useRecoilState } from "recoil";
import {cartState } from "../../Atoms";
import { useHistory } from 'react-router-dom'

export default function CourseCard({item,trending, categoryName}) {



  const [ userCart ,setUserCart ] = useRecoilState(cartState) ;
const [courseExists, setcourseExists] = useState(false)

const history  = useHistory()

useEffect(() => {
 
  let temp = [...userCart.cartCourses]
   
    
    temp.forEach(el=>{
      if(el._id==item._id){
        
       setcourseExists(true)
      }else{
       
      }
    })


  return () => {
  
  }
}, [])


//get the cart and push the course to it
  const addToCart = ()=>{

    let temp = [...userCart.cartCourses]
    temp.push(item)
    setUserCart({cartCourses : temp }) ;
    setcourseExists(true)
    }
  const buyNow = ()=>{

    let temp = [...userCart.cartCourses]
    temp.push(item)
    setUserCart({cartCourses : temp }) ;
    setcourseExists(true)
    history.push('/cart')
    }





  return (
  
      <div className={` ${trending ? (' course-card box-shadow trending-course-card') : ('course-card box-shadow')}`}>
        <div className="course-block">


        <Link to={`/coursedetail/Buy${item.courseId}`}>
          <div className="course-img">
            <img
              src={item.thumbnailImage}
              alt={`${item.courseName} image`}
            style={{width:"100%",height:200}}
            />
            {/* <div className="course-price ">$120 </div> */}
          </div> </Link>
          <div className="course-content">

            <span className="course-cat">{categoryName}</span>
           
           <span className="course-heading">
            <h5 >
              { item.courseName.length > 40 && <Link to={`/coursedetail/Buy${item.courseId}`}>{item.courseName.slice(0,40)} ...</Link>}
              { item.courseName.length<=40 && <Link to={`/coursedetail/Buy${item.courseId}`}>{item.courseName.slice(0,40)}</Link>}

             
            </h5>
            <span><p style={{fontSize : '14px' , lineHeight : 1.7 , marginBottom : '5px'}}>{item.courseDescription}</p></span>
            </span>
         
         
            <div className="course-meta" style={{ display : 'flex' , flexDirection : 'row' , justifyContent : 'space-between'}}>
           

              <span className="course-duration">
                <i className="far fa-star" style={{color : '#be5a0e'}}/>
               {item.rating} Rating
              </span>

              
<span className="course-duration">
  <strong style={{fontSize : '16px' , color : '#8c33df'}}>Rs {item.price}</strong>
</span>




            </div>
       
        {/* <div className="row"> */}
          {/* <div className="col-lg-12"> */}
            {courseExists ? ( <button className="btn btn-gradient2" style={{marginTop : '5px' , color:'white', width: '100%'}} >Already in Cart</button>) : ( <button className="btn btn-gradient1" style={{marginTop : '5px' , color:'white', width: '100%'}} onClick={()=>addToCart()}>Add To Cart</button>)}
          {/* </div> */}
          {/* <div className="col-lg-12"> */}
            { courseExists ? ( <button className="btn btn-gradient2" style={{marginTop : '5px' , color:'white', width: '100%'}} onClick={()=>{history.push('/cart')}} >Buy Now</button>) : ( <button className="btn btn-gradient2" style={{marginTop : '5px' , color:'white', width: '100%'}} onClick={()=>buyNow()}>Buy Now</button>)}
          {/* </div> */}
        {/* </div> */}
         

         
       
          </div>
        </div>
      </div>
  
  );
}
