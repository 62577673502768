import React, { useEffect, useState } from "react";
import {Link} from 'react-router-dom'

export default function StudentCourseCard({item}) {

  const [comp_percent, setComp_percent] = useState(20)
  useEffect(() => {
    var temp_comp = 0
    var temp_total = 0
    item.topics.map(topic => {
      return topic.subTopics.map(subT => {
        if(subT.watched){
          temp_comp += 1
        }
        temp_total += 1
        return 
      })
    })
    // console.log(temp_comp)
    // console.log(temp_total)
    setComp_percent(temp_comp*100/temp_total)
  }, [])
  return (
  
      <div className="course-card">
        <div className="course-block">
          <div className="course-img" style={{position:'relative'}}>
            <img
              src={item.thumbnailImage}
              alt={`${item.courseName} image`}
              className="img-fluid"
            />
            <div style={{position:'absolute', top: 0, right: 0, color:'#fff', fontWeight: 'bold', backgroundColor:'green', padding: '5px 20px', borderBottomLeftRadius: '8px'}}>{comp_percent.toFixed(0)}% Completed</div>
            {/* <div className="course-price ">$120 </div> */}
          </div>
          <div className="course-content" style={{position:'relative'}}>

            {/* <span className="course-cat">Graphic design</span> */}
           
           <span className="course-heading">
            <h5 >
              { item.courseName.length > 40 && <Link to={`/mycourses/${item.courseId}`}>{item.courseName.slice(0,40)} ...</Link>}
              { item.courseName.length<40 && <Link to={`/mycourses/${item.courseId}`}>{item.courseName.slice(0,40)}</Link>}
            </h5>
            <span><p style={{fontSize : '14px' , lineHeight : 1.7 , marginBottom : '5px'}}>{item.courseDescription}</p></span>
            </span>
         
         
            <div className="course-meta" style={{ display : 'flex' , flexDirection : 'row' , justifyContent : 'space-between'}}>
           

              <span className="course-duration">
                <i className="far fa-star" style={{color : '#be5a0e'}}/>
                {item.rating} Rating
              </span>

              
<span className="course-duration">
<Link to={`/mycourses/${item.courseId}`}><strong style={{fontSize : '18px' , color : '#8c33df'}}>Watch Now</strong></Link>
</span>
            </div>
          </div>
        </div>
      </div>
  
  );
}
