import axios from 'axios'


const base_url = 'https://efinladder.in/api'
// const base_url = 'https://udemy-user.herokuapp.com'
// const base_url = 'http://localhost:4500'


export const userSignup =async(userData)=>{
    const res = await axios.post(`${base_url}/signup`,userData) ;
    return res ;
}

export const userSignin = async (userData) =>{
    const res = await axios.post(`${base_url}/signin`,userData) ;
    return res ;
}



export const contactFormDataApi = async (userData) =>{
    const res = await axios.post(`${base_url}/contact-us`,userData) ;
    return res ;
}



export const forgotPasswordRequest =async(email)=>{
    const res = await axios.post(`${base_url}/forgot`,email) ;
    return res ;
}

export const forgotPasswordOtp =async(otp)=>{
    const res = await axios.post(`${base_url}/verify-otp`,otp) ;
    return res ;
}


export const resetPassword =async(data)=>{
    const res = await axios.post(`${base_url}/reset-password`,data) ;
    return res ;
}


export const getAllCourses =async()=>{
    const res = await axios.get(`${base_url}/get-all-courses-by-all-category`) ;
    return res ;
}


export const getTrendingCourses =async()=>{
    const res = await axios.get(`${base_url}/get-trending-course`) ;
    return res ;
}
export const getBlogs =async()=>{
    const res = await axios.get(`${base_url}/blogs`) ;
    return res ;
}

// change base url 
export const getBlogsBYID =async(id)=>{
    const res = await axios.get(`https://udemy-user.herokuapp.com/blog/${id}`) ;
    return res ;
}

export const getCourseById =async(courseId)=>{
    const res = await axios.get(`${base_url}/get-course-by-classId`,{
        params : {
            courseId : courseId
        }
    }) ;
    return res ;
}


export const getCourseByUser =async(token)=>{
    const res = await axios.get(`${base_url}/getMyCart`,{
        headers :   {'Authorization': `${token}` }
    })
 
    return res ;
}


export const getMyCourseById =async(token,courseId)=>{
    const res = await axios.get(`${base_url}/getMyCourseById/${courseId}`,{
        headers :   {'Authorization': `${token}` }
    })
 
    return res ;
}



export const toggleWatched =async(token,courseId, topicIndex, subTopicIndex, act)=>{
    const res = await axios.put(`${base_url}/checkWatched`,{
        "courseId": courseId,
        "topicIndex":topicIndex,
        "subTopicIndex":subTopicIndex,
        "watched":act
    },{
        headers :   {'Authorization': `${token}` }
    })
 
    return res ;
}





////////// Payment Stuff Begins ///////////////////////
export const createOrder =async(token,data)=>{
    const res = await axios.post(`${base_url}/create-order`,data,{
        headers :   {'Authorization': `${token}` }
    })
 
    return res ;
}
export const getOrderById =async(data)=>{
    const res = await axios.get(`${base_url}/get-order-by-id/`+data,{
   
    })
 
    return res ;
}

export const confirmOrder =async(token,data)=>{
    const res = await axios.post(`${base_url}/confirm-order`,data,{
        headers :   {'Authorization': `${token}` }
    })
 
    return res ;
}

export const checkCoupon =async(token,coupon)=>{
    const res = await axios.get(`${base_url}/isCouponApplicable?code=${coupon}`,{
        headers :   {'Authorization': `${token}` }
    })
 
    return res ;
}

////////// Payment Stuff Ends ///////////////////////
