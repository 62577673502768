import React from 'react';
import { AiOutlineCheckSquare } from 'react-icons/ai';
import { IoIosArrowDropright } from 'react-icons/io';

const CoursesFeaturesCard = () => {
  return <div className=" bg-gray">


    <div className="container">
      <div className="row justify-content-center align-items-center mb-5 py-5">
        <div className="col-lg-4 rightContainer">
          <div className="">
            <img src="/assets/Icons/6.svg" className="img-fluid" alt="undraw_ask_me_anything_re_x7pm" />
          </div>
        </div>
        <div className="col-lg-7 leftContainerDfaDetail">
          <h4 className="leftContainerDfaDetail mt-5  " >What are FRM Courses Online? What do they offer?</h4>
          <p>FRM courses are the one that is concerned with evaluating candidates on their knowledge of controlling, analyzing market risk, credit risk, and other financial-related risks. We provide certification to the candidates associated with GARP after they successfully qualify for the exam. GARP administers the FRM certification and provides the content for Part 1 and Part 2 of the FRM exam.
          </p>
          <p>
            This FRM Course Online offers you a certified job designation in the market. The certification of our online course is a globally appreciated symbol for those managing risk. After this rapid change in the global market, it needs someone who can manage risk, money, and investment. Therefore, we have created FRM Certification Courses Online to earn worldwide standardized knowledge. Our online course offers worldwide recognition so that it can fill that void between national and international knowledge.
          </p>

          <ul>
            <li> <AiOutlineCheckSquare />  &nbsp;  improve his/ her understanding of the capital markets</li>
            <li> <AiOutlineCheckSquare />  &nbsp;  become an Equity Research Analyst</li>
            <li> <AiOutlineCheckSquare />  &nbsp;  focus on long term investments</li>
            <li> <AiOutlineCheckSquare />  &nbsp;  learn how to invest</li>
            <li> <AiOutlineCheckSquare />  &nbsp;  Construct and manage individual portfolios</li>
            <li> <AiOutlineCheckSquare />  &nbsp;  Become self-sufficient in managing money  </li>
          </ul>
        </div>

      </div>
    </div>


  </div>;
};

export default CoursesFeaturesCard;
