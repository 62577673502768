import React from 'react';
import { AiOutlineCheckSquare } from 'react-icons/ai';
import "./Mainpage.css"

const Timeline = () => {
  return <div className="container mt-4" >
   <div className="">
          <h2 className="text-center" >Modules of Equity Research Training:</h2>
          <p>Equity Research Training Course Online Modules Full Syllabus is located her		e. The course is a bundle of 15 courses. It takes 55+ hours to complete Stock Market Full Course Duration. We have designed this course for students and professionals who want to master research equity skills from verified sources. The candidates must explore the blog for more information regarding the syllabus. </p>
          <h4>Equity Research Training Module Full Syllabus</h4>
          <p>Below given is the list of Stock Market Modules. 
</p>

<div className="row">
  <div className="col-md-6">
  <ul>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Basic Microsoft Excel Training </li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Microsoft Excel - Advanced</li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Accounting:01 - Income Statement</li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Accounting: 02- Balance sheet</li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Accounting: 03 - Cash Flows Analysis</li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Corporate Valuation- Beginner To Pro in Microsoft Excel </li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Finance Modelling of Siemens AG </li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  DCF- Discount Cash Flow </li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Comparable Comps- Comparable Company Analysis</li>
      </ul>
  </div>
  <div className="col-md-6">
  <ul>
        <li> <AiOutlineCheckSquare/>  &nbsp;  10.	Comprehensive Relative Valuation Training </li>
        <li> <AiOutlineCheckSquare/>  &nbsp; 11.	 Equity Research Report Writing</li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  12.	Financial Modelling- Indian Telecommunication Sector</li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  13.	Financial Modelling- Automobile Sector</li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  14.	Comcast and Time Warner Merger Modelling Training</li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  15.	Financial Modelling- Banking Sector </li>
   
      </ul>
  </div>
</div>

<p>In this course, you will learn several basic fundamental models to super-advanced equity research concepts. Access the free PDF of the course attached below for more information. </p>

      </div>
      {/* <div class="timeline mt-5">
     
  <div class="Customecontainer bg left">
    <div class="content">
      <h5>Industry Overview
</h5>
      <p>The core purpose of Module1 is to familiarize the investment industry to the candidates. The candidates learn about the advantages of well-done work and the negative consequences of poorly-done work on the market.</p>
      <ul>
        <li> <AiOutlineCheckSquare/> &nbsp; The investment industry; A Top-Down View</li>
      </ul>
    </div>
  </div>
  <div class="Customecontainer right">
    <div class="content">
      <h5>
      Ethics and regulations</h5>
      <p>2nd Modules focus on the foundation of the investment industry. Skills like how to build trust, reputation, confidence, and value essential matters are taught to the candidates</p>
      <ul>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Ethics and investment professionalism</li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Regulation</li>
      </ul>
    </div>
  </div>
  <div class="Customecontainer left">
    <div class="content">
      <h5>Inputs and Tools</h5>
      <p>Here we familiarise the candidates with the economic world. They are taught about how their actions can affect the industry at the micro, macro, and international level. Candidates get a clearer understanding of the economic world. </p>
      <ul>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Microeconomics </li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Macroeconomics</li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Economics of international trade</li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Financial Statement</li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Quantitative Concepts</li>
      </ul>
    </div>
  </div>
  <div class="Customecontainer right">
    <div class="content">
      <h5>Investment Instruments</h5>
      <p>This module covers basic investment options. Each investment aspect is discussed in depth here. What are essential investment instruments and what is their purpose is all that candidates learn under this module</p>
      <ul>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Debt Securities </li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Equity Securities</li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Derivatives</li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Alternative investments</li>
      </ul>
    </div>
  </div>
  <div class="Customecontainer left">
    <div class="content">
      <h5>Industry Structure</h5>
      <p>This module teaches topics like who are investing in this industry, what are they investing in, a different markets place where investors can take place, etc</p>
      <ul>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Structure of the investment industry </li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Investment vehicles</li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  The functioning of financial markets</li>
      </ul>
    </div>  
  </div>
  <div class="Customecontainer right">
    <div class="content">
      <h5>Serving Clients Need</h5>
      <p>As the name suggests, this module is all about clients and their needs. CFA Course Online teaches all about clients. So that investments can be allocated and managed in the right way for them.</p>
      <ul>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Investors and their needs </li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Investment management</li>
        
      </ul>
    </div>  
  </div>
  <div class="Customecontainer left">
    <div class="content">
      <h5>Industry Control</h5>
      <p>Controls are essential for ensuring that everything functions smoothly. To guarantee that the customer is appropriately served in the fast-paced world of investments and risk, it is critical to understand how systems and controls are used in the sector. This module discusses it all in-depth.</p>
      <ul>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Risk Management </li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Performance Evaluation</li>
        <li> <AiOutlineCheckSquare/>  &nbsp;  Investment industry documentation</li>
      </ul>
    </div>  
  </div>
  
</div> */}
  </div>;
};

export default Timeline;
