import React, { useState } from 'react'
import { contactFormDataApi } from '../../Api'
import WhatsupLogo from '../WhatsupLogo';



export default function ContactPage() {


  const [loading, setLoading] = useState(false);
  const [dataSent, setDataSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [contactData, setContactData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
    phone: ' ',
  });



  console.log(contactData.phone);

  //make a function that checks contact data is not empty string and show that message 
  const checkContactData = () => {


    if (contactData.name === '' || contactData.email === '' || contactData.subject === '' || contactData.message === '' || contactData.phone.length < 10) {
      setErrorMessage('Please Fill in all fields !')
      return reset();
    }
    else {
      setErrorMessage('')
      sendData();
    }
  }


  const sendData = async () => {

    setLoading(true);

    try {
      const res = await contactFormDataApi(contactData);
      setDataSent(true);
      setLoading(false);
    } catch (e) {
      setErrorMessage(e.response.data.error);
      setLoading(false);
    }

    reset();
  }


  const reset = () => {
    setTimeout(() => {
      setDataSent(false);
      setErrorMessage('')
    }, 6000);
  }

  return (
    <div>

      <br />
      <div className="section-heading center-heading">
        <span className="subheading">Know More About Us</span>
        <h3>Meet The Founders</h3>
      </div>


      <div className="container">

        <div className="row justify-content-center" style={{ marginTop: '20px', padding: '20px', textAlign: 'center' }}>



          <div className="col-lg-4 col-md-12">
            <div className="instructor-image">
              <img src="/assets/images/ishaan.jpg" alt="photo of ishan arora" className="img-fluid" />

            </div>
            <br />
            <div >
              <div className="instructor-content">
                <h4><a >Ishaan Arora</a>

                </h4>

                <span className="sub-title"><p><a href="https://ishaanarora.in/" target="_blank" >
                  www.ishaanarora.in
                </a></p></span>
                {/* <span className="sub-title"><p>Phone: 9650746842</p></span> */}
                {/* <p>Jone Smit is a celebrated photographer, author, and writer who brings passion to everything he does.</p> */}
                <ul className="user-social-links">
                  <li><a taget="_blank" href="https://instagram.com/ishaanarora1?igshid=1fu7oq0mynaal"> <i className="fab fa-instagram" /></a></li>
                  <li><a taget="_blank" href="https://twitter.com/Ishaan_Arora7?s=09"> <i className="fab fa-twitter" /></a></li>
                  <li><a taget="_blank" href="https://www.linkedin.com/in/ishaan-arora-555a51161"> <i className="fab fa-linkedin" /></a></li>
                </ul>
              </div>
            </div>
          </div>


          <div className="col-lg-4 col-md-12">
            <div className="instructor-image">
              <img src="/assets/images/murrad.jpg" alt="photo of murad beigh" className="img-fluid" />

            </div>
            <br />
            <div >
              <div className="instructor-content">
                <h4><a >Murrad Beigh</a></h4>
                <span className="sub-title"><p>Phone: 7006559176</p></span>
                {/* <p>Jone Smit is a celebrated photographer, author, and writer who brings passion to everything he does.</p> */}
                <ul className="user-social-links">
                  <li><a href="https://instagram.com/murrad_beigh?igshid=1ccpvojwkwetr"> <i className="fab fa-instagram" /></a></li>
                  <li><a href="https://twitter.com/Murradb?s=09"> <i className="fab fa-twitter" /></a></li>
                  <li><a href="https://www.linkedin.com/in/murrad-beigh-frm-a0679141"> <i className="fab fa-linkedin" /></a></li>
                </ul>
              </div>
            </div>
          </div>

        </div>
      </div>

      <br />

      <section className="contact section-padding">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-7 col-xl-7">
              <div className="section-heading center-heading">
                <span className="subheading">contact</span>
                <h3>Write us a message...</h3>
              </div>
            </div>
          </div>
          <div className="row justify-content-center space-2">
            <div className="col-lg-4">
              <div className="row">
                <div className="col-lg-12 col-md-6">
                  <div className="contact-item">
                    <p>Email Us</p>
                    <h4><a href="http://themeturn.com/cdn-cgi/l/email-protection" className="__cf_email__" data-cfemail="40333530302f323400252d21292c6e232f2d">team@finladder.co</a></h4>
                  </div>
                </div>
                <div className="col-lg-12 col-md-6">
                  <div className="contact-item">
                    <p>Make a Call</p>
                    <h4>7006559176</h4>
                  </div>
                </div>

              </div>
            </div>



            <div className="col-lg-8">


              {!dataSent
                && <div className="contact__form form-row " >


                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input onChange={(e) => setContactData({ ...contactData, name: e.target.value })} type="text" id="name" name="name" className="form-control" placeholder="Your Name" />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input onChange={(e) => setContactData({ ...contactData, email: e.target.value })} type="email" name="email" id="email" className="form-control" placeholder="Email Address" />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input onChange={(e) => setContactData({ ...contactData, phone: e.target.value })} type="number" required name="phone" id="phone" className="form-control" placeholder=" Phone no" />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input onChange={(e) => setContactData({ ...contactData, subject: e.target.value })} type="text" name="subject" id="subject" className="form-control" placeholder="Subject" />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <textarea onChange={(e) => setContactData({ ...contactData, message: e.target.value })} id="message" name="message" cols={30} rows={6} className="form-control" placeholder="Your Message" defaultValue={""} />
                      </div>
                    </div>
                  </div>


                  <div className="col-lg-12">
                    <p className="mt-4 mb-4 text-danger text-capitalize">
                      {errorMessage}
                    </p>

                    <div className="mt-t mb-4">
                      {
                        loading ? <button className="btn btn-main" >Sending...</button>
                          :
                          <button className="btn btn-main" onClick={() => checkContactData()}>Send Message</button>
                      }
                    </div>
                  </div>
                </div>
              }

              {
                dataSent
                && <div>
                  <div class="alert alert-success" role="alert">
                    Your Message has been sent !
                  </div>
                </div>
              }

            </div>
          </div>
        </div>
      </section>
      {/* Contact section End */}
      <WhatsupLogo />
    </div>
  )
}
