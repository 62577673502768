import React, { useState, useEffect } from "react";
import Loader from "react-loader-spinner";
import ReactPlayer from "react-player/youtube";
import TopicAccordion from "../ReusableComponents/TopicAccordion";
import {userState} from '../../Atoms'
import {useRecoilState} from 'recoil'
import {getMyCourseById } from '../../Api'
import './studentCourse.css'
import Plyr from 'plyr-react'
import 'plyr-react/dist/plyr.css'

export default function StudentInnerCoursePage(props) {
  const [courseData, setcourseData] = useState(null);
  const [userData,setuserData] = useRecoilState(userState)
const token = userData.userToken
const courseId = props.match.params.courseId ;
const [topicLink, settopicLink] = useState(null)
const [currentSubTopic, setcurrentSubTopic] = useState(null)

useEffect(() => {

getCourseDetail(token,courseId)
// console.log(courseData);
  return () => {
    
  }
}, [])

const getCourseDetail = async(token,courseId)=>{
  const res = await getMyCourseById(token,courseId)
  console.log('Data is',res.data)
  setcourseData(res.data)
  setcurrentSubTopic(res.data.courseId.topics[0].subTopics[0])
  settopicLink(res.data.courseId.topics[0].subTopics[0].videoLink)
  // console.log('Video is',res.data[0].courseId.topics[0].subTopics[0].videoLink)
}


  return (
    <div>


<center>
{ !courseData &&   <Loader type="Puff" color="#00BFFF" height={50} width={50} /> }</center>


   { courseData &&    <div className="container-fluid">
        <div className="row course-player-container">
          <div
            className="col-lg-9 col-md-12"
            style={{ paddingTop: "5vh",padding: 0 }}
          >

            

            <center>
              <div className="iframe-wrapper">
              {/* <ReactPlayer className="course-modal-player"  url={topicLink} controls={true} /> */}
              {/* {topicLink && <div className="frame-container">
                <iframe
                  width="853"
                  height="480"
                  src={`${topicLink.replace('watch?v=','embed/').replace('youtube','youtube-nocookie')}?showinfo=0&rel=0&modestbranding=1&controls=0`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded youtube"
                />
              </div>} */}
            {topicLink && <Plyr source={{
                                type: "video",
                                sources: [
                                  {
                                    src:`${topicLink.replace('watch?v=','embed/').replace('youtube','youtube-nocookie')}?showinfo=0&rel=0&modestbranding=1&controls=0`,
                                    provider: "youtube"
                                  }
                                ]
                              }}
                            />}
              </div>
            </center>

            {currentSubTopic && <div style={{display:'flex',alignItems: 'baseline', background:'#80348712', padding:'14px' }}>
              {/* <h4>Now Playing :</h4> */}
              <div>
              <h4>{currentSubTopic.subTopicName}</h4>
              <p>{currentSubTopic.description}</p>
              {currentSubTopic.docUrl && <a href={currentSubTopic.docUrl}>Document Link</a>}
              </div>
            </div>}
            <div className="coursemobileoff">
            <center>

            <br/>
              <br/>
              <h4>{courseData.courseId.courseName}</h4>
              <br/>
            
              <p >
                {courseData.courseId.description}
              </p>
            </center>
            </div>
            
          </div>

          <div
            className="col-lg-3 col-md-12"
            style={{ backgroundColor: "#1D292E", paddingTop: "5vh",paddingBottom : '5vh' }}
          >


{ courseData.courseId.topics.map((item,index)=>{
  return(    <TopicAccordion  data={item} key={index} topicIndex={index} settopicLink={settopicLink} token={token} courseId={courseData.courseId._id} setcurrentSubTopic={setcurrentSubTopic} topicLink={topicLink}/>)
}) }

         


          </div>
        </div>
      </div>}
    </div>
  );
}
