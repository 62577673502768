import React, { useState } from 'react'
import { courseOfferForm } from '../../OfferCourseForm/Api';
import { useHistory } from "react-router-dom";
import "./style.css";
const CourseV2Form = ({ CurrentCourseName }) => {
    const [loading, setLoading] = useState(false);
    const [dataSent, setDataSent] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [contactData, setContactData] = useState({
        name: "",
        email: "",
        phone: " ",
        courseName: CurrentCourseName
    });
    const history = useHistory();
    console.log(CurrentCourseName);
    //function that checks contact data is not empty string and show that message
    const handleSubmit = (e) => {
        e.preventDefault();

        if (
            contactData.phone.length < 10
        ) {
            setErrorMessage("Phone number should be 10 digit");
            return reset();
        } else {
            setErrorMessage("");
            sendData();
        }
    };

    const sendData = async () => {
        setLoading(true);

        try {
            const res = await courseOfferForm(contactData);
            console.log('api hit', res);
            setDataSent(true);
            setLoading(false);
            history.push("/thank-you");

        } catch (e) {
            setErrorMessage(e.response.data.error);
            setLoading(false);

        }

        reset();
    };

    const reset = () => {
        setTimeout(() => {
            setDataSent(false);
            setErrorMessage("");

        }, 6000);
    };
    return (
        <div className="courseV2form-container mt-5" id="ContactV2">
            <div className="heading">
                <h3 className="text-light" >You`re nearly there</h3>
            </div>
            <form onSubmit={handleSubmit} className="course-form-wrapper">
                <div className="mb-2">
                    <label htmlFor="" className="course-form-label mb-1 text-light">Name</label>
                    <input type="text"
                        placeholder="Name"
                        onChange={(e) =>
                            setContactData({ ...contactData, name: e.target.value })
                        }
                        required
                    />
                </div>
                <div className="mb-2">
                    <label htmlFor="" className="course-form-label mb-1 text-light">Email</label>

                    <input
                        type="email"
                        placeholder="Email"
                        required
                        onChange={(e) =>
                            setContactData({ ...contactData, email: e.target.value })
                        }
                    />
                </div>
                <div className="mb-2">
                    <label htmlFor="" className="course-form-label mb-1 text-light">Phone</label>

                    <input
                        type="number"
                        placeholder="Phone"
                        required
                        onChange={(e) =>
                            setContactData({ ...contactData, phone: e.target.value })
                        }
                    />
                </div>

                <div className="course-form-btn">

                    {/* <button type="submit">Submit</button> */}
                    <p className="mt-4 mb-4 text-danger text-capitalize">
                        {errorMessage}
                        {/* erro mssage */}
                    </p>

                    {loading ? (
                        <button className="btn btn-main">Sending...</button>
                    ) : (
                        <button type="submit">Enquire Now</button>
                    )}
                </div>
            </form>
        </div>
    )
}

export default CourseV2Form;