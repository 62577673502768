import React from 'react'
import "./style.css"

const WhyFinladder = ({ whyFinladderData }) => {
    return (
        <div className="whyFinladder-container my-5 p-2">
            <div className="heading-wrapper text-center mb-5">
                <h3>Why Finladder?</h3>
            </div>
            <div className="container-fluid">
                <div className="row justify-content-center">
                    {
                        whyFinladderData && whyFinladderData.map((item, index) => {
                            return (
                                <div className="col-lg-4 text-center mb-4" key={index}>
                                    <div className="title">
                                        <h5>{item?.title}</h5>
                                    </div>
                                    <div className="whyFinladder-card-img-wrapper">
                                        <img src={item?.imgUrl} alt="" />
                                    </div>
                                    <p style={{ color: '#000002' }} className="whyFinladder-card-desc" >{item?.desc}</p>
                                </div>
                            )
                        })
                    }

                </div>
            </div>
        </div>
    )
}

export default WhyFinladder