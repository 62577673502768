import React from "react";
import { Accordion, Card, Button } from "react-bootstrap";
import {
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
  MdPlayCircleFilled,
} from "react-icons/md";
import { toggleWatched } from "../../Api";

export default function TopicAccordion({ data, settopicLink,topicLink, setcurrentSubTopic, topicIndex, token, courseId }) {
  // console.log("Topic is ", data);

  return (
    <div style={{ marginTop : '10px' }}>
      <Accordion>
        <Card>
          <Card.Header
            style={{
              padding: 0,
              fontSize: "12px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Accordion.Toggle
              eventKey="0"
              style={{
                padding: 0,
                fontSize: "12px",
                backgroundColor: "transparent",
                color: "gray",
              }}
            >
              <span style={{ padding: 0, fontSize: "14px" }}>
                {data.topicName} &nbsp;
                <MdKeyboardArrowDown />
              </span>
              <br />
              <span style={{ padding: 0, fontSize: "12px", marginTop: "5px" }}>
                Duration : {Math.round(data.topicDuration/60)} Mins | Topics :{" "}
                {data.subTopics.length}{" "}
              </span>
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              {data.subTopics.map((item, index) => {
                return (
                  <div key={index} style={{display:'flex', justifyContent:'space-between', alignItems: 'center'}}>
                  <input type="checkbox" defaultChecked={item.watched} onChange={()=>{toggleWatched(token,courseId, topicIndex, index, !item.watched)}} style={{width:'20px',height:'20px'}}/>
                  <div
                  
                    style={{
                      width: '90%',
                      display: "flex",
                      justifyContent: "space-between",
                      cursor: "pointer",
                      color : (topicLink==item.videoLink) ? ('#8c33df') : ('gray'),
                    }}
                    onClick={() => {
                      settopicLink(item.videoLink);
                      setcurrentSubTopic(item);
                    }}
                  >
                    <span key={index}> {item.subTopicName} </span>
                    <span
                      onClick={() => {
                        settopicLink(item.videoLink);
                        setcurrentSubTopic(item);
                      }}
                    >
                      <MdPlayCircleFilled size={24} />
                    </span>
                  </div>
                  </div>
                );
              })}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
  );
}
