import React, { useState, useEffect } from "react";
import "./Navbar.css";
import { Link } from "react-router-dom";
import { MdShoppingCart } from "react-icons/md";
import { cartState, userState } from "../../Atoms";
import { useRecoilState } from "recoil";
import { Dropdown } from "react-bootstrap";
import { MdArrowDropDown } from "react-icons/md";
import { useHistory } from "react-router-dom";
import logo from '../../assets/logo-white.png'

export default function Navbar() {
  const history = useHistory();

  const CloseMenu = () => {
    var navmenu = document.getElementById("navbarTogglerDemo02");
    navmenu.classList.remove("show");
  };

  const [isAuth, setisAuth] = useState(false);

  const [userCart, setUserCart] = useRecoilState(cartState);
  const [userDetail, setuserDetail] = useRecoilState(userState);

  useEffect(() => {
    if (userDetail.email) {
      setisAuth(true);
    }
    return () => {};
  }, [isAuth]);

  const logout = () => {
    setuserDetail({ email: null, name: null, userToken: null });
    setisAuth(false);
    window.location.replace('/')
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container">
        <Link className="navbar-brand" to="/">
          <strong>
            <img src={logo}   className="logo-brand"/>
            {/* FinLadder */}
          </strong>
        </Link>





      
        <li className="nav-item shopping-mobile" style={{postion : 'absolute' , marginRight : '10px'}}>
              <Link className="nav-link" to="/cart" onClick={()=>CloseMenu()}>
                <MdShoppingCart size={22} />{" "}
                <span className="badge badge-light cart-badge">
                  {userCart.cartCourses.length}
                </span>
              </Link>
            </li>




        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarTogglerDemo02"
          aria-controls="navbarTogglerDemo02"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>








        <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
          <ul className="navbar-nav ml-auto mb-2 mb-lg-0">





            <li className="nav-item">
              <Link className="nav-link active" aria-current="page" to="/"  onClick={()=>CloseMenu()}>
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/about" onClick={()=>CloseMenu()}>
                About
              </Link>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/blogs"  onClick={()=>CloseMenu()}>
                Blogs
              </a>
            </li>
          
            <li className="nav-item">
              <Link className="nav-link" to="/courses" onClick={()=>CloseMenu()}>
                Courses
              </Link>
            </li>

{/* drop down */}
<li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Popular Courses
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
         
         <Link className="dropdown-item" to="/courses/frm" onClick={()=>CloseMenu()}>
         FRM
              </Link>
         <Link className="dropdown-item" to="/courses/equity-research-training" onClick={()=>CloseMenu()}>
         Equity Research Training
              </Link>
         <Link className="dropdown-item" to="/courses/cfa" onClick={()=>CloseMenu()}>
         CFA
              </Link>
         <Link className="dropdown-item" to="/courses/ncfm" onClick={()=>CloseMenu()}>
         NCFM
              </Link>
        
        </div>
      </li>
{/* drop down */}

            <li className="nav-item">
              <Link className="nav-link" to="/contact" onClick={()=>CloseMenu()}>
                Contact
              </Link>
            </li>

         

            <li className="nav-item shopping-web" style={{postion : 'absolute' , marginRight : '10px'}}>
              <Link className="nav-link" to="/cart" onClick={()=>CloseMenu()}>
                <MdShoppingCart size={22} />{" "}
                <span className="badge badge-light cart-badge">
                  {userCart.cartCourses.length}
                </span>
              </Link>
            </li>


            {!isAuth && (
              <li className="nav-item">
                <Link to="/login" className="nav-link"  onClick={()=>CloseMenu()}>
                  Register/Login
                </Link>
              </li>
            )}

            {isAuth && (
              <li className="nav-item">
                <Dropdown>
                  <Dropdown.Toggle
                    className="navbar-dropdown"
                    id="dropdown-basic"
                  >
                    {userDetail.name} <MdArrowDropDown size={20} />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                   <Dropdown.Item href="/studentdashboard"  onClick={()=>CloseMenu()}>My Dashboard</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">
                     My Profile
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => {logout() ; CloseMenu() ;}}>
                      Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
}
