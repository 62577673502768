import React, { useState, useEffect } from "react";
import CourseInstructor from "./CourseInstructor";
import { allCourseState, cartState } from "../../Atoms";
import { useRecoilState } from "recoil";
import { getCourseById } from "../../Api";
import { Modal, Button } from "react-bootstrap";
import Loader from "react-loader-spinner";
import { FaPlayCircle, FaCaretDown } from "react-icons/fa";
import ReactPlayer from "react-player/youtube";
import { Accordion, Card, Breadcrumb, Badge } from "react-bootstrap";
import { ImYoutube } from 'react-icons/im';
import {
  FaStar,
  FaStarHalf,
  FaCalendarCheck,
  FaGlobeAmericas,
} from "react-icons/fa";
import TrendingCourse from "../TrendingCourses/TrendingCourse";
import Plyr from 'plyr-react'
import 'plyr-react/dist/plyr.css'
import EquityResearchTraining from "../CoursesV2/EquityResearchTraining";
import FullWidthTestimonial from "../ReusableComponents/FullWidthTestimonial";
import AdvisorTestimonial from "../CoursesV2/AdvisorTestimonial";
import CourseReviewTestimonial from "../CoursesV2/CourseReviewTestimonial";
import WhyFinladder from "../CoursesV2/WhyFinaldder";
import CourseV2Form from "../CoursesV2/CourseV2Form";
import OurAlumni from "../CoursesV2/OurAlumni";
import FinancialRiskMang from "../CoursesV2/FinancialRiskMang";
import InvestMentBanking from "../CoursesV2/InvestmentBanking";

export default function InnerCoursePage(props) {
  const [Loading, setLoading] = useState(false);
  const courseId = props.match.params.courseId;
  const [savedCourses, setsavedCourses] = useRecoilState(allCourseState);
  const [userCart, setUserCart] = useRecoilState(cartState);
  const [courseData, setcourseData] = useState(null);
  const [allCourses, setallCourses] = useState([]);
  const [show, setShow] = useState(false);
  const [modalText, setmodalText] = useState("");
  const [previewLink, setpreviewLink] = useState("");
  const [previeTopic, setpreviewTopic] = useState("");
  const [showPreview, setshowPreview] = useState(false);

  const [showEquityVideoModal, setShowEquityVideoModal] = useState(false)

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const equityAlumni = [
    {
      src: "/assets/images/brand_logo/download (56).png"
    },
    {
      src: "/assets/images/brand_logo/ernst-young-ey-logo-vector.svg"
    },
    {
      src: "/assets/images/brand_logo/Apexon_Logo_Colour_CMYK.jpg"
    },
    {
      src: "/assets/images/brand_logo/Wipro_Primary_Logo_Color_RGB.svg.png"
    },
    {
      src: "/assets/images/brand_logo/Tata_Consultancy_Services_Logo.svg.png"
    },
    {
      src: "/assets/images/brand_logo/Deloitte_Logo.png"
    },
    {
      src: "/assets/images/brand_logo/BNY_Mellon.svg.png"
    },
  ]
  const frmAlumni = [
    {
      src: "/assets/images/brand_logo/McKinsey_&_Company-Logo.wine.png"
    },

    {
      src: "/assets/images/brand_logo/Tata_Consultancy_Services_Logo.svg.png"
    },
    {
      src: "/assets/images/brand_logo/Boston_Consulting_Group_2020_logo.svg.png"
    },
    {
      src: "/assets/images/brand_logo/2560px-Accenture.svg.png"
    },
    {
      src: "/assets/images/brand_logo/PricewaterhouseCoopers_Logo.png"
    },

  ]
  const investmentBAlumni = [
    {
      src: "/assets/images/brand_logo/Barclays-Logo.wine.png"
    },
    {
      src: "/assets/images/brand_logo/download (58).png"
    },

    {
      src: "/assets/images/brand_logo/download (57).png"
    },
  ]
  useEffect(() => {
    // console.log("Saved course Data", savedCourses);

    window.scrollTo(0, 0);

    if (savedCourses.length < 1) {
      // get data from api
      getCourse(courseId);
    } else {
      if (savedCourses) {
        let temp = [];

        savedCourses.map((item) => {
          return item.coursesCategoryWise.map((val) => {
            return temp.push(val);
          });
        });

        setallCourses("all courses are ", temp);

        let rightData = temp.filter((val) => {
          // console.log('Course is' ,val)
          if (val.courseId == courseId) {
            setcourseData(val);
            // console.log("right Course is", val);
            return val;
          }
        });

        // console.log('Right Data is ', rightData[0])
      }
    }
    return () => { };
  }, []);




  const getCourse = async (courseId) => {
    setLoading(true);
    // console.log("Course id is", courseId);
    const res = await getCourseById(courseId);
    // console.log("Course from Api ", res.data);
    setcourseData(res.data);
    setLoading(false);
  };

  const addToCart = () => {
    var temp = [...userCart.cartCourses];
    var courseExits = false;

    temp.forEach((item) => {
      if (item._id == courseData._id) {
        courseExits = true;
      } else {
      }
    });

    if (!courseExits) {
      temp.push(courseData);
      // console.log("Cart is ", userCart.cartCourses);

      setUserCart({ cartCourses: temp });
      setmodalText("Course Added in Cart");
      setShow(true);
    } else {
      setmodalText("Course Already in Cart");
      setShow(true);
    }
  };


  let whyFinladderDataErt = [
    {
      title: "Learn Job Relevant Skills",
      desc: "Cutting-edge curriculum designed in guidance with industry and academia to develop job-ready skills",
      imgUrl: '/assets/images/new_course_icons/ICONS Equity Research Training Program-01.png'
    },
    {
      title: "Personalized Live Projects",
      desc: "Learn the Practicality of Financial Modelling and Valuations by Personalized Live Projects. With over 5000 students enrolled, know how to use your learnings in the real world!",
      imgUrl: '/assets/images/new_course_icons/notebook (2).png'

    },
    {
      title: "Learn Live",
      desc: "Live facilitated instruction is proven to provide the best student outcomes. Make the most of your investment, and learn from the best in our live online classrooms globally. ",
      imgUrl: '/assets/images/new_course_icons/ICONS Equity Research Training Program-03.png'

    },
    {
      title: "Industry Endorsed Curriculum",
      desc: "Holistic, well- rounded and practical curriculum designed by industry experts with exclusive access to content from e finladder experts.",
      imgUrl: '/assets/images/new_course_icons/ICONS Equity Research Training Program-04.png'

    },
    {
      title: "Structured Guidance and 24x7 Support",
      desc: "24x7 Mentors and a community of like-minded peers can help you resolve any conceptual stumbling blocks.",
      imgUrl: '/assets/images/new_course_icons/ICONS Equity Research Training Program-05.png'

    },
    {
      title: "Global Networking",
      desc: "Connect with industry professionals and prior course graduates to expand your knowledge and maximize your possibilities.",
      imgUrl: '/assets/images/new_course_icons/ICONS Equity Research Training Program-06.png'

    },
  ]
  let whyFinladderDataFrm = [
    {
      title: "Stand Out To Top Employers",
      desc: "FRMs are in high demand among the world's biggest corporations and banks. Our certified FRMs are currently employed in over 190 countries and territories throughout the world",
      imgUrl: '/assets/images/new_course_icons/ICONS  FRM-07.png'

    },
    {
      title: "Official Prep Providers",
      desc: " We're an official Exam Prep Provider (EPP) of GARP institute for both online and offline training",
      imgUrl: '/assets/images/new_course_icons/ICONS  FRM-08.png'

    },

    {
      title: "Live Sessions by Risk Experts",
      desc: "Live facilitated instruction is proven to provide the best student outcomes. Our facilitators are FRM charterholder with years experience in Risk modelling with top Banks in the world",
      imgUrl: '/assets/images/new_course_icons/ICONS  FRM-09.png'

    },
    {
      title: "Cloud e- learning Portal",
      desc: "Get live class notifications, view class recordings, or practice questions sall in one access.",
      imgUrl: '/assets/images/new_course_icons/ICONS  FRM-10.png'

    },
    {
      title: "Reputed Study Materials and Industry Endorsed Curriculum",
      desc: "Holistic, well- rounded and practical curriculum designed by industry experts with exclusive access to elite study materials e finladder experts.",
      imgUrl: '/assets/images/new_course_icons/ICONS  FRM-11.png'

    },
    {
      title: "Structured Guidance and 24x7 Support",
      desc: "24x7 Mentors and a community of like-minded peers can help you resolve any conceptual stumbling blocks.",
      imgUrl: '/assets/images/new_course_icons/ICONS  FRM-12.png'

    },
    {
      title: "Global Networking",
      desc: "Connect with industry professionals and prior course alumnus to expand your knowledge and maximise your possibilities ",
      imgUrl: '/assets/images/new_course_icons/ICONS  FRM-13.png'



    },
  ]
  let whyFinladderDataIb = [
    {
      title: "Industry Aligned Curriculum",
      desc: "Finladder investment banking course covers complex securities, derivative products,and the trading lifecycles and functions associated with them.",
      imgUrl: '/assets/images/new_course_icons/ICONS  IB-14.png'

    },
    {
      title: "Live Sessions",
      desc: "Enjoy a personalized, people-mediated online learning experience created to make you feel supported at  step. Make the most of your investment, and learn from the best online classrooms across India.",
      imgUrl: '/assets/images/new_course_icons/ICONS  IB-15.png'

    },
    {
      title: "Skill Building",
      desc: "Along with domain-specific knowledge empowerment, we promise that you will improve your soft skills to  better articulate and present your ideas.",
      imgUrl: '/assets/images/new_course_icons/ICONS  IB-16.png'

    },
    {
      title: "Personalized Live Projects      ",
      desc: "Learn the Practicality of Financial Modelling and Valuations by Personalized Live Projects. With over 5000 students enrolled, know how to use your learnings in the real world!",
      imgUrl: '/assets/images/new_course_icons/notebook (2).png'

    },
    {
      title: "Advanced Tech Aided",
      desc: "You can engage with your coursework and keep track of reading material via using Finladder's advanced  and external technology.",
      imgUrl: '/assets/images/new_course_icons/ICONS  IB-18.png'

    },
    {
      title: "Structured Guidance and 24x7 Support",
      desc: "24x7 Mentors and a community of like-minded peers can help you resolve any conceptual stumbling blocks",
      imgUrl: '/assets/images/new_course_icons/ICONS  IB-19.png'

    },
  ]
  let courseReviewDataErt = [
    {
      name: "Deeksha Oberoi",
      desc: "The course layout was structured in a clear, thoughtful manner.Special thanks to Ishaan Arora who effortlessly gave practical examples to illustrate basic accounting concepts (which was a good reminder). Some real-world advice was provided by the instructors, at the beginning (understanding the users of the financial model) and at the end (interview questions, common mistakes). That was a good heads up of what to expect in the real world."
    },
    {
      name: "Ajith Pathak",
      desc: "Kudos to e Finladder! Incredibly informative and easy to follow. Excellent overview of critical components with complete and thorough instruction. All topics addressed clearly and concisely. Case studies helped to tie all aspects of the instruction together. A complicated topic presented in an easy to understand and follow package. I also appreciated the added instruction of using Microsoft Word tools to aid in assessing writing quality."
    },
  ]
  let courseReviewDataFrm = [
    {
      name: "Jagman Chawla",
      desc: "The fact that | passed FRM part 1 in Ist attempt with high quartiles signifies how effective the prep course offered by Finaladder. Looking forward to securing the same results in part 2 with the help of Finladder's coaching and guidance"
    },
    {
      name: "Vineesh Mathews",
      desc: "Finladder live sessions, videos and preparation material have been plentiful with regards to preparation of the FRM Part 2 examination. Conceptual clarity along with professional advice and experience from the instructor has been the best part of the course. sufficient notes and question banks provided on the portal along with Notes, no one should have a problem with not having a well rounded revision + practice schedule, The in-depth strategy of covering the curriculum and revision strategy is not an easy task but with  help, it was honestly relieving to know how to. go on about the syllabus."
    },
  ]
  let courseReviewDataIb = [
    {
      name: "Kriti Shah",
      desc: "It was a fantastic learning opportunity. Finladder Investment Banking  is designed to provide modelling and valuation studies from the ground up. The  faculty was extremely helpful in every way, and a special thanks to the Placement team for assisting me in obtaining the desired opportunities."
    },
    {
      name: "Madhav Sood",
      desc: "Prior to joining Finladder, I was having difficulty launching my career in  investment banking. The Finladder staff, on the other hand, encouraged me that with more hard work and devotion, I will be able to make it big in the realms of banking. Now that I've been employed at a reputable company, I'm grateful to Finladder for providing me with a platform to showcase my abilities."
    },
    {
      name: "Nitin Dhall",
      desc: "I've been attempting to master finance and investment banking for the past five years. It was neither easy nor useful prior to joining Finladder. All credit goes to Ishaan sir and the entire Finladder team. Tall constructions necessitated a solid base. Thank you for advising us on how to strengthen our foundation and aim our efforts on the appropriate path."
    },

  ]




  return (
    <div>

      {/* equity video modal */}
      <Modal show={showEquityVideoModal} onHide={
        () => setShowEquityVideoModal(false)
      } size='xl'>
        <Modal.Header closeButton style={{ paddingTop: 0, paddingBottom: 0, background: '#00000030' }}>
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body style={{ padding: 0 }}>
          <Plyr autoplay="true" source={{
            type: "video",
            sources: [
              {
                src: `${"https://www.youtube.com/watch?v=mljlmWMVoGQ".replace('watch?v=', 'embed/').replace('youtube', 'youtube-nocookie')}?showinfo=0&rel=0&modestbranding=1&controls=0`,
                provider: "youtube"
              }
            ]
          }}
          />
        </Modal.Body>
      </Modal>
      {/* equity video modal */}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Cart Update</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalText} !</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            style={{ backgroundColor: "transparent", color: "black" }}
            onClick={handleClose}
          >
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Okay
          </Button>
        </Modal.Footer>
      </Modal>



      <Modal className="videoModalCourse" show={showPreview} onHide={() => setshowPreview(false)} style={{ width: '100%' }} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>{previeTopic} - Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: 0, overflow: "hidden", width: '100%' }}>
          {/* <center>
            <ReactPlayer
              url={previewLink}
              className="preview-modal-player"
              controls={true}
              width={100}
            />
          </center> */}
          <div className="video-responsive">

            <Plyr source={{
              type: "video",
              sources: [
                {
                  src: previewLink,
                  provider: "youtube"
                }
              ]
            }}
            />
          </div>

        </Modal.Body>
        <Modal.Footer style={{ display: "none" }}>
          {/* <Button variant="secondary" style={{backgroundColor : 'transparent' , color : 'black'}} onClick={handleClose}>
            Close
          </Button> */}
          <Button onClick={() => setshowPreview(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
      <center>
        {Loading && (
          <Loader type="Puff" color="#00BFFF" height={50} width={50} />
        )}
      </center>
      {courseData && (
        <div>
          {/* <section className="page-header" style={{ background: '#1e1e1c' }}> */}
          <section
            className={courseData.courseName !== "Equity Research Training" && courseData.courseName !== "Financial Risk Management." && courseData.courseName !== "Investment Banking (Financial Modeling & Valuations)" ? "page-header-black page-header" : "page-header-equit-banner "}
          // className="page-header-black page-header"
          >
            <div className="container">
              <div className="row "
                style={{ flexWrap: "wrap-reverse" }}
              >
                {/* {
                  courseData.courseName !== "Equity Research Training" ?
                    
                  <div className="col-lg-6 col-xl-6">
                      <div className="title-block">
                        <span
                          style={{ color: "#8ed1dc", textTransform: "capitalize" }}
                        >
                      Courses > {courseData.categoryName}{" "}
                        </span>
                        <br />
                        <br />
                        <h2 style={{ color: "white", textAlign: "left" }}>
                          {courseData.courseName}
                        </h2>

                        <span className="course-page-description">
                          {courseData.description}
                        </span>
                        <br />
                        <br />
                        <span className="rating-div">
                          <Badge style={{ padding: "5px" }} variant="info">
                            Best Seller
                          </Badge>

                          <span>
                            <strong
                              style={{ color: "#ffc48c", marginLeft: "20px" }}
                            >
                              {courseData && courseData.rating} <FaStar />{" "}
                            </strong>
                            <span>(687 ratings) 1642 students</span>
                          </span>
                        </span>
                        <br />
                        <span className="rating-div">
                          <FaCalendarCheck /> &nbsp; Updated : 21 Jan 2021 &nbsp;
                          <FaGlobeAmericas /> &nbsp; English
                        </span>
                      </div>
                    </div>
                    :
                    (
                      <>
                        <div className="col-md-8 equity-video-container "
                        >

                        </div>
                        <div className="col-md-4 equity-video-container"
                        >
                          <button className="btn btn-transparent video-block" style={{ padding: 0 }} onClick={
                            () => setShowEquityVideoModal(true)
                          }>
                            <img src="/assets/images/banner/CoursesBg/equity-yt-thumbnail.jpeg" alt="equity-banner" className="img-fluid equity-video-banner" />
                            <a className="video-icon"

                            >
                              <ImYoutube

                                style={{
                                  marginTop: "-25px"
                                }}
                              />

                            </a>
                          </button>

                         
                        </div>
                      </>
                    )
                } */}
                {
                  courseData.courseName === "Equity Research Training" ? (<><EquityResearchTraining /> </>) : courseData.courseName === "Financial Risk Management." ? (<><FinancialRiskMang /></>) : courseData.courseName === "Investment Banking (Financial Modeling & Valuations)" ? (<><InvestMentBanking /></>) : (<>
                    <div className="col-lg-6 col-xl-6">
                      <div className="title-block">
                        <span
                          style={{ color: "#8ed1dc", textTransform: "capitalize" }}
                        >
                      Courses > {courseData.categoryName}{" "}
                        </span>
                        <br />
                        <br />
                        <h2 style={{ color: "white", textAlign: "left" }}>
                          {courseData.courseName}
                        </h2>

                        <span className="course-page-description">
                          {courseData.description}
                        </span>
                        <br />
                        <br />
                        <span className="rating-div">
                          <Badge style={{ padding: "5px" }} variant="info">
                            Best Seller
                          </Badge>

                          <span>
                            <strong
                              style={{ color: "#ffc48c", marginLeft: "20px" }}
                            >
                              {courseData && courseData.rating} <FaStar />{" "}
                            </strong>
                            <span>(687 ratings) 1642 students</span>
                          </span>
                        </span>
                        <br />
                        <span className="rating-div">
                          <FaCalendarCheck /> &nbsp; Updated : 21 Jan 2021 &nbsp;
                          <FaGlobeAmericas /> &nbsp; English
                        </span>
                      </div>
                    </div>
                  </>)
                }


              </div>
            </div>
          </section>

          {courseData.courseName === "Equity Research Training" ? (<>
            <OurAlumni equityAlumni={equityAlumni} />

          </>) : courseData.courseName === "Financial Risk Management." ? (<><OurAlumni frmAlumni={frmAlumni} /></>) : courseData.courseName === "Investment Banking (Financial Modeling & Valuations)" ? (<><OurAlumni investmentBAlumni={investmentBAlumni} /></>) : null}

          <section className="page-wrapper edutim-course-single edutim-course-content">
            <div className="container">
              <div className="row" style={{ flexWrap: "wrap-reverse" }}>
                <div className="col-lg-8">
                  <div className="single-course-details ">
                    {/* <h4 className="course-title">Overview</h4>
<p>{courseData.description}</p> */}

                    {/* course subjects */}
                    <div className="edutim-course-curriculum ">
                      <br />
                      <h4 className="course-title">What will I Learn ?</h4>
                      <br />

                      <div className="curriculum-scrollable">
                        <ul className="curriculum-sections">
                          <Accordion>
                            {courseData.topics.map((item, index) => {
                              return (
                                <Card key={index}>
                                  <Card.Header>
                                    <Accordion.Toggle
                                      variant="link"
                                      eventKey={"" + index}
                                      className="accordion-button"
                                    >
                                      <div className="section-header">
                                        <div
                                          className="section-left"
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                          }}
                                        >
                                          <h5 className="section-title custom-topic-title">
                                            Topic : {item.topicName}{" "}
                                            <FaCaretDown size={20} />{" "}
                                          </h5>
                                          <h5 className="section-title custom-topic-title">
                                            Duration : {Math.round(item.topicDuration / 60)} mins
                                          </h5>
                                        </div>
                                      </div>
                                    </Accordion.Toggle>
                                  </Card.Header>
                                  <Accordion.Collapse eventKey={"" + index}>
                                    <Card.Body>
                                      {item.subTopics.map((val, index) => {
                                        return (
                                          <li
                                            key={index}
                                            className="course-item has-status course-item-lp_lesson"
                                          >
                                            {/* <div className="course-item-lp_lesson"> */}
                                            <span className="item-name">
                                              {val.subTopicName}
                                            </span>

                                            <div className="course-item-meta">
                                              <span
                                                onClick={() => {
                                                  setpreviewLink(
                                                    val.previewLink
                                                  );
                                                  setpreviewTopic(
                                                    val.subTopicName
                                                  );
                                                  setshowPreview(true);
                                                }}
                                              >
                                                <FaPlayCircle
                                                  className="play-button"
                                                  size={20}
                                                />{" "}
                                                &nbsp; &nbsp;{" "}
                                              </span>

                                              <span className="item-meta duration">
                                                {Math.round(val.duration / 60)} mins
                                              </span>
                                              <i className="item-meta course-item-status" />
                                            </div>
                                            {/* </div> */}
                                            {/* {val.description.trim().length > 0 && <div className="text-muted" style={{borderTop: '1px solid #f7f7f7', marginTop: '10px', marginBottom: '19px', padding: '2px 10px', background: '#f7f7f78c', marginTop: '10px'}}>{val.description}</div>} */}
                                          </li>
                                        );
                                      })}
                                    </Card.Body>
                                  </Accordion.Collapse>
                                </Card>
                              );
                            })}
                          </Accordion>
                        </ul>
                      </div>
                    </div>

                    {/* instructors div */}
                    <div className="courses-instructor">
                      <br />
                      <br />
                      {courseData.instructors && courseData.instructors.length > 0 && <h4 className="course-title">Instructors</h4>}

                      <div className="single-instructor-box">
                        <div className="row align-items-center">
                          {courseData.instructors && courseData.instructors.map((item, index) => {
                            return (
                              <CourseInstructor instructor={item} key={index} />
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className=""> */}
                <div
                  // className={courseData.courseName !== "Equity Research Training" ? "col-lg-4 coure-box" : "col-lg-4"}
                  className={courseData.courseName !== "Equity Research Training" && courseData.courseName !== "Financial Risk Management." && courseData.courseName !== "Investment Banking (Financial Modeling & Valuations)" ? "col-lg-4 coure-box" : 'col-lg-4 '}

                >
                  {/* Course Sidebar start */}
                  <div className="course-sidebar course-sidebar-2">
                    <div className="course-widget course-details-info">
                      <div className="course-thumbnail">
                        <img
                          src={courseData.thumbnailImage}
                          alt=""
                          className="img-fluid w-100"
                        />
                      </div>
                      <ul>
                        <li>
                          <div className="d-flex justify-content-between align-items-center">
                            <span>
                              <i className="fa fa-money-bill" />
                              Price:
                            </span>
                            <a className="d-inline-block">
                              <h4 className="course-price">
                                {" "}
                                <span className="price">
                                  Rs {courseData.price}
                                </span>
                              </h4>
                            </a>
                          </div>
                        </li>

                        <li>
                          <div className="d-flex justify-content-between align-items-center">
                            <span>
                              <i className="fa fa-user" />
                              Enrolled :
                            </span>
                            <a className="d-inline-block">2500+ Students</a>
                          </div>
                        </li>
                        <li>
                          {courseData.courseDuration / 60 < 60 && <div className="d-flex justify-content-between align-items-center">
                            <span>
                              <i className="fa fa-clock" />
                              Duration :
                            </span>
                            {Math.round(courseData.courseDuration / 60)} Mins
                          </div>}
                          {courseData.courseDuration / 60 >= 60 && <div className="d-flex justify-content-between align-items-center">
                            <span>
                              <i className="fa fa-clock" />
                              Duration :
                            </span>
                            {Math.round(courseData.courseDuration / 3600)} Hours
                          </div>}
                        </li>
                      </ul>

                      <br />
                      <div className="buy-btn">
                        <button
                          className="button button-enroll-course btn btn-primary"
                          onClick={() => addToCart()}
                          style={{ backgroundColor: "#7054ea" }}
                        >
                          Add To Cart
                        </button>
                      </div>

                      <br />

                      <ul style={{ paddingBottom: 0 }}>


                        <li>
                          <div className="d-flex justify-content-between align-items-center">
                            <span>
                              <i className="fa fa-file" />
                              {courseData && courseData.topics.length} Lessons
                            </span>
                          </div>
                        </li>

                      </ul>
                    </div>
                  </div>
                  {/* Course Sidebar end */}
                </div>
              </div>
            </div>
            {/* <FullWidthTestimonial /> */}
            {/* {
              courseData.courseName === "Equity Research Training" && courseData.courseName === "Financial Risk Management." && courseData.courseName === "Investment Banking (Financial Modeling & Valuations)" &&
              <>
                <AdvisorTestimonial />
                <CourseReviewTestimonial />
                <WhyFinladder />
                <CourseV2Form />
              </>
            } */}
            {courseData.courseName === "Equity Research Training" ? (<>
              <WhyFinladder whyFinladderData={whyFinladderDataErt} />
              <AdvisorTestimonial />
              <CourseReviewTestimonial courseReviewData={courseReviewDataErt} />
              <CourseV2Form CurrentCourseName="Equity Research Training" />
            </>) : courseData.courseName === "Financial Risk Management." ? (<> <WhyFinladder whyFinladderData={whyFinladderDataFrm} />
              <AdvisorTestimonial />
              <CourseReviewTestimonial minHeightCard={'420px'} courseReviewData={courseReviewDataFrm} />
              <CourseV2Form CurrentCourseName="Financial Risk Management" /></>) : courseData.courseName === "Investment Banking (Financial Modeling & Valuations)" ? (<> <WhyFinladder whyFinladderData={whyFinladderDataIb} />
                <AdvisorTestimonial />
                <CourseReviewTestimonial courseReviewData={courseReviewDataIb} minHeightCard={'330px'} />
                <CourseV2Form CurrentCourseName="Investment Banking (Financial Modeling & Valuations)" />

              </>) : null}



            <br />
            {
              courseData.courseName !== "Equity Research Training" && courseData.courseName !== "Financial Risk Management." && courseData.courseName !== "Investment Banking (Financial Modeling & Valuations)" &&
              <div className="container-fluid">
                <div className="row">
                  <TrendingCourse />
                </div>
              </div>

            }

          </section>

        </div>
      )}
    </div>
  );
}
