import React, { useState } from "react";
import CoursesFeaturesCard from "./CoursesFeaturesCard";
import Timeline from "./Timeline";
import NcfmDetailPage from "./NcfmDetailPage";
import { Helmet } from "react-helmet";
import TrendingCourse from "../../TrendingCourses/TrendingCourse";
import LeadForm from "./LeadForm";
import { useEffect } from "react";
import OfferCourseForm from "../../OfferCourseForm/OfferCourseForm";
// import './Mainpage.css'

const MainPage = () => {

  const [showOfferForm, setShowOfferForm] = useState(false)

  useEffect(() => {
    setShowOfferForm(true)
  }, [1])


  return (
    <div>
      <Helmet>
        <title>
          NCFM Course Online | NCFM Certification Course 2022 - eFinladder
        </title>
        <meta
          name="description"
          content=" NCFM Course Online – NCFM Course can be the best financial course for you to start your career in finance, here have launched the Best NCFM Certification Course 2022for everyone, check out now."
        />
        <meta name="keywords" content="NCFM Course" />
      </Helmet>

      <div className="  bg-banner ">
        <div className="container ">
          <div className="row justify-content-center align-items-center ">
            <div className="p-2 col-lg-7 leftContainer">
              <h4 className="leftContainerTitle mb-2">
                NCFM Course Online | NCFM Certification Course 2022 - eFinladder
              </h4>
              <p className="leftContainerPara">
                Are you, someone who wants to master the financial market, but
                does not know how to do it? If yes, you are at the right place.
                We are here to offer you the best financial market mastery
                course to develop and shape your career. We have taken this step
                to develop a group of skilled human resources having proficiency
                in a particular segment of the market and industry. Our course
                guides the audience who participate in the market.
              </p>
              <p className="leftContainerPara">
                NCFM stands for NSE Certification in Financial Markets.
                Considering that we are all familiar with the role of the NSE in
                the Indian stock market, our course is highly valuable for those
                who are hoping to start a career in Indian finance. In this
                article, we will dig into topics such as NCFM modules, benefits,
                fees, Eligibility criteria. NCFM Login procedure will also be
                included in it.
              </p>

              <p className="leftContainerPara">
                Our course is specially designed to help the people working with
                financial intermediaries and has built interest over the years
                to learn more. There was very little in the name of formal
                education in the financial market in the past, but our platform
                has brought certified financial courses for you. Our NCFM
                Certification Course 2021 is registered officially in finance
                education.
              </p>
              <p className="leftContainerPara">
                To know more about us find the NCFM Course Online Modules PDF
                below.
              </p>
            </div>
            <div className="col-lg-4 rightContainer">
              <LeadForm />

            </div>
          </div>
        </div>
      </div>

      <CoursesFeaturesCard />

      <Timeline />

      <NcfmDetailPage />

      <div className="container-fluid">
        <div className="row">
          <TrendingCourse />
        </div>
      </div>

      {/* <DynemicDataPage /> */}
      <OfferCourseForm
        show={showOfferForm}
        onClose={() => setShowOfferForm(false)}
        CurrentCourseName="NCFM"
      />
    </div>
  );
};

export default MainPage;
