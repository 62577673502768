import React, { useState, useEffect } from "react";
import { allCourseState, cartState, userState } from "../../Atoms";
import { useRecoilState } from "recoil";
import { Mdclose } from "react-icons";
import { MdClose } from "react-icons/md";
import jsSHA from "jssha";
import { Link } from "react-router-dom";
import { createOrder, confirmOrder, checkCoupon } from "../../Api";
import { useHistory } from "react-router-dom";
import "./cart.css";
import stateJson from "./stats.json";

export default function CartPage() {
  const [userCart, setUserCart] = useRecoilState(cartState);
  const [userAuth, setUserAuth] = useRecoilState(userState);
  const [cart, setcart] = useState([]);
  const [total, settotal] = useState(0);
  const [couponText, setcouponText] = useState("");
  const [state, setState] = useState("");
  const [couponValid, setcouponValid] = useState(0);
  const history = useHistory();
  const [discount, setDiscount] = useState(0);
  const [maxDiscount, setMaxDiscount] = useState(0);
  const [showSuccess, setshowSuccess] = useState(false);
  const [clicked, setclicked] = useState(false);
  console.log("User Auth", state);

  useEffect(() => {
    updateCart();
    return () => { };
  }, [cart]);

  useEffect(() => {
    updateCart();
    return () => { };
  }, [discount, maxDiscount]);

  const updateCart = () => {
    setcart(userCart.cartCourses);
    console.log("Cart is", userCart.cartCourses);

    let temp_total = 0;
    userCart.cartCourses.map((item) => {
      temp_total = temp_total + item.price;
    });
    let promo = (temp_total * discount) / 100;
    if (promo > maxDiscount) {
      temp_total -= maxDiscount;
    } else {
      temp_total -= promo;
    }
    console.log(temp_total, ",", discount, ",", maxDiscount);
    settotal(temp_total);
  };
  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };
  const handlePayment = async () => {
    if (state) {
      setclicked(true);
      const res1 = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );
      if (!res1) {
        alert("Razorpay failed to load. Are you online?");
        setclicked(false);
        return;
      }
      const data = userCart.cartCourses.map((item) => {
        return { courseId: item._id };
      });
      const res = await createOrder(userAuth.userToken, {
        course: data,
        coupon: couponText,
        state: state,
      });
      const { id: order_id, currency } = res.data;
      const { amount } = res.data.order;
      const server_orderID = res.data.orderId;
      // keyId = "rzp_test_IbF8jbSFUsiVzb" <---Test
      // key_secret = p8QaAU7ovCbRzrsEdYmS0JeD    <----Test
      const options = {
        key: "rzp_live_bjFN3pO2McwEkk", // Enter the Key ID generated from the Dashboard

        amount: amount,
        currency: currency,
        name: userAuth.name,
        description: "Course Transaction",
        image: "https://finladder.co/favicon.ico",
        order_id: order_id,
        handler: async function (response) {
          let data = {
            orderCreationId: order_id,
            razorpayPaymentId: response.razorpay_payment_id,
            razorpayOrderId: response.razorpay_order_id,
            razorpaySignature: response.razorpay_signature,
          };
          console.log("Payment Response", data);
          await confirmPayment(data, server_orderID);
        },
        prefill: {
          name: userAuth.name,
          email: userAuth.email,
          contact: "",
        },
        notes: {
          address: state,
        },
        theme: {
          color: "#803487",
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();

      setclicked(false);
    }
    else {
      alert("Select State")
    }
  };
  const confirmPayment = async (data, orderId) => {
    const res = await confirmOrder(userAuth.userToken, {
      paymentCallback: data,
      orderId: orderId,
    });
    console.log("Confirm Payment", res);

    // empty the cart
    emptyUpCart();

    //show a modal, with sucessfull payment and ask to to got to dashboard
    setshowSuccess(true);
    history.push({
      pathname: '/thank-you-nse/' + userCart.cartCourses[0].courseId
      , state: orderId
    })
  };
  const emptyUpCart = () => {
    setUserCart({ cartCourses: [] });
  };
  const confirmCoupon = async (event) => {
    event.preventDefault();
    const res = await checkCoupon(userAuth.userToken, couponText);
    console.log("coupon check =>", res.data);
    if (res.data.applicable) {
      setDiscount(res.data.discount);
      setMaxDiscount(res.data.maxDiscount);
      setcouponValid(1);
    } else {
      setDiscount(0);
      setMaxDiscount(0);
      setcouponValid(2);
    }
  };
  const removeItem = (id) => {
    let temp = userCart.cartCourses.filter((val) => {
      if (val._id == id) {
      } else {
        return val;
      }
    });

    setcart(temp);
    setUserCart({ cartCourses: temp });
  };

  return (
    <div>
      <section className="page-header" style={{ padding: "40px" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-xl-8">
              <div className="title-block">
                <h1>Cart</h1>
                <ul className="list-inline mb-0">
                  <li className="list-inline-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="list-inline-item">/</li>
                  <li className="list-inline-item">Cart</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <main className="site-main woocommerce single single-product page-wrapper">
        {/*shop category start*/}
        <section>
          <div className="container-fluid sm-center">
            <div className="row">
              <div className="col-lg-8">
                <article
                  id="post-6"
                  className="post-6 page type-page status-publish hentry desktop-cart"
                >
                  {/* .entry-header */}
                  <div className="entry-content">
                    <div className="woocommerce">
                      <div className="woocommerce-notices-wrapper" />
                      <form className="woocommerce-cart-form" action="#" method>
                        <table
                          className="shop_table shop_table_responsive cart woocommerce-cart-form__contents"
                          cellSpacing={0}
                        >
                          <thead>
                            <tr>
                              <th className="product-thumbnail">
                                Course &nbsp;
                              </th>
                              <th className="product-name">Course Name</th>
                              <th className="product-price">Price</th>
                              <th className="product-quantity">Category</th>
                              <th className="product-subtotal">Total</th>
                              <th className="product-remove">Action&nbsp;</th>
                            </tr>
                          </thead>
                          <tbody>
                            {cart.map((item, index) => {
                              return (
                                <tr
                                  key={index}
                                  className="woocommerce-cart-form__cart-item cart_item"
                                >
                                  <td className="product-thumbnail">
                                    <a>
                                      <img
                                        width={400}
                                        height={400}
                                        src={item.thumbnailImage}
                                        className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail"
                                        alt=""
                                      />
                                    </a>
                                  </td>
                                  <td
                                    className="product-name"
                                    data-title="Product"
                                  >
                                    <a>{item.courseName}</a>
                                  </td>
                                  <td
                                    className="product-price"
                                    data-title="Price"
                                  >
                                    <span className="woocommerce-Price-amount amount">
                                      <span className="woocommerce-Price-currencySymbol">
                                        Rs &nbsp;
                                      </span>
                                      {item.price}
                                    </span>
                                  </td>
                                  <td
                                    className="product-quantity"
                                    data-title="Quantity"
                                  >
                                    <div className="quantity">
                                      <label
                                        className="screen-reader-text"
                                        htmlFor="quantity_5cc58182489a8"
                                      >
                                        Category
                                      </label>
                                      <span className="woocommerce-Price-amount amount">
                                        <span className="woocommerce-Price-currencySymbol">
                                          {item.categoryId.categoryName}
                                        </span>
                                      </span>
                                    </div>
                                  </td>
                                  <td
                                    className="product-subtotal"
                                    data-title="Total"
                                  >
                                    <span className="woocommerce-Price-amount amount">
                                      <span className="woocommerce-Price-currencySymbol">
                                        Rs &nbsp;
                                      </span>
                                      {item.price}
                                    </span>
                                  </td>

                                  <td className="product-remove">
                                    <center>
                                      <a
                                        onClick={() => removeItem(item._id)}
                                        className="remove"
                                        aria-label="Remove this item"
                                        data-product_id={30}
                                        data-product_sku
                                      >
                                        <MdClose />
                                      </a>{" "}
                                    </center>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </form>
                    </div>
                  </div>
                  {/* .entry-content */}
                </article>
              </div>

              <div className="course-card-container mobile-cart ">
                {cart.map((item, index) => {
                  return (
                    <div className="cart-course-div" key={index}>
                      <div style={{ flex: 1, overflow: "hidden" }}>
                        <img
                          style={{ resize: "contain" }}
                          src={item.thumbnailImage}
                          alt=""
                        />{" "}
                      </div>

                      <div className="text-course-container">
                        <h6>{item.courseName}</h6>
                        <p style={{ fontSize: "14px" }}>
                          Category :{item.categoryId.categoryName}{" "}
                        </p>
                        <p style={{ fontSize: "14px" }}>
                          Price : Rs {item.price}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>

              <div className="col-lg-4">
                <div className="cart-collaterals">
                  <div className="cart_totals ">
                    <h2>Cart Total</h2>
                    <table
                      cellSpacing={0}
                      className="shop_table shop_table_responsive"
                    >
                      <tbody>
                        <tr className="order-total">
                          <th>Total</th>
                          <td data-title="Total">
                            <strong>
                              <span className="woocommerce-Price-amount amount">
                                <span className="woocommerce-Price-currencySymbol">
                                  Rs{" "}
                                </span>
                                {total.toFixed(2)}
                              </span>
                            </strong>{" "}
                          </td>
                        </tr>

                        <tr></tr>
                      </tbody>
                    </table>
                    <br />
                    <div className='mb-4'>
                      <select
                        className="woocommerce-Input woocommerce-Input--text input-text form-control"
                        name="element"
                        onChange={(e) => setState(e.target.value)}

                      >
                        <option
                          value=''

                        >
                          Select State
                        </option>
                        {stateJson.map((element) => {
                          return (
                            <option
                              value={element.name}
                              key={element.key}
                            >
                              {element.name}{" "}
                            </option>
                          );
                        })}
                      </select>{" "}
                    </div>
                    <div className="order-total">
                      {couponValid === 1 && (
                        <h6 color="green">
                          Coupon Applied! {discount}% Unlocked.
                        </h6>
                      )}
                      {couponValid === 2 && (
                        <h6 color="red">Invalid Coupon!</h6>
                      )}
                      <div data-title="Total">

                        <form
                          className="form"
                          onSubmit={(e) => confirmCoupon(e)}
                          style={{ display: "flex" }}
                        >
                          <div>
                            {" "}
                            <input
                              className="woocommerce-Input woocommerce-Input--text input-text form-control"
                              type="coupon"
                              name="coupon"
                              placeholder="Type Coupon code"
                              onChange={(e) => setcouponText(e.target.value)}
                              required
                            />
                          </div>

                          <input
                            type="submit"
                            value="Apply"
                            className="btn btn-primary"
                          />
                        </form>
                      </div>
                    </div>
                    <div className="wc-proceed-to-checkout">
                      {!clicked && userAuth.userToken && (
                        <button
                          onClick={handlePayment}
                          className="checkout-button button alt wc-forward"
                        >
                          Proceed to checkout
                        </button>
                      )}
                      {clicked && userAuth.userToken && (
                        <button
                          disabled
                          className="checkout-button button alt wc-forward"
                        >
                          Processing...
                        </button>
                      )}
                      {!userAuth.userToken && (
                        <Link
                          to="/login"
                          className="checkout-button button alt wc-forward"
                        >
                          Proceed to checkout
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*shop category end*/}
      </main>

      {showSuccess && (
        <div className="success-modal">
          <div className="success-body">
            <h3>Hurray! We are excited to get you onboard</h3>
            <div className="success-lotte">
              <lottie-player
                src="https://assets1.lottiefiles.com/private_files/lf30_kdkhqgib.json"
                background="transparent"
                speed="1"
                style={{ width: "300px", height: "300px" }}
                loop
                autoplay
              ></lottie-player>
            </div>
            <div className="ctas">
              <Link to="/studentdashboard" className="btn btn-secondary">
                My Dashboard
              </Link>
              <Link to="/studentdashboard" className="btn btn-success">
                Start My Course
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
