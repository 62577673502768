import React, { useState } from 'react'
import AboutCircle from '../../assets/about-circle.png'
import { Dropdown } from 'react-bootstrap'
import { FaCaretDown } from 'react-icons/fa'
import { Link } from 'react-router-dom'

export default function ThreeFeatures() {

  const [showDropdown, setshowDropdown] = useState(false)


  return (
    <div>

      {/* <div>
  <img src={AboutCircle} style={{
    maxWidth : '30%' , position : 'absolute' , zIndex: 2, marginLeft : '-25%' , marginTop : '-5%' ,
    zIndex : 1 ,
  }} className="about-circle"/>
</div> */}






      {/* Feature section start */}
      {/* <section className="features pt-50 pb-50" style={{
  position : 'relative' , zIndex: 99,marginLeft : '2%'
  }}>
       
          <div className="container">
            <div className="row ">
              <div  data-aos="fade-up" data-aos-duration="2000" data-aos-delay="300" className="col-lg-3 col-md-6 col-xl-3 col-sm-6">
                <div className="feature-item">
                  <div className="feature-icon">
                    <i className="flaticon-flag" />
                  </div>
                  <div className="feature-text">
                    <h4>Expert Teacher</h4>
                    <p>Develop skills for career of various majors including computer</p>
                  </div>
                </div>
              </div>
              <div data-aos="fade-up" data-aos-duration="2000" data-aos-delay="600" className="col-lg-3 col-md-6 col-xl-3 col-sm-6">
                <div className="feature-item">
                  <div className="feature-icon">
                    <i className="flaticon-layers" />
                  </div>
                  <div className="feature-text">
                    <h4>Self Development</h4>
                    <p>Develop skills for career of various majors including computer.</p>
                  </div>
                </div>
              </div>
              <div data-aos="fade-up" data-aos-duration="2000" data-aos-delay="900" className="col-lg-3 col-md-6 col-xl-3 col-sm-6">
                <div className="feature-item">
                  <div className="feature-icon">
                    <i className="flaticon-video-camera" />
                  </div>
                  <div className="feature-text">
                    <h4>Remote Learning</h4>
                    <p>Develop skills for career of various majors including language</p>
                  </div>
                </div>
              </div>
              <div data-aos="fade-up" data-aos-duration="2000" data-aos-delay="1200" className="col-lg-3 col-md-6 col-xl-3 col-sm-6">
                <div className="feature-item">
                  <div className="feature-icon">
                    <i className="flaticon-help" />
                  </div>
                  <div className="feature-text">
                    <h4>Life Time Support</h4>
                    <p>Develop skills for career of various majors including language</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
    
       */}
      <div className="container mb-4" style={{ position: 'relative', zIndex: 99, marginBottom: '5vh' }}>
        <div className="row">



          <div className="col-lg-4" style={{ marginBottom: '10px' }}>
            <div class="card  featured-cards box-shadow"  >
              <img class="card-img-top" src="https://i.pinimg.com/originals/92/ba/8f/92ba8f599341997e1ae8dcce764b31b6.png" alt="Card image cap" />
              <div class="card-body">
                <h5 class="card-title">FinLadder Instructors</h5>
                <p class="card-text">Learn from best instructors & get certified.</p>
                {/* <a href="#" class="btn btn-primary  btn-gradient1">View instructor</a> */}

                <Dropdown onClick={() => setshowDropdown(!showDropdown)}>
                  <Dropdown.Toggle variant="success" id="dropdown-basic" className="btn btn-primary  btn-gradient1" style={{ width: '100%' }}>
                    View instructor  <FaCaretDown size={20} className={`${showDropdown ? ('dropdown-true') : ('dropdown-false')}`} />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>

                    <Dropdown.Item href="https://www.linkedin.com/in/ishaan-arora-555a51161">Ishaan Arora</Dropdown.Item>
                    <Dropdown.Item href="https://www.linkedin.com/in/murrad-beigh-frm-a0679141">Murrad Beigh</Dropdown.Item>
                    <Dropdown.Item href="https://www.linkedin.com/in/tushar-kapoor-frm">Tushar Kapoor</Dropdown.Item>
                    {/* <Dropdown.Item href="https://www.linkedin.com/in/siddharth-sid-balasubramanyan-64301389">Siddharth Bala</Dropdown.Item> */}
                    <Dropdown.Item href="https://www.linkedin.com/in/kaushal-subudhi-181a33150">Kaushal Subudhi</Dropdown.Item>
                    <Dropdown.Item href="https://www.linkedin.com/in/pragatisaraf">Pragati Saraf</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

              </div>
            </div>
          </div>



          <div className="col-lg-4 " style={{ marginBottom: '10px' }}>
            <div class="card box-shadow featured-cards" >
              <img class="card-img-top" src="https://cdn.dribbble.com/users/2895451/screenshots/6089193/shot-criativodigital-arteprofessores.jpg?compress=1&resize=400x300" alt="Card image cap" />
              <div class="card-body">
                <h5 class="card-title">FinLadder Courses</h5>
                <p class="card-text">Personalized and technical courses anytime anywhere.</p>
                <Link to="/courses" class="btn btn-primary btn-gradient2">View Courses</Link>
              </div>
            </div>
          </div>



          <div className="col-lg-4" style={{ marginBottom: '10px' }}>
            <div class="card featured-cards box-shadow"  >
              <img class="card-img-top" src="https://cdn.dribbble.com/users/1353252/screenshots/9327462/illustartion-v3_4x.jpg" alt="Card image cap" />
              <div class="card-body">
                <h5 class="card-title">FinLadder Advance Learning</h5>
                <p class="card-text">View free content for FRM,CFA and investment banking.</p>
                <Link to="/content" class="btn btn-primary  btn-gradient3">View content</ Link>
              </div>
            </div>



          </div>








        </div>
      </div>


    </div>
  )
}
