import React, { useEffect } from "react";
import CoursesFeaturesCard from "./CoursesFeaturesCard";

import Timeline from "./Timeline";

import CfaDetailPage from "./CfaDetailPage";

import { getAllCourses, getCourseById } from "../../../Api";
import { useRecoilState } from "recoil";
import { useState } from "react";
import { allCourseState, cartState } from "../../../Atoms";
import { useHistory } from "react-router-dom";
import DynemicDataPage from "./DynemicDataPage";
import { Helmet } from "react-helmet";
import { Modal, Button } from "react-bootstrap";
import Loader from "react-loader-spinner";
import { FaPlayCircle, FaCaretDown } from "react-icons/fa";
import ReactPlayer from "react-player/youtube";
import { Accordion, Card, Breadcrumb, Badge } from "react-bootstrap";
import {
  FaStar,
  FaStarHalf,
  FaCalendarCheck,
  FaGlobeAmericas,
} from "react-icons/fa";

import Plyr from "plyr-react";
import "plyr-react/dist/plyr.css";
import TrendingCourse from "../../TrendingCourses/TrendingCourse";
import CourseInstructor from "../../CoursePage/CourseInstructor";
import OfferCourseForm from "../../OfferCourseForm/OfferCourseForm";

const MainPage = (props) => {
  const courseId = "CFA";
  const history = useHistory();
  const [Loading, setLoading] = useState(false);

  const [savedCourses, setsavedCourses] = useRecoilState(allCourseState);
  const [userCart, setUserCart] = useRecoilState(cartState);
  const [courseData, setcourseData] = useState(null);
  const [allCourses, setallCourses] = useState([]);
  const [show, setShow] = useState(false);
  const [modalText, setmodalText] = useState("");

  const [previewLink, setpreviewLink] = useState("");
  const [previeTopic, setpreviewTopic] = useState("");
  const [showPreview, setshowPreview] = useState(false);
  const [showOfferForm, setShowOfferForm] = useState(false)

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {

    window.scrollTo(0, 0);

    if (savedCourses.length < 1) {
      // get data from api
      getCourse(courseId);
    } else {
      if (savedCourses) {
        let temp = [];

        savedCourses.map((item) => {
          return item.coursesCategoryWise.map((val) => {
            return temp.push(val);
          });
        });

        setallCourses("all courses are ", temp);

        let rightData = temp.filter((val) => {
          // console.log('Course is' ,val)
          if (val.courseId == courseId) {
            setcourseData(val);
            return val;
          }
        });

        // console.log('Right Data is ', rightData[0])
      }
    }
    return () => { };
  }, []);

  const getCourse = async (courseId) => {
    setLoading(true);
    const res = await getCourseById(courseId);
    setcourseData(res.data);
    setLoading(false);
  };

  const addToCart = () => {
    var temp = [...userCart.cartCourses];
    var courseExits = false;
    history.push("/cart");

    temp.forEach((item) => {
      if (item._id == courseData._id) {
        courseExits = true;
      } else {
      }
    });

    if (!courseExits) {
      temp.push(courseData);

      setUserCart({ cartCourses: temp });
      setmodalText("Course Added in Cart");
      setShow(true);
    } else {
      setmodalText("Course Already in Cart");
      setShow(true);
    }
  };

  useEffect(() => {
    setShowOfferForm(true)
  }, [1])


  return (
    <>
      <div>
        <Helmet>
          <title>
            {" "}
            CFA Course Online | CFA Course Details, Syllabus & Duration –
            eFinladder
          </title>
          <meta
            name="description"
            content=" CFACourse Online – CFA is one of the best financial courses which has such brilliant career options. Enroll in our CFA Course 2022 now to become a certified Chartered Financial Analyst."
          />
          <meta name="keywords" content="CFA Course" />
        </Helmet>
        <div>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Cart Update</Modal.Title>
            </Modal.Header>
            <Modal.Body>{modalText} !</Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                style={{ backgroundColor: "transparent", color: "black" }}
                onClick={handleClose}
              >
                Close
              </Button>
              <Button variant="primary" onClick={handleClose}>
                Okay
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal
            className="videoModalCourse"
            show={showPreview}
            onHide={() => setshowPreview(false)}
            style={{ width: "100%" }}
            size="xl"
          >
            <Modal.Header closeButton>
              <Modal.Title>{previeTopic} - Preview</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ padding: 0, overflow: "hidden", width: "100%" }}>
              {/* <center>
      <ReactPlayer
        url={previewLink}
        className="preview-modal-player"
        controls={true}
        width={100}
      />
    </center> */}
              <div className="video-responsive">
                <Plyr
                  source={{
                    type: "video",
                    sources: [
                      {
                        src: previewLink,
                        provider: "youtube",
                      },
                    ],
                  }}
                />
              </div>
            </Modal.Body>
            <Modal.Footer style={{ display: "none" }}>
              {/* <Button variant="secondary" style={{backgroundColor : 'transparent' , color : 'black'}} onClick={handleClose}>
      Close
    </Button> */}
              <Button onClick={() => setshowPreview(false)}>Close</Button>
            </Modal.Footer>
          </Modal>
          <center>
            {Loading && (
              <Loader type="Puff" color="#00BFFF" height={50} width={50} />
            )}
          </center>
          {courseData && (
            <div>
              <section className="page-header" style={{ background: "#1e1e1c" }}>
                <div className="container">
                  <div className="row ">
                    <div className="col-lg-6 col-xl-6">
                      <div className="title-block">
                        <span
                          style={{
                            color: "#8ed1dc",
                            textTransform: "capitalize",
                          }}
                        >
                        Courses > {courseData.categoryName}{" "}
                        </span>
                        <br />
                        <br />
                        <h2 style={{ color: "white", textAlign: "left" }}>
                          {courseData.courseName}
                        </h2>

                        <span className="course-page-description">
                          {courseData.description}
                        </span>
                        <br />
                        <br />
                        <span className="rating-div">
                          <Badge style={{ padding: "5px" }} variant="info">
                            Best Seller
                          </Badge>

                          <span>
                            <strong
                              style={{ color: "#ffc48c", marginLeft: "20px" }}
                            >
                              {courseData && courseData.rating} <FaStar />{" "}
                            </strong>
                            <span>(687 ratings) 1642 students</span>
                          </span>
                        </span>
                        <br />
                        <span className="rating-div">
                          <FaCalendarCheck /> &nbsp; Updated : 21 Jan 2021 &nbsp;
                          <FaGlobeAmericas /> &nbsp; English
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section className="page-wrapper edutim-course-single edutim-course-content">
                <div className="container">
                  <div className="row" style={{ flexWrap: "wrap-reverse" }}>
                    <div className="col-lg-8">
                      <div className="single-course-details ">
                        {/* <h4 className="course-title">Overview</h4>
<p>{courseData.description}</p> */}

                        {/* course subjects */}
                        <div className="edutim-course-curriculum ">
                          <br />
                          <h4 className="course-title">What will I Learn ?</h4>
                          <br />

                          <div className="curriculum-scrollable">
                            <ul className="curriculum-sections">
                              <Accordion>
                                {courseData.topics.map((item, index) => {
                                  return (
                                    <Card key={index}>
                                      <Card.Header>
                                        <Accordion.Toggle
                                          variant="link"
                                          eventKey={"" + index}
                                          className="accordion-button"
                                        >
                                          <div className="section-header">
                                            <div
                                              className="section-left"
                                              style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                              }}
                                            >
                                              <h5 className="section-title custom-topic-title">
                                                Topic : {item.topicName}{" "}
                                                <FaCaretDown size={20} />{" "}
                                              </h5>
                                              <h5 className="section-title custom-topic-title">
                                                Duration :{" "}
                                                {Math.round(
                                                  item.topicDuration / 60
                                                )}{" "}
                                                mins
                                              </h5>
                                            </div>
                                          </div>
                                        </Accordion.Toggle>
                                      </Card.Header>
                                      <Accordion.Collapse eventKey={"" + index}>
                                        <Card.Body>
                                          {item.subTopics.map((val, index) => {
                                            return (
                                              <li
                                                key={index}
                                                className="course-item has-status course-item-lp_lesson"
                                              >
                                                {/* <div className="course-item-lp_lesson"> */}
                                                <span className="item-name">
                                                  {val.subTopicName}
                                                </span>

                                                <div className="course-item-meta">
                                                  <span
                                                    onClick={() => {
                                                      setpreviewLink(
                                                        val.previewLink
                                                      );
                                                      setpreviewTopic(
                                                        val.subTopicName
                                                      );
                                                      setshowPreview(true);
                                                    }}
                                                  >
                                                    <FaPlayCircle
                                                      className="play-button"
                                                      size={20}
                                                    />{" "}
                                                    &nbsp; &nbsp;{" "}
                                                  </span>

                                                  <span className="item-meta duration">
                                                    {Math.round(
                                                      val.duration / 60
                                                    )}{" "}
                                                    mins
                                                  </span>
                                                  <i className="item-meta course-item-status" />
                                                </div>
                                                {/* </div> */}
                                                {/* {val.description.trim().length > 0 && <div className="text-muted" style={{borderTop: '1px solid #f7f7f7', marginTop: '10px', marginBottom: '19px', padding: '2px 10px', background: '#f7f7f78c', marginTop: '10px'}}>{val.description}</div>} */}
                                              </li>
                                            );
                                          })}
                                        </Card.Body>
                                      </Accordion.Collapse>
                                    </Card>
                                  );
                                })}
                              </Accordion>
                            </ul>
                          </div>
                        </div>

                        {/* instructors div */}
                        <div className="courses-instructor">
                          <br />
                          <br />
                          {courseData.instructors &&
                            courseData.instructors.length > 0 && (
                              <h4 className="course-title">Instructors</h4>
                            )}

                          <div className="single-instructor-box">
                            <div className="row align-items-center">
                              {courseData.instructors &&
                                courseData.instructors.map((item, index) => {
                                  return (
                                    <CourseInstructor
                                      instructor={item}
                                      key={index}
                                    />
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 coure-box">
                      {/* Course Sidebar start */}
                      <div className="course-sidebar course-sidebar-2">
                        <div className="course-widget course-details-info">
                          <div className="course-thumbnail">
                            <img
                              src={courseData.thumbnailImage}
                              alt=""
                              className="img-fluid w-100"
                            />
                          </div>
                          <ul>
                            <li>
                              <div className="d-flex justify-content-between align-items-center">
                                <span>
                                  <i className="fa fa-money-bill" />
                                  Price:
                                </span>
                                <a className="d-inline-block">
                                  <h4 className="course-price">
                                    {" "}
                                    <span className="price">
                                      Rs {courseData.price}
                                    </span>
                                  </h4>
                                </a>
                              </div>
                            </li>

                            <li>
                              <div className="d-flex justify-content-between align-items-center">
                                <span>
                                  <i className="fa fa-user" />
                                  Enrolled :
                                </span>
                                <a className="d-inline-block">2500+ Students</a>
                              </div>
                            </li>
                            <li>
                              {courseData.courseDuration / 60 < 60 && (
                                <div className="d-flex justify-content-between align-items-center">
                                  <span>
                                    <i className="fa fa-clock" />
                                    Duration :
                                  </span>
                                  {Math.round(
                                    courseData.courseDuration / 60
                                  )}{" "}
                                  Mins
                                </div>
                              )}
                              {courseData.courseDuration / 60 >= 60 && (
                                <div className="d-flex justify-content-between align-items-center">
                                  <span>
                                    <i className="fa fa-clock" />
                                    Duration :
                                  </span>
                                  {Math.round(
                                    courseData.courseDuration / 3600
                                  )}{" "}
                                  Hours
                                </div>
                              )}
                            </li>
                          </ul>

                          <br />
                          <div className="buy-btn">
                            <button
                              className="button button-enroll-course btn btn-primary"
                              onClick={() => addToCart()}
                              style={{ backgroundColor: "#7054ea" }}
                            >
                              Add To Cart
                            </button>
                          </div>

                          <br />

                          <ul style={{ paddingBottom: 0 }}>
                            <li>
                              <div className="d-flex justify-content-between align-items-center">
                                <span>
                                  <i className="fa fa-file" />
                                  {courseData && courseData.topics.length} Lessons
                                </span>
                              </div>
                            </li>

                          </ul>
                        </div>
                      </div>
                      {/* Course Sidebar end */}
                    </div>
                  </div>
                </div>

                <br />
              </section>
            </div>
          )}
        </div>

        <div className="  bg-banner ">
          <div className="container ">
            <div className="row justify-content-center align-items-center ">
              <div className="leftContainer p-2">
                <h4 className="leftContainerTitle mb-2">
                  CFA Course Online | CFA Course Details, Syllabus & Duration –
                  eFinladder
                </h4>
                <p className="leftContainerPara">
                  We all might have bumped into someone in the financial
                  profession who is really excited about entering CFA programs.
                  They may or may not even know about what they are getting into.
                  It takes a lot to get a charter, but with the right institute
                  like us, the procedure can be made smooth. If you want to pursue
                  a career in this profession, you must evaluate all factors, such
                  as how it will help you and your career, what it takes to become
                  one, and so on. Know about it all with us.
                </p>{" "}
                <br />
                <p className="leftContainerPara">
                  CFA stands for Charted Financial Analysts. Our program is a
                  globally recognized international qualification in the subject
                  of finance. CFA Course Online is divided into three levels.
                  Level 1 is set as the most crucial one as it sets the foundation
                  of the other two levels. Our course is better known for
                  providing skill sets and practical knowledge in the finance
                  field. If you want to pursue a career in finance, our course is
                  the right way to start with.{" "}
                </p>
              </div>

            </div>
          </div>
        </div>
        <CoursesFeaturesCard />

        <Timeline />

        <CfaDetailPage />
        <div className="container-fluid">
          <div className="row">
            <TrendingCourse />
          </div>
        </div>
        {/* <DynemicDataPage/> */}

      </div>

      <OfferCourseForm
        show={showOfferForm}
        onClose={() => setShowOfferForm(false)}
        CurrentCourseName="CFA"

      />
    </>
  );
};

export default MainPage;
