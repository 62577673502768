import React from 'react';
import { AiOutlineCheckSquare } from 'react-icons/ai';
import { IoIosArrowDropright } from 'react-icons/io';

const CoursesFeaturesCard = () => {
  return <div className=" bg-gray">
      

      {/* Career Benefits */}

  

    {/* new */}

    <div className="container">
    <div className="row justify-content-center align-items-center mb-5 py-5">
  <div className="col-lg-4 rightContainer">
    <div className="">
        <img src="/assets/Icons/2.svg" className="img-fluid" alt="undraw_ask_me_anything_re_x7pm" />
    </div>
  </div>
  <div className="col-lg-7 leftContainerDfaDetail">
    <h4 className="leftContainerDfaDetail mt-5  " >What are NCFM Courses Online? What do they offer?</h4>
   <p>Our courses certify students/learners in a certain field of the financial market, such as the Indian stock market. This article contains the NCFM Course Full Details. To learn about them, continue reading.
</p>
<p>
Our NCFM Courses are offered to anyone who is interested in Financial Markets, as they are open to everyone. Our course aims to benefit everyone from beginners who are new to the financial markets to experts who have worked in the financial markets and want to learn more. Since our courses are online, individuals who purchase them can learn whenever and wherever they choose. All you have to do is sign up for the course with us.
 </p>


  </div>
 
  </div>
    </div>


  </div>;
};

export default CoursesFeaturesCard;
