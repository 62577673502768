import React from 'react'


export default function FreeContentComponent({contentData}) {
    return (

        <div className="container-fluid  ">
            <div className="row">
            {contentData && contentData.map((item,index)=>{
                return (
                         
 <div className="col-lg-3 mb-5" key={index} >
 <div class="card  box-shadow"  >
<img class="card-img-top" src={item.thumbnail} alt="Card image cap" />
<div class="card-body">
{/* <h5 class="card-title">{item.title}</h5> */}
{/* <p class="card-text">{item.description}</p> */}
<center>
<a class="btn btn-primary  btn-gradient3" href={item.pdf} download={item.title} style={{color : 'white'}}>Download content</a>

</center>
</div>
</div>



 </div>
                )
            })}
            </div>
        </div>
    )
}
