import React,{useState,useEffect} from 'react'
import ScrollTrigger from 'react-scroll-trigger';
import CountUp,{ useCountUp } from 'react-countup';
import { number } from 'yup';

export default function Counter() {


let mounted = true ;

useEffect(() => {
   
    return () => {
        mounted = false ;
    }
}, [])




function updateStat(){

let num = 0 

if(mounted){



  let studentCounterfunction = setInterval(()=>{
       if(num<5100){
        document.getElementById("studentCounter").innerHTML = num+'+';
        num+=16;
       }else{
        //    console.log('I am in elase block')
           clearInterval(studentCounterfunction)
       }
   },8)


   let lecti = 0 
   let lectureHoursCounterfunction = setInterval(()=>{
    if(lecti<500){
       
     document.getElementById("lectureHoursCounter").innerHTML = lecti+'+';
     lecti+=5 ;
        // setcounter2(lecti)
    }else{
        // console.log('I am in elase block')
        clearInterval(lectureHoursCounterfunction)
    }
},10)



let certi = 0 
   let certificateCounterfunction = setInterval(()=>{
    if(certi<2300){
     document.getElementById("certificateCounter").innerHTML = certi+'+';
     certi+=12 ;

    }else{
        // console.log('I am in elase block')
        clearInterval(certificateCounterfunction)
    }
},10)


let rating = 0

   let courseRatingCounterfunction = setInterval(()=>{
    if(rating<4.9){
     document.getElementById("courseRatingCounter").innerHTML = rating.toString().slice(0,3);
     rating = 1+0.2+rating
    

    }else{
        document.getElementById("courseRatingCounter").innerHTML = '4.9';
        clearInterval(courseRatingCounterfunction)
    }
},300)

}else{
    return ;
}



   

}

    return (

        <ScrollTrigger onEnter={()=>updateStat()} onExit={()=>{mounted=false}} >
        <div className="container"   data-aos="fade-down" data-aos-duration="1000"  data-aos-delay="600"  style={{paddingTop : '10vh'}}>
           

          

              <div class="row" >
            <div class="col-lg-3 col-md-6">
                <div class="counter-item">
                   
                    <div class="count">
                        <span class="counter " id="studentCounter">5200 +</span>
                    </div>
                    <h6>Number of Students</h6>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="counter-item">
                   
                    <div class="count">
                        <span class="counter" id="lectureHoursCounter">513</span>
                    </div>
                    <h6>Hours of Lectures</h6>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">         
                <div class="counter-item">
                   
                    <div class="count">
                        <span class="counter" id="certificateCounter">3200</span>
                    </div>
                    <h6>Certification Completed</h6>
                </div>
            </div>
        
            <div class="col-lg-3 col-md-6">
                <div class="counter-item">
                       
                    <div class="count">
                        <span class="counter" id="courseRatingCounter">4.9</span>
                    </div>
                    <h6>Course Rating </h6>   
                   
                </div>
            </div>
       </div>
    

       <br/>
            <br/>
        </div>
        </ScrollTrigger>
    )
}
