import React from 'react'
import "./style.css"
const AdvisorTestimonial = () => {


    return (
        <div>
            <section className="advisor-testimonial section-padding" >
                <div className="container">
                    <div className="row align-items-center justify-content-center mb-3">
                        <div className="col-lg-7">
                            <div className="section-heading center-heading">
                                <h3 className="text-primary-emphasis" >Message from the founder</h3>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid ">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="testimonials-slides ">
                                <div className="advisor-testimonial-item-wrapper">
                                    <div className="advisor-testimonial-item" >
                                        <div className="client-info d-flex justify-content-between">
                                            <div className="img-wrapper">
                                                <img src="/assets/images/ishaan-icon.png" alt="" className="client-img" />
                                            </div>

                                            <div className="testionial-author text-right d-flex  flex-column justify-content-center">
                                                <div className="name ">Ishaan  Arora</div>
                                                <div className="role">Co- Founder Finladder</div>
                                            </div>
                                        </div>

                                        <div className="testimonial-info-desc">
                                            An entrepreneur who loves to teach about finance and career growth. <br />
                                            Started at the age of 19 and built an edtech company - FinLadder. Since then he has taught over 20,000+ students and also made content on this niche on various platforms. <br />

                                            His goal is to help people become financially aware and at the same time inculcate confidence in them to take things in their own hands.
                                        </div>
                                    </div>
                                </div>






                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default AdvisorTestimonial;