import React, { useState, useEffect } from "react";
import Testimonial from '../ReusableComponents/Testimonial'
import FourFeatures from '../ReusableComponents/FourFeatures'
import FullWidthTestimonial from "../ReusableComponents/FullWidthTestimonial";
import Subscribe from "../ReusableComponents/Subscribe";
import TrendingCourse from "../TrendingCourses/TrendingCourse";
import TwoSectionContent from "../ReusableComponents/TwoSectionContent";
import TwoSectionLeft from "../ReusableComponents/TwoSectionLeft";
import ThreeFeatures from "../ReusableComponents/ThreeFeatures";
import homepagepng from '../../assets/homepage-png1.png'
import Counter from "../ReusableComponents/Counter";
import Bg1 from '../../assets/bg1.png'

import { getAllCourses } from "../../Api";
import Loader from "react-loader-spinner";
import { allCourseState } from "../../Atoms";
import { useRecoilState } from "recoil";



export default function HomepageScreen() {


  const [savedCourses, setsavedCourses] = useRecoilState(allCourseState);


  //get all courses from api
  useEffect(() => {

    console.log('Saved courses', savedCourses)

    if(!savedCourses.data){
      console.log('Data is null')
      getCourses() ;
    }
  
    return () => {};
  }, []);



  const getCourses = async () => {

    const res = await getAllCourses();
    console.log("all courses are from API", res.data);

    setsavedCourses(res.data);
 
  
  };





  return (
  <div>
       {/* Banner Section Start */}




       <section className="banner-2 section-padding mb-4"  >
          <div className="container">
            <div className="row align-items-center">
              <div  className="col-md-12 col-xl-6 col-lg-6">
                <div className="banner-content">
                  <span className="subheading">Learn it with FinLadder</span>
                  {/* <h4 className="banner-heading">eFinladder offering the best finance courses for those who are searching for the financial courses to join and to become a certified financial expert in the industry. </h4> */}
                  <h1 className="banner-heading">Professional and Technical courses for all.</h1>
                  {/* <p>The ultimate planning solution for busy women who want to reach their personal goals.Effortless comfortable eye-catching unique detail </p> */}
                  <a href="/courses" className="btn btn-main">View Courses </a>  
                </div>
              </div>
              <div data-aos="fade-left" data-aos-duration="1000" className="col-md-12 col-xl-6 col-lg-6">
              <img src={homepagepng} alt="" className="img-fluid" />
                {/* <div className="banner-img-round mt-5 mt-lg-0">
                
                </div> */}
              </div>
            </div> {/* / .row */}
          </div> {/* / .container */}
        </section>
        {/* Banner Section End */}
      



<FourFeatures />

<TwoSectionLeft/>



<ThreeFeatures/>


<TrendingCourse/>

<TwoSectionContent/>

<FullWidthTestimonial/>

<Counter/>

<Subscribe />






  </div>
  );
}
