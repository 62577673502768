import { atom } from "recoil";

const cartState = atom({
  key: "cart",
  default: { cartCourses : []},
  persistence_UNSTABLE: {
    type: "cart",
  },
});

const userState = atom({
  key: "finladder_user_v2",
  default: {
    email: null,
    userId: null,
    userToken: null,
    name: null,
  },
  persistence_UNSTABLE: {
    type: "finladder_user_v2",
  },
});

const allCourseState = atom({
  key: "allCourses",
  default: {
    data : null
  },
  persistence_UNSTABLE: {
    type: "allCourses",
  },
});






export { cartState, userState,allCourseState};
