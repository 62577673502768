import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import "./style.css";

export default function ThankYouPageForm() {

    // <!-- Event snippet for Landin Page Thank You conversion page -->

    useEffect(() => {
        const script = document.createElement('script');
        script.innerHTML = `
          gtag('event', 'conversion', {'send_to': 'AW-10937423139/KzjfCPLenosYEKOqr98o'});`;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    <script>
    </script>
    return (
        <>

            <div className="thank-you-page-wrapper">
                <h2 className="title">
                    Thank you
                </h2>
                <p className="para ">
                    Your submission has been  received. <br />
                    We will be in touch and contact you soon
                </p>
                <div className="back-home-btn">
                    <Link to={"/"} className="btn btn-main">Back to Home Page</Link>

                </div>
            </div>
        </>

    )
}
