import React, { useEffect } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { getOrderById } from '../../Api'

export default function ThankYouPage() {
	let [order, setOrder] = React.useState(null)
	useEffect(() => {
		getOrder()
	}, [])
	let history = useLocation()
	const getOrder = async () => {
		console.log(history)
		let ordersDetails = await getOrderById(history.state)
		console.log(ordersDetails)
		setOrder(ordersDetails.data)
	}
	return (
		<div id="container">
			<div class="container">
				<div class="row thankyoupage">
					<div class="col-ms-12 m-auto text-center ">
						<main>


							<h2 className='thankyou-haeding'>Thank you for enrolling with us</h2>
							<div className=' '>
								{order && order.course.map((course, index) => {
									return (
										<div class="alert alert-success" role="alert">
											<Link to={'/mycourses/' + course?.courseId?.courseId} class="alert-link">
												{course?.courseId?.courseName}
											</Link>
										</div>
									)
								})}
							</div>

							<p>As the next step, kindly share the screenshot showing successful payment along with your full name as a WhatsApp message on the number given below to get your account details.</p>
							<div className='m-auto'>


								{/* <a className=' btn cta-thankyoubtn ' href="https://api.whatsapp.com/send?phone=919650746842&text=Hi!%20Please%20find%20the%20attached%20screenshot%20with%20my%20full%20name."
			> 9650746842
            </a> */}
								<div>
									<h6 className='thankyou-subname'>
										Ishaan Arora</h6>
								</div>
							</div>
						</main>
					</div>
				</div>
			</div>
		</div>
	)
}
