import React, { useState, useEffect } from "react";
import CourseCard from "../ReusableComponents/CourseCard";
import { MdArrowDropDown, MdSearch } from "react-icons/md";
import { getAllCourses } from "../../Api";
import Loader from "react-loader-spinner";
import { allCourseState } from "../../Atoms";
import { useRecoilState } from "recoil";

export default function CoursePage() {
  const [savedCourses, setsavedCourses] = useRecoilState(allCourseState);
  
  const [Loading, setLoading] = useState(false)
  const [Courses, setCourses] = useState(null);

  const [selectedCategory, setselectedCategory] = useState("All Courses");
  const [currentIndex, setcurrentIndex] = useState(-1);

  const [openMenu, setopenMenu] = useState(false);
  const [searchTerm, setsearchTerm] = useState('')

  //get all courses from api
  useEffect(() => {

    // console.log('Saved courses', savedCourses)

    if (savedCourses) {
      getCourses();
      // console.log("Getting data from Api");
    } else {
      setLoading(true)
      setallCourses1(savedCourses.data);
      var temp = [];
      savedCourses.forEach((element) => {
        temp.push(element.categoryName);
      });
    setallCourses1(savedCourses)
      setcategoryArray(temp);
      // console.log("Getting data from Recoiljs",savedCourses);
      setLoading(false)
    }
    return () => {};
  }, []);

  const [allCourses1, setallCourses1] = useState(null);
  const [categoryArray, setcategoryArray] = useState([]);

  const getCourses = async () => {
    setLoading(true)
    const res = await getAllCourses();
    // console.log("all courses are", res.data);
    setallCourses1(res.data);
    var temp = [];
    res.data.forEach((element) => {
      temp.push(element.categoryName);
    });
    setsavedCourses(res.data);
    setcategoryArray(temp);
    setLoading(false)
  };

  const allCourses = [
    {
      thumbnailImage:
        "https://sarahcordiner.com/wp-content/uploads/2019/10/YouTube-Thumbnail.png",
      courseName: "Financial Freedom 2020",
      courseDuration: 18,
      numberOfStudents: 88,
      courseRating: 4.5,
      courseDescription:
        "This is a course about fiinance in 2021 and how to make wealth.",
    },
    {
      thumbnailImage:
        "https://cdn3.f-cdn.com/contestentries/1112912/26228964/599ac11baba88_thumb900.jpg",
      courseName: "Financial Freedom 2020",
      courseDuration: 18,
      numberOfStudents: 88,
      courseRating: 4.5,
      courseDescription:
        "This is a course about fiinance in 2021 and how to make wealth.",
    },
    {
      thumbnailImage:
        "https://cdn5.f-cdn.com/contestentries/1122631/9339617/59ac19e21539b_thumb900.jpg",
      courseName: "Financial Freedom 2020",
      courseDuration: 18,
      numberOfStudents: 88,
      courseRating: 4.5,
      courseDescription:
        "This is a course about fiinance in 2021 and how to make wealth.",
    },
    {
      thumbnailImage:
        "https://cdn5.f-cdn.com/contestentries/1122631/9339617/59ac19e21539b_thumb900.jpg",
      courseName: "Financial Freedom 2020",
      courseDuration: 18,
      numberOfStudents: 88,
      courseRating: 4.5,
      courseDescription:
        "This is a course about fiinance in 2021 and how to make wealth.",
    },
    {
      thumbnailImage:
        "https://cdn5.f-cdn.com/contestentries/1122631/9339617/59ac19e21539b_thumb900.jpg",
      courseName: "Financial Freedom 2020",
      courseDuration: 18,
      numberOfStudents: 88,
      courseRating: 4.5,
      courseDescription:
        "This is a course about fiinance in 2021 and how to make wealth.",
    },
    {
      thumbnailImage:
        "https://cdn5.f-cdn.com/contestentries/1122631/9339617/59ac19e21539b_thumb900.jpg",
      courseName: "Financial Freedom 2020",
      courseDuration: 18,
      numberOfStudents: 88,
      courseRating: 4.5,
      courseDescription:
        "This is a course about fiinance in 2021 and how to make wealth.",
    },
  ];


  useEffect(() => {
    if (currentIndex >= 0) {
      setselectedCategory(categoryArray[currentIndex]);
      setopenMenu(false);
      var temp = [];
      if (allCourses1) {
        allCourses1[currentIndex].coursesCategoryWise.map((item, index) => {
          temp.push(item);
        });
      }
      // console.log('Temp courses are',temp)
      setCourses(temp);
    } else {
      setselectedCategory("All Courses");
      var temp = [];
      if (allCourses1) {
        allCourses1.forEach((element) => {
          element.coursesCategoryWise.forEach((item) => {
            temp.push(item);
          });
        });
      }
      // console.log('Temp courses are',temp)
      setCourses(temp);
    }

    return () => {};
  }, [currentIndex, allCourses1]);

  return (
    <div>
      <br />
      <br />

      <div className="container">
        <div className="row">
          <div
            className="col-lg-6 col-sm-12"
            style={{ display: "flex", alignItems: "center", padding: "30px" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "100%",
              }}
            >
              <MdSearch size={40} style={{ marginRight: "5px" }} />
              <input
                type="text"
                style={{
                  border: "0.8px solid #e0e0e0",
                  height: "40px",
                  backgroundColor: "#fff",
                }}
                className="form-control search-input"
                id="formGroupExampleInput"
                placeholder="Seach Course"
                onChange={(event)=>setsearchTerm(event.target.value)}
              />
            </div>
          </div>

          <div
            className="col-lg-6 col-sm-12"
            style={{ display: "flex", alignItems: "center", padding: "30px" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "center",
                flexWrap: "wrap",
                cursor: "pointer",
                transition: "all 0.3s ease-in",
              }}
            >
              <h5>Showing Courses for : &nbsp;</h5>
              <div className="dropdown" onClick={() => setopenMenu(true)}>
                <button
                  className="btn btn-primary dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    padding: 0,
                    marginTop: "-5px",
                    color: "gray",
                  }}
                >
                  {selectedCategory} <MdArrowDropDown size={24} />
                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  <li onClick={() => setcurrentIndex(-1)}>
                    <a className="dropdown-item">All Courses</a>
                  </li>

                  {categoryArray &&
                    categoryArray.map((item, index) => {
                      return (
                        <li
                          style={{ cursor: "pointer" }}
                          key={index}
                          onClick={() => setcurrentIndex(index)}
                        >
                          <a className="dropdown-item">{item}</a>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* //container */}
      </div>

<center>
      { Loading && (
        <Loader type="Puff" color="#00BFFF" height={50} width={50} />
      )}
      </center>

      {/* This is courses cards section */}

      <div className="container-fluid" style={{ minHeight: "100vh" }}>
        <div className="row">
          <div className="course-container">
            {Courses &&
              Courses.filter((val)=>{
                if(searchTerm==''){
                  return val ;
                  // return all elements
                }else{
                 if(val.courseName.toLowerCase().includes(searchTerm.toLowerCase())){
                   return val ;
                 }
                }
              }).map((item, index) => {
                {/* console.log(item) */}
                  return <CourseCard key={index} item={item} categoryName={item.categoryName} />;
                
               
              })}
          </div>
        </div>
      </div>
    </div>
  );
}
