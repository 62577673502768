import React from 'react'
import logo from '../../assets/logo-white.png'
import { Link } from 'react-router-dom'


export default function footer() {
  return (
    <div>
      <br />
      {/* Footer section start */}
      <section className="footer-2" >
        <div className="container">
          <div className="row">
            <div data-aos-duration="1500" data-aos-delay="300" className="col-lg-3 col-sm-6 col-md-8 col-xl-3 col-sm-6">
              <div className="widget footer-about mb-5 mb-lg-0">
                <h5 className="widget-title text-gray">About us</h5>
                <ul className="list-unstyled footer-info">
                  <p>
                    FinLadder is an online educational organization which regularly prepares
                    students and professionals for various finance courses including
                    those conducted by NSE.
                  </p>
                </ul>
                <ul className="list-inline footer-socials">
                  <li className="list-inline-item">Follow us :</li>
                  <li className="list-inline-item"><a target="_blank" href="https://instagram.com/finladder?igshid=cx12na8frebq"><i className="fab fa-instagram" /></a></li>
                  <li className="list-inline-item"> <a target="_blank" href="https://youtube.com/channel/UCLwD3OVMvculdNXHj9a0jtQ"><i className="fab fa-youtube" /></a></li>
                  <li className="list-inline-item"><a target="_blank" href="https://www.linkedin.com/company/finladder"><i className="fab fa-linkedin" /></a></li>

                </ul>
              </div>
            </div>
            <div data-aos-duration="1500" data-aos-delay="600" className="col-xl-8 ml-auto col-lg-8 col-md-12 col-sm-12">
              <div className="row">
                <div className="col-lg-4 col-xl-4 col-sm-4 col-md-4 ">
                  <div className="footer-widget mb-5 mb-lg-0">
                    <h5 className="widget-title text-gray">Explore</h5>
                    <ul className="list-unstyled footer-links">
                      <li><Link to="courses">Courses</Link></li>
                      <li><Link to="/about">About Us</Link></li>
                      <li><Link to="/terms" >Terms & Conditions</Link></li>
                      <li><Link to="/privacy">Privacy</Link></li>



                    </ul>
                  </div>
                </div>
                <div data-aos-duration="1500" data-aos-delay="900" className="col-lg-3 col-xl-4 col-sm-4 col-md-4">
                  <div className="footer-widget mb-5 mb-lg-0">
                    <h5 className="widget-title text-gray">Product & Services</h5>
                    <ul className="list-unstyled footer-links">





                      <li><Link to="/refund-policy">Refunds/Cancellations</Link></li>
                      <li><Link to="/cart">My Cart</Link></li>
                      <li><Link to="/register">Register</Link></li>
                      <li><Link to="/login">Login</Link></li>
                      <li><Link to="/contact">Contact us</Link></li>
                    </ul>
                  </div>
                </div>
                {/* <div  data-aos-duration="1500" data-aos-delay="1200" className="col-lg-4 col-xl-4 col-sm-4 col-md-4">
                    <div className="footer-widget mb-5 mb-lg-0">
                      <h5 className="widget-title text-gray">Legal</h5>
                      <ul className="list-unstyled footer-links">
                        <li><a href="#">Terms &amp; Condition</a></li>
                        <li><a href="#">Privacy policy</a></li>
                        <li><a href="#">Return policy</a></li>
                      </ul>
                    </div>
                  </div> */}



                <div className="col-lg-5 col-sm-6 col-md-8 col-xl-3 col-sm-6">
                  <div className="widget footer-about mb-5 mb-lg-0">
                    <h5 className="widget-title text-gray">Contact us</h5>
                    <ul className="list-unstyled footer-info">
                      <li style={{ color: 'white' }}><span>Ph:</span>7006559176</li>
                      <li style={{ color: 'white' }}> <span>Email:</span>team@finladder.co</li>
                      {/* <li><span>Location:</span> 123 Fifth Floor East 26th Street,
                      New York, NY 10011</li> */}

                      <li style={{ color: 'white', fontSize: '15px' }}><span>Address:</span> 67/3,<br /> 24 CoWork Studio, <br /> G.K - 2, New Delhi<br /> 110048</li>
                    </ul>

                  </div>
                </div>






              </div>
            </div>
          </div>
        </div>
        <div className="footer-btm">
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-xl-6 col-lg-4 col-md-12">
                <div className="footer-logo text-lg-left text-center mb-4 mb-lg-0">
                  <img src={logo} alt="EduHash" className="img-fluid" />
                </div>
              </div>
              <div className="col-xl-6 col-lg-8 col-md-12">
                <div className="copyright text-lg-right text-center">
                  <p>© Copyright Finladder.Crafted by <a style={{ color: 'yellow' }} href="https://www.thebrandwick.com/">The Brandwick</a> </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Footer section End */}
      <div className="fixed-btm-top">
        <a
          onClick={() => window.scrollTo(0, 0)}
          className="js-scroll-trigger scroll-to-top"><i className="fa fa-angle-up" /></a>
      </div>
    </div>
  )
}
