import React from 'react'
import Slider from 'react-slick';
import "./style.css"

const OurAlumni = ({ equityAlumni, frmAlumni, investmentBAlumni }) => {
    var settings = {

        infinite: true,
        speed: 500,
        slidesToShow: 3,
        draggable: true,
        centerMode: true,
        arrows: true,
        dots: true,
        nav: true,
        autoplay: false,
        autoplaySpeed: 1500,
        pauseOnHover: true,
        responsive: [{
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
            },
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
            },
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
            },
        }]
    };

    return (
        <div className="our-alumni-work-at-wrapper my-5 align-items-center  ">
            <div className="title">
                <h4 className="text-center ">OUR ALUMNI WORK AT</h4>
            </div>

            <Slider {...settings} >
                {
                    equityAlumni && equityAlumni.map((item, index) => {
                        return (
                            <div className="d-flex justify-center align-items-center mt-5 p-2 our-alumni-logo-wrapper" key={index}>
                                <img src={item.src} alt="" className="our-alumni-logo-img" />

                            </div>
                        )
                    })
                }
                {
                    frmAlumni && frmAlumni.map((item, index) => {
                        return (
                            <div className="d-flex justify-center mt-5 p-2 align-items-center our-alumni-logo-wrapper" key={index}>
                                <img src={item.src} alt="" className="our-alumni-logo-img" />

                            </div>
                        )
                    })
                }
                {
                    investmentBAlumni && investmentBAlumni.map((item, index) => {
                        return (
                            <div className="d-flex justify-center mt-5 p-2  align-items-center our-alumni-logo-wrapper" key={index}>
                                <img src={item.src} alt="" className="our-alumni-logo-img" />

                            </div>
                        )
                    })
                }

            </Slider>
        </div>


    )
}

export default OurAlumni;

{/* <div className="d-flex justify-content-center mt-5 p-2">

<img src="/assets/images/mckinsey_logo.PNG" alt="" className="img-fluid " />
</div>
<div className="d-flex justify-content-center mt-5 p-2">

<img src="/assets/images/eClerx.PNG" alt="" className="img-fluid " />
</div>
<div className="d-flex justify-content-center mt-5 p-2">

<img src="/assets/images/mckinsey_logo.PNG" alt="" className="img-fluid " />
</div>
<div className="d-flex justify-content-center mt-5 p-2">

<img src="/assets/images/eClerx.PNG" alt="" className="img-fluid " />
</div>
<div className="d-flex justify-content-center mt-5 p-2">

<img src="/assets/images/mckinsey_logo.PNG" alt="" className="img-fluid " />
</div> */}