import React, { useState } from 'react'
import Testimonial from '../ReusableComponents/Testimonial'
import FourFeatures from '../ReusableComponents/FourFeatures'
import { counterState, userState } from '../../Atoms'
import { useRecoilState } from "recoil";
import Plyr from 'plyr-react'
import 'plyr-react/dist/plyr.css'
import { Modal, Button } from 'react-bootstrap'
import WhatsupLogo from '../WhatsupLogo';

export default function AboutPage() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [myUser, setmyUser] = useRecoilState(userState)

  return (
    <div>
      <Modal show={show} onHide={handleClose} size='xl'>
        <Modal.Header closeButton style={{    paddingTop: 0,paddingBottom: 0, background: '#00000030'}}>
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body style={{padding:0}}>
          <Plyr autoplay="true" source={{
            type: "video",
            sources: [
              {
                src: `${"https://www.youtube.com/watch?v=fnth5doIKG4".replace('watch?v=', 'embed/').replace('youtube', 'youtube-nocookie')}?showinfo=0&rel=0&modestbranding=1&controls=0`,
                provider: "youtube"
              }
            ]
          }}
          />
        </Modal.Body>
      </Modal>
      <section className="page-header">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-xl-8">
              <div className="title-block">
                <h1>Who we are</h1>
                <ul className="list-inline mb-0">
                  <li className="list-inline-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="list-inline-item">/</li>
                  <li className="list-inline-item">
                    About Us
                    </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>



      {/* About Section Start */}
      <section className="about-section section-padding">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-6 col-lg-6">
              <button className="btn btn-transparent video-block" style={{ padding: 0 }} onClick={handleShow}>
                <img src="assets/images/bg/about.png" alt="" className="img-fluid" />
                <a className="video-icon"><i className="fa fa-play" /></a>
              </button>
            </div>
            <div className="col-xl-6 pl-5 col-lg-6">
              <div className="section-heading mt-4 mt-lg-0 ">
                <span className="subheading">Changing Finance Education</span>
                <h3>WE MAKE LEARNING EASY AND FUN .</h3>
                <p>
                  FinLadder is an online educational organization which regularly prepares students and professionals to prepare for various finance courses including those conducted by NSE. Thousands of students from universities all around India including Delhi University, Indian Institute of Management, Indraprastha University, Symbiosis are engaged with them.

                  The students belong to different backgrounds and states and they all share common groups to entail knowledge about the aforementioned certifications.

<br />
                  <br />
FinLadder is a start-up by two students pursuing bachelors of commerce from Shaheed Bhagat Singh College, University of Delhi. The main objective of this organization is to promote financial literacy in India and abroad.



                  </p>
              </div>
              <ul className="about-features">
                <li>
                  <i className="fa fa-check" />
                  <h5>High Quality Videos</h5>
                </li>
                <li>
                  <i className="fa fa-check" />
                  <h5>Join 10,000+ Community Members</h5>
                </li>
                <li>
                  <i className="fa fa-check" />
                  <h5>
                    Highly Qualified Instructors</h5>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* About Section END */}



      <FourFeatures />




      <Testimonial />

      <WhatsupLogo />





    </div>
  )
}
