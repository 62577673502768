import React, { useEffect,useState } from 'react'
import logo from '../../assets/logo-white.png'
import {Link} from 'react-router-dom'
import { getBlogs } from '../../Api'


export default function Blog() {
    let [currentpage,setCurrentPage] = useState(0)
    let [lastpage,setLastPage] = useState(null)
    let [blogs,setblogs] = useState([])
    useEffect(()=>{
Blogs()
    },[0])
    const Blogs = async () => {
        let data= await getBlogs()
console.log(data)
setblogs(data.data)
setLastPage(Math.floor(data.data.length/10))
}
let a=blogs.slice((currentpage)*10)
    console.log(lastpage,(currentpage-1)*10,a)
    return (
        <div>
          <br/>
  <div className="container text-center">
      <h1 >Blogs </h1>
  </div>
        <section>
          <div className="container  m-auto">
            <div className="row">
                {blogs.slice((currentpage)*10).map((blog,index)=>{
                    return( <div key={index} data-aos-duration="1500" data-aos-delay="300"
                    className="col-lg-3 col-sm-5  m-2 col-sm-6">
                   <div class="card" style={{width: '18rem' , height:'95% ' }}>
     <img src={blog.mainHeaderImage} style={{height:'200px'}} class="card-img-top" alt="..." />
     <div class="card-body">
       <h5 class="card-title">{blog.heading}</h5>
       <p class="card-text" style={{height:'100px'}} >{blog.shortContent.substring(0,100)}...</p>
       <Link to={"/blog/"+blog.title} class="btn btn-primary mt-2">Read More</Link>
     </div>
   </div>
               </div>)
                })}
               
            </div>
            <div className='row mt-4'>
                <div className='col-md-11 m-auto '>
            <nav aria-label="Page navigation example ">
  <ul class="pagination">
    <li class="page-item"><button class="page-link" disabled={currentpage===0?true:false}
     onClick={()=>setCurrentPage(currentpage-1)}>Previous</button></li>

    <li class="page-item"><button disabled={currentpage===lastpage?true:false} class="page-link"
     onClick={()=>setCurrentPage(currentpage+1)}>Next</button></li>
  </ul>
</nav>
                </div>
                </div>
          </div>
      
        </section>
        {/* Footer section End */}
        <div className="fixed-btm-top">
          <a 
          onClick={()=>window.scrollTo(0,0)}
          className="js-scroll-trigger scroll-to-top"><i className="fa fa-angle-up" /></a>
        </div>
      </div>
    )
}
