import React from 'react'

export default function Terms() {
    return (
        <div className="container">
           

         <br/>  
         <br/>  
         <br/>  
     
         <h2><strong>Thank you for applying at FinLadder!</strong></h2>
<p>FinLadder's products and services are provided by FinLadder itself. These Terms of Use ("Terms") govern your use of FinLadder's website and YouTube channel and other products and services ("Services"). As some of our Services may be software that is downloaded to your computer, phone, tablet, or other device, you</p>
<p>agree that we may automatically update this software, and that these Terms will apply to such updates. Please read these Terms carefully, and contact us if you have any questions. By using our Services, you agree to be bound by these Terms, including the policies referenced in these Terms. Using FinLadder</p>
<p>&nbsp;</p>
<p><strong>Who May Use our Services</strong></p>
<p>You may use our Services only if you can form a binding contract with FinLadder, and only in compliance with these Terms and all applicable laws. When you create your FinLadder account, and subsequently when you use certain features, you must provide us with accurate and complete information, and you agree to</p>
<p>update your information to keep it accurate and complete. Any use or access by anyone other than the registered candidate is prohibited, and certain courses may have additional requirements and/or restrictions.</p>
<p>&nbsp;</p>
<p><strong>Our License to You</strong></p>
<p>Subject to these Terms and our policies (including the Acceptable Use Policy, Honor Code, and coursespecific eligibility requirements and other terms), we grant you a limited, personal, non-exclusive, nontransferable, and revocable license to use our Services. You may download content from our Services only for your personal, non-commercial use, unless you obtain FinLadder's written permission to otherwise use the content. You also agree that you will create, access, and/or use only one user account, and you will not share with any third party access to or access information for your account. Using our Services does not give</p>
<p>you ownership of any intellectual property rights in our Services or the content you access.</p>
<p>&nbsp;</p>
<p><strong>Our Courses</strong></p>
<p><strong>Course Modifications</strong></p>
<p>While we take pride in our world-class courses, unexpected events do occur. FinLadder reserves the right to cancel, interrupt, or reschedule any course or to modify any course content or the point value or weight of any assignment, quiz, or other assessment. Courses offered are subject to the Disclaimers and Limitation of Liability sections below.</p>
<p>&nbsp;</p>
<p><strong>Disclaimer of Student-University Relationship</strong></p>
<p>Nothing in these Terms or otherwise with respect to your participation in any course: (a) establishes any relationship between you and any educational institution with which FinLadder may be affiliated; (b) enrolls or registers you in any educational institution, or in any course offered by any educational institution; or (c) entitles you to use the resources of any educational institution beyond participation in the course.</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p><strong>Your Content</strong></p>
<p><strong>User Content</strong></p>
<p>The Services enable you to share your content, such as homework, quizzes, exams, projects, and other assignments you submit, posts you make in the forums, and the like ("User Content"), with FinLadder, instructors, and/or other users. You retain all intellectual property rights in, and are responsible for, the User</p>
<p>Content you share.</p>
<p>&nbsp;</p>
<p><strong>How FinLadder and Others May Use User Content</strong></p>
<p>To the extent that you provide User Content, you grant FinLadder a fully-transferable, royalty-free, perpetual, sublicensable, non-exclusive, worldwide license to copy, distribute, modify, create derivative works based on, publicly perform, publicly display, and otherwise use the User Content. This license</p>
<p>includes granting FinLadder the right to authorize participating institutions to use User Content with their registered students and on-campus learners independent of the Services. Nothing in these Terms shall restrict other legal rights FinLadder may have to User Content, for example under other licenses. We reserve the right to remove or modify User Content for any reason, including User Content that we believe violates these Terms.</p>
<p>&nbsp;</p>
<p><strong>Feedback</strong></p>
<p>We welcome your suggestions, ideas, comments, and other feedback regarding the Services ("Feedback"). By submitting any Feedback, you grant us the right to use the Feedback without any restriction or any compensation to you. By accepting your Feedback, FinLadder does not waive any rights to use similar or</p>
<p>related Feedback previously known to FinLadder, developed by its employees or contractors, or obtained from other sources.</p>
<p>&nbsp;</p>
<p><strong>Security</strong></p>
<p>We care about the security of our users. While we work to protect the security of your account and related information, FinLadder cannot guarantee that unauthorized third parties will not be able to defeat our security measures. Please notify us immediately of any compromise or unauthorized use of your account by</p>
<p>emailing at : team@finladder.co</p>
<p>&nbsp;</p>
<p><strong>Third Party Content</strong></p>
<p>Through the Services, you will have the ability to access and/or use content provided by instructors, other users, and/or other third parties and links to websites and services maintained by third parties. FinLadder cannot guarantee that such third party content, in the Services or elsewhere, will be free of material you may find objectionable or otherwise inappropriate or of malware or other contaminants that may harm your computer, mobile device, or any files therein. FinLadder disclaims any responsibility or liability related to your access or use of such third party content.</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p><strong>Copyright and Trademark Policy</strong></p>
<p>FinLadder respects the intellectual property rights of our users, participating institutions, and other third parties and expects our users to do the same when using the Services.</p>
<p>&nbsp;</p>
<p><strong>Education Research</strong></p>
<p>FinLadder is committed to advancing the science of learning and teaching, and records of your participation in courses may be used for education research. In the interest of this research, you may be exposed to variations in the course content. Research findings will typically be reported at the aggregate level. Your</p>
<p>personal identity will not be publicly disclosed in any research findings without your express consent.</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p><strong>Paid Services</strong></p>
<p>FinLadde offers paid Services (e.g., Course Certificates for certain courses) for a fee. Unless otherwise stated, all fees are quoted in Indian rupees. You are responsible for paying all fees and applicable taxes in a timely manner with a payment mechanism associated with the applicable paid Services. If your payment</p>
<p>method fails or your account is past due, we may collect fees using other collection mechanisms. Fees may vary based on your location and other factors, and FinLadder reserves the right to change any fees at any time at its sole discretion. Any change, update, or modification will be effective immediately upon posting</p>
<p>through the relevant Services. Refunds may be available for paid Services as described in our Refund Policy.</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p><strong>Modifying and Terminating our Services</strong></p>
<p>We are constantly changing and improving our Services. We may add or remove functions, features, or requirements, and we may suspend or stop a Service altogether. Accordingly, FinLadder may terminate your use of any Service for any reason. If your use of a paid Service is terminated, a refund may be available</p>
<p>under our Refund Policy. None of FinLadder, its participating institutions and instructors, its contributors, sponsors, and other business partners, and their employees, contractors, and other agents (the "FinLadder Parties") shall have any liability to you for any such action. You can stop using our Services at any time, although we'll be sorry to see you go.</p>
<p>&nbsp;</p>
<p><strong>Disclaimers</strong></p>
<p>THE SERVICES AND ALL INCLUDED CONTENT ARE PROVIDED ON AN "AS IS" BASIS WITHOUT WARRANTY OF ANY KIND, WHETHER EXPRESS OR IMPLIED. THE COURSERA</p>
<p>PARTIES SPECIFICALLY DISCLAIM ANY AND ALL WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT, AND</p>
<p>ANY WARRANTIES ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE. THE FinLadder PARTIES FURTHER DISCLAIM ANY AND ALL LIABILITY RELATED TO YOUR ACCESS&nbsp;</p>
<p>OR USE OF THE SERVICES OR ANY RELATED CONTENT. YOU ACKNOWLEDGE AND AGREE THAT ANY ACCESS TO OR USE OF THE SERVICES OR SUCH CONTENT IS AT YOUR OWN RISK.</p>
<p>Limitation of Liability TO THE MAXIMUM EXTENT PERMITTED BY LAW, THE FinLadder PARTIES SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, OR ANY LOSS OF PROFITS OR REVENUES, WHETHER INCURRED DIRECTLY OR INDIRECTLY, OR ANY LOSS OF DATA, USE, GOODWILL, OR OTHER INTANGIBLE LOSSES,</p>
<p>RESULTING FROM: (A) YOUR ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE THE SERVICES; (B) ANY CONDUCT OR CONTENT OF ANY PARTY OTHER THAN THE APPLICABLE</p>
<p>COURSERA PARTY, INCLUDING WITHOUT LIMITATION, ANY DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT; OR (C) UNAUTHORIZED ACCESS, USE, OR ALTERATION OF YOUR</p>
<p>CONTENT OR INFORMATION. IN NO EVENT SHALL FinLadder's AGGREGATE LIABILITY FOR ALL CLAIMS RELATED TO THE SERVICES EXCEED TWO HUNDRED RUPEES OR THE TOTAL</p>
<p>AMOUNT OF FEES RECEIVED BY FinLadder FROM YOU FOR THE USE OF PAID SERVICES DURING THE PAST SIX MONTHS, WHICHEVER IS GREATER.</p>
<p>YOU ACKNOWLEDGE AND AGREE THAT THE DISCLAIMERS AND THE LIMITATIONS OF LIABILITY SET FORTH IN THIS TERMS OF USE REFLECT A REASONABLE AND FAIR</p>
<p>ALLOCATION OF RISK BETWEEN YOU AND THE FinLadder PARTIES, AND THAT THESE LIMITATIONS ARE AN ESSENTIAL BASIS TO FinLadder's ABILITY TO MAKE THE SERVICES</p>
<p>AVAILABLE TO YOU ON AN ECONOMICALLY FEASIBLE BASIS. YOU AGREE THAT ANY CAUSE OF ACTION RELATED TO THE SERVICES MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY BARRED.</p>
<p>&nbsp;</p>
<p><strong>Indemnification</strong></p>
<p>You agree to indemnify, defend, and hold harmless the FinLadder Parties from any and all claims, liabilities, expenses, and damages, including reasonable attorneys' fees and costs, made by any third party related to: (a) your use or attempted use of the Services in violation of these Terms; (b) your violation of any law or rights of any third party; or (c) User Content, including without limitation any claim of infringement or misappropriation of intellectual property or other proprietary rights.&nbsp;</p>
<p>&nbsp;</p>
<p><strong>Governing Law and Jurisdiction</strong></p>
<p>The Services are managed by FinLadder which is located in New Delhi, India. You agree that any dispute related to these Terms will be governed by the laws of India excluding its conflicts of law provisions. You further consent to the personal jurisdiction of and exclusive venue in the federal and state courts located in</p>
<p>and serving New Delhi, India as the legal forum for any such dispute.</p>
<p>&nbsp;</p>
<p><strong>General Terms</strong></p>
<p><strong>Revisions to the Terms</strong></p>
<p>We reserve the right to revise the Terms at our sole discretion at any time. Any revisions to the Terms will be effective immediately upon posting by us. For any material changes to the Terms, we will take reasonable steps to notify you of such changes. In all cases, your continued use of the Services after publication of such changes, with or without notification, constitutes binding acceptance of the revised Terms.</p>
<p>&nbsp;</p>
<p><strong>Severability; Waiver</strong></p>
<p>If it turns out that a particular provision of these Terms is not enforceable, this will not affect any other terms. If you do not comply with these Terms, and we do not take immediate action, this does not indicate that we relinquish any rights that we may have (such as taking action in the future).</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p><strong>Participating Institutions</strong></p>
<p>FinLadder's participating institutions are third party beneficiaries of the Terms and may enforce those provisions of the Terms that relate to them.</p>
<p>FinLadder's mission is to provide universal access to the world&rsquo;s best education. We believe strongly in preserving free speech and expression for our learners as well as academic freedom for our partner institutions and instructors. We also want to make sure that all of our learners and instructors feel safe and</p>
<p>comfortable while using our Services. We have drafted these guidelines to ensure that people understand and follow the rules when participating in our online community and otherwise using our Services. Although we do not routinely screen or monitor content provided by users, we may remove or edit</p>
<p>inappropriate content or activity reported to us or suspend, disable, or terminate a user's access to all or part of the Services.</p>
<p>&nbsp;</p>
<p><strong>You are prohibited from using our Services to share content that:</strong></p>
<p>Contains illegal content or promotes illegal activities with the intent to commit such activities.We don&rsquo;t allow content that creates a genuine risk of physical injury or property damage, credibly threatens people or public safety, or organizes or encourages harm. Harrasses others. We encourage commentary about people and matters of public interest, but abusive or otherwise inappropriate content directed at private individuals is not allowed. Violates intellectual property, privacy, or other rights. Do not share content that you do not have the right to share, claim content that you did not create as your own, or otherwise infringe or misappropriate someone else&rsquo;s intellectual property or other rights. Always attribute materials used or quoted by you to the original copyright owner.</p>
<p>Spams others. Do not share irrelevant or inappropriate advertising, promotional, or solicitation content. Otherwise violates the FinLadder's Terms of Use. Please note that specific courses may have additional rules and requirements.</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p><strong>You also aren't allowed to:</strong></p>
<p>Do anything that violates local, state, national or international law or breaches any of your contractual obligations or fiduciary duties. Share your password, let anyone access your account, or do anything that might put your account at risk. Attempt to access any other user's account. Reproduce, transfer, sell, resell, or otherwise misuse any content from our Services, unless specifically authorized to do so. Access, tamper with, or use non-public areas of our systems, unless specifically authorized to do so. Break or circumvent our authentication or security measures or otherwise test the vulnerability of our</p>
<p>systems or networks, unless specifically authorized to do so. Try to reverse engineer any portion of our Services. Try to interfere with any user, host, or network, for example by sending a virus, overloading, spamming, or mail-bombing. Use our Services to distribute malware. Impersonate or misrepresent your affiliation with any person or entity. Encourage or help anyone do any of the things on this list. FinLadder respects the intellectual property rights of our partner institutions, instructors, and other third parties and expects our users to do the same when using the Services. We reserve the right to suspend,</p>
<p>disable, or terminate the accounts of users who repeatedly infringe or are repeatedly charged with infringing the copyrights, trademarks, or other intellectual property rights of others. FinLadder also respects the trademark rights of others. Accounts with any other content that misleads others or violates another's trademark may be updated, suspended, disabled, or terminated by FinLadder in its sole discretion. If you are concerned that someone may be using your trademark in an infringing way on our Services, please email us at team@finladder.co, and we will review your complaint. If we deem appropriate, we may remove the offending content, warn the individual who posted the content, and/or temporarily or permanently suspend or disable the individual&rsquo;s account.&nbsp;</p>
<p>&nbsp;</p>
<p><strong>Coursera Refund Policy</strong></p>
<p><strong>Effective as of June 10th,2019</strong></p>
<p>For details on our refund deadlines and policies, please refer to the information below; note that our policies differ for subscription payments vs. one-time course and Specialization purchases, and that payment options may vary from one Service to another. Please also note that we treat violations of our Terms of Use and Honor Code very seriously, and we have no obligation to offer refunds to learners who are found to be in violation of these terms, even if their requests are made within the designated refund period. Similarly, we have no obligation to offer late refunds to learners who do not pass a course or Specialization, or who are</p>
<p>otherwise unsatisfied with their final grade.&nbsp;</p>
<p>&nbsp;</p>
<p><strong>FinLadder does not offer refunds for payments made on a subscription plan.</strong></p>
<p>&nbsp;</p>
<p><strong>For Other Paid Services</strong></p>
<p>&nbsp;</p>
<p>Except as described in this Refund Policy or as explicitly stated as part of the sign-up process for a Service, FinLadder has no obligation to provide refunds or vouchers for any other Services.&nbsp;</p>
<p>&nbsp;</p>
<p><strong>Third Party Marketplaces</strong></p>
<p>Not withstanding foregoing, if you purchase or subscribe for a course or Specialization, or purchase any other paid Service, through a third-party marketplace, the refund policy applicable to that third-party marketplace will apply, unless otherwise explicitly stated by FinLadder. Except as otherwise explicitly stated</p>
<p>by FinLadder, the third-party marketplace will be solely responsible for making refunds under its refund policy, and FinLadder will have no refund obligations. FinLadder disclaims any responsibility or liability related to any third-party marketplace's refund policy or the third party's compliance or noncompliance with</p>
<p>such policy.</p>
<p>&nbsp;</p>
<p>All students participating in the class must agree to abide by the following code of conduct:</p>
<p>&nbsp;</p>
<p>I will register for only one account.</p>
<p>My answers to homework, quizzes, exams, projects, and other assignments will be my own work (except for assignments that explicitly permit collaboration).</p>
<p>I will not make solutions to homework, quizzes, exams, projects, and other assignments available to anyone else (except to the extent an assignment explicitly permits sharing solutions). This includes both solutions written by me, as well as any solutions provided by the course staff or others.</p>
<p>I will not engage in any other activities that will dishonestly improve my results or dishonestly improve or hurt the results of others.</p>         
           
           
     <br/>      
     <br/>      
     <br/>      
     <br/>      
           
            </div>
    )
}
