import React from 'react'
import Newsletter from '../../assets/newsletter.png'


export default function Subscribe() {
    return (
        <div>
                  <section className="subscribe section-padding pt-0">
        <div className="container-fluid">
          <div className="row align-items-center form-inner">
            <div data-aos="fade-right" data-aos-duration="2500" className="col-lg-8 col-xl-6">
              <div className="section-heading mb-0">
                <span className="subheading">Newsletter</span>
                <h3>Subscribe to get latest news.</h3>

                <form className="subscribe-form" name="subscribe" method="post">
                <input type="hidden" name="form-name" value="subscribe" />
                <input type="email" name="email" placeholder="Enter Your Email" className="form-control" />
             
                <button style={{marginTop : '10px'}} type="submit" className="btn btn-main" >Subscribe</button>
              </form>
              </div>
            </div>
            <div data-aos="fade-left" data-aos-duration="2500" className="col-lg-4 col-xl-6">
              <img style={{width : '80%' , marginLeft : '10%' , marginTop : '5vh'}} src={Newsletter} />
            </div>
          </div>
        </div>
      </section>
        </div>
    )
}
