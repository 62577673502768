import React, { useState } from 'react'
import ModalContainer from '../ModalContainer/ModalContainer';
import { courseOfferForm } from './Api';

const OfferCourseForm = ({ show, onClose, CurrentCourseName }) => {
    const [loading, setLoading] = useState(false);
    const [dataSent, setDataSent] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [contactData, setContactData] = useState({
        name: "",
        email: "",
        phone: " ",
        courseName: CurrentCourseName
    });


    //function that checks contact data is not empty string and show that message
    const handleSubmit = (e) => {
        e.preventDefault();

        if (
            contactData.phone.length < 10
        ) {
            setErrorMessage("Phone number should be 10 digit");
            return reset();
        } else {
            setErrorMessage("");
            sendData();
        }
    };

    const sendData = async () => {
        setLoading(true);

        try {
            const res = await courseOfferForm(contactData);
            // console.log('api hit', contactData);
            setDataSent(true);
            setLoading(false);
            onClose();
        } catch (e) {
            setErrorMessage(e.response.data.error);
            setLoading(false);
        }

        reset();
    };

    const reset = () => {
        setTimeout(() => {
            setDataSent(false);
            setErrorMessage("");
        }, 6000);
    };
    return (

        <ModalContainer
            show={show}
            closeFunction={onClose}
            maxHeightAllocated={500}
            maxWidthAllocated={500}
            overflowY="auto"
        >
            <div className="col-lg-12 rightContainer">
                <div className="form-heading">
                    <div className="">
                        Special Offer on Our Courses
                        Validity - 24 Hours
                    </div>
                    <div className="">
                        Fill this form to connect with a counselor today!
                    </div>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="">

                        <input type="text"
                            placeholder="Name"
                            onChange={(e) =>
                                setContactData({ ...contactData, name: e.target.value })
                            }
                            required
                        />
                    </div>
                    <div className="">

                        <input
                            type="email"
                            placeholder="Email"
                            required
                            onChange={(e) =>
                                setContactData({ ...contactData, email: e.target.value })
                            }
                        />
                    </div>
                    <div className="">
                        <input
                            type="number"
                            placeholder="Mobile"
                            required
                            onChange={(e) =>
                                setContactData({ ...contactData, phone: e.target.value })
                            }
                        />
                    </div>

                    <div className="Cbtn">
                        <p className="mt-4 mb-4 text-danger text-capitalize">
                            {errorMessage}
                            {/* erro mssage */}
                        </p>

                        {loading ? (
                            <button className="btn btn-main">Sending...</button>
                        ) : (
                            <button type="submit">Enquiry Now</button>
                        )}
                    </div>                </form>

            </div>
        </ModalContainer>

    )
}

export default OfferCourseForm;