import React,{useEffect,useState} from 'react'
import CourseCard from '../ReusableComponents/CourseCard'
import Slider from "react-slick";
import {getAllCourses} from '../../Api'

export default function TrendingCourse() {

    var settings = {
      
        infinite: true,
        speed: 500,
        slidesToShow:3,
       draggable : true,
        centerMode : true,
        autoplay : true,
        autoplaySpeed : 3000, 
        arrows : true,
        responsive : [{
          breakpoint: 1024,
          settings : {
            slidesToShow : 1,
          }
        }]
      };


const [trendingData, settrendingData] = useState(null)

const trendingCourses = [
    {
        thumbnailImage : 'http://themeturn.com/tf-db/eduhash/theme/assets/images/blog/blog1.jpg',
        courseName : 'Financial Freedom 2020',
        courseDuration : 18,
        numberOfStudents : 88,
        courseRating : 4.5 ,
        courseDescription : 'This is a course about fiinance in 2021 and how to make wealth.'
    },
    {
        thumbnailImage : 'http://themeturn.com/tf-db/eduhash/theme/assets/images/blog/blog2.jpg',
        courseName : 'Financial Freedom 2020',
        courseDuration : 18,
        numberOfStudents : 88,
        courseRating : 4.5 ,
        courseDescription : 'This is a course about fiinance in 2021 and how to make wealth.'
    },
    {
        thumbnailImage : 'http://themeturn.com/tf-db/eduhash/theme/assets/images/blog/blog3.jpg',
        courseName : 'Financial Freedom 2020',
        courseDuration : 18,
        numberOfStudents : 88,
        courseRating : 4.5 ,
        courseDescription : 'This is a course about fiinance in 2021 and how to make wealth.'
    },
    {
        thumbnailImage : 'https://image.freepik.com/free-vector/happy-teacher-day-girl-learning-illustration-gradient-color-vector-graphic_3300-94.jpg',
        courseName : 'Financial Freedom 2020',
        courseDuration : 18,
        numberOfStudents : 88,
        courseRating : 4.5 ,
        courseDescription : 'This is a course about fiinance in 2021 and how to make wealth.'
    },
    {
        thumbnailImage : 'https://image.freepik.com/free-vector/e-learning-concept-illustration_1893-2537.jpg',
        courseName : 'Financial Freedom 2020',
        courseDuration : 18,
        numberOfStudents : 88,
        courseRating : 4.5 ,
        courseDescription : 'This is a course about fiinance in 2021 and how to make wealth.'
    },

]

useEffect(() => {

getTrending()
  
  return () => {
    
  }
}, [])

const getTrending = async()=>{
  let res = await getAllCourses()
  // console.log('Res trending',res.data[res.data.length-1].coursesCategoryWise)
  for(let i of res.data){
    // console.log(i)
    if(i.coursesCategoryWise.length > 0){
      settrendingData(i.coursesCategoryWise)
    }
  }
  
}


    return (
        <div style={{maxWidth : '94vw' , marginLeft : '3vw'}}>
                 <section className="section-padding popular-course bg-grey">
        <div className="container-fluid">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-7">
              <div className="section-heading center-heading">
                <span className="subheading">Trending Courses</span>
                <h3>Popular Online Courses Around You</h3>
                {/* <p>The ultimate planning solution for
                  busy women who want to reach their personal goals</p> */}
              </div>
            </div>
          </div>





          <div className="row">
<div className="container-fluid" >

       <Slider {...settings}>
          {trendingData && trendingData.map((item,index)=>{
              return <CourseCard item={item} key={index} trending={true} categoryName={item.categoryId.categoryName}/> })}
          </Slider>

   </div>
</div>



          <div className="row justify-content-center">
            <div className="col-xl-6">
              <div className="text-center mt-5" style={{fontSize : '14px'}}>
                {/* Take the control of their life back and start doing things to make their dream come true. <a href="course.html" className="font-weight-bold text-underline">View all courses </a> */}
              </div>
            </div>
          </div>
        </div>
      </section>
        </div>
    )
}
