import React from 'react'

export default function Refund() {
	return (
		<div id="container">
			<div class="container">
				<div class="row">
					<div class="col-ms-12">
						<article>


							<h2>Refund policy</h2>

							<p>We're so convinced you'll absolutely love our services, that we're willing to offer a 1 day risk-free money back guarantee. If you are not satisfied with the service for any reason you can get a refund within 1 days of making a purchase.</p>
							<h2>Contacting us</h2>
							<p>If you would like to contact us concerning any matter relating to this Refund Policy, you may send an email to &#116;eam&#64;&#102;&#105;&#110;&#108;&#97;&#100;&#100;er&#46;&#99;o</p>

						</article>
					</div>
				</div>
			</div>
		</div>
	)
}
